import { Button, H1, Icon, MenuItem, Tooltip } from "@blueprintjs/core";
import { ItemRendererProps, Select } from "@blueprintjs/select";
import { createSelector } from "@reduxjs/toolkit";
import { Loader, ModuleLoader } from "@stockifi/shared";
import DataDisplay from "components/sales/data-display";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import imAction from "redux/integration-monitor/actions";
import { ScrapersData } from "redux/integration-monitor/types";
import salesActions from "redux/sales/actions";
import { RootState } from "redux/store";
import { User } from "redux/users/types";
import { getScrapersDataByLogId } from "services/integration-monitor";
import styles from "./index.module.scss";

function SalesPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userIdParam } = useParams<{ userIdParam: string }>();

  const users = useAppSelector((state) => state.users.users);
  const sales = useAppSelector((state) => state.sales.sales);
  const loadingSales = useAppSelector((state) => state.sales.loadingSales);
  const firstSaleId = useAppSelector((state) => state.sales.firstSaleId);
  const lastSaleId = useAppSelector((state) => state.sales.lastSaleId);
  const selectLogIds = (state: RootState) =>
    state.integrationMonitor.scraperLogIds;
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const selectScraperLogIds = createSelector(selectLogIds, (logIds) => {
    if (!selectedUserId) return logIds;
    const vendorName =
      users
        .find((x) => x.id === selectedUserId)
        ?.integrations.find((x) => x.type !== "AP" && x.connected)?.vendor ||
      "";
    return logIds.filter((x) => x.vendor === vendorName);
  });
  const scraperLogIds = useAppSelector(selectScraperLogIds);
  const [selectedDataId, setSelectedDataId] = useState<string>("");
  const [selectedScrapersData, setSelectedScrapersData] =
    useState<ScrapersData | null>();
  const [loadScrapedData, setLoadScrapedData] = useState<boolean>(false);
  const [isHideList, setIsHideList] = useState<boolean>(false);

  const salesProcessed = useMemo(() => {
    return sales
      .map((sale) => {
        const filteredLog = scraperLogIds.find(
          (x) =>
            moment(sale.createdAt.toDate().getTime()).format("YYYY-MM-DD") ===
              moment(x.createdAt.getTime()).format("YYYY-MM-DD") &&
            sale.createdAt.toDate() > x.createdAt &&
            sale.createdAt.toDate() > x.lastStep.createdAt
        );
        return { ...sale, logId: filteredLog?.id ?? "" };
      })
      .sort((a, b) => (a.deleted === b.deleted ? 0 : a.deleted ? 1 : -1));
  }, [sales, scraperLogIds]);

  useEffect(() => {
    if (selectedUserId) {
      setSelectedScrapersData(null);
      setSelectedDataId("");
      dispatch(
        imAction.GET_SCRAPER_LOGS({
          userId: selectedUserId,
          type: "POS",
        })
      );
      dispatch(salesActions.GET_USER_SALES({ userId: selectedUserId }));
      setIsHideList(false);
    }
  }, [dispatch, selectedUserId]);

  useEffect(() => {
    if (!users || users.length === 0) return;
    if (
      userIdParam &&
      userIdParam !== "" &&
      users.some((x) => x.id === userIdParam)
    ) {
      setSelectedUserId(userIdParam);
    }
  }, [navigate, userIdParam, users]);

  const userIdsRenderer = (
    user: User,
    { handleClick, modifiers, handleFocus }: ItemRendererProps
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={user.id}
        text={user.name || user.email || user.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        label={user.id}
      />
    );
  };

  const handlePrev = () => {
    dispatch(
      salesActions.GET_USER_SALES({
        direction: "previous",
        userId: selectedUserId,
        firstSaleId: sales[0]?.id,
      })
    );
  };

  const handleNext = () => {
    dispatch(
      salesActions.GET_USER_SALES({
        direction: "next",
        userId: selectedUserId,
        lastSaleId: sales[sales.length - 1]?.id,
      })
    );
  };

  return (
    <ModuleLoader loading={users.length === 0}>
      <div className={styles.container}>
        <H1 className="mb-4">Sales</H1>

        <div className={styles.select__user__dropdown}>
          <span>Select a user:</span>
          <Select
            items={users}
            itemRenderer={userIdsRenderer}
            onItemSelect={(user) => navigate(`/sales/${user.id}`)}
            itemPredicate={(query, user) =>
              user.name?.toLowerCase().includes(query.toLowerCase()) ||
              user.email?.toLowerCase().includes(query.toLowerCase()) ||
              user.id.toLowerCase().includes(query.toLowerCase())
            }
          >
            <Button
              fill
              text={
                users.find((user) => user.id === selectedUserId)?.name ||
                users.find((user) => user.id === selectedUserId)?.email ||
                selectedUserId ||
                "Select a user..."
              }
              rightIcon="caret-down"
              className={styles.dropdown__btn}
            />
          </Select>
        </div>

        {selectedUserId && sales.length > 0 && (
          <div style={{ marginTop: 20, marginBottom: 30 }}>
            <Tooltip
              content={`${isHideList ? "Show Sales List" : "Hide Menu List"}`}
            >
              <Icon
                icon={`${isHideList ? "menu-open" : "menu-closed"}`}
                style={{
                  marginRight: 20,
                  cursor: "pointer",
                }}
                onClick={() => setIsHideList(!isHideList)}
              />
            </Tooltip>
            <Button
              onClick={handlePrev}
              style={{ marginRight: 10 }}
              disabled={sales.some((x) => x.id === firstSaleId)}
            >
              Prev
            </Button>
            <Button
              onClick={handleNext}
              disabled={sales.some((x) => x.id === lastSaleId)}
            >
              Next
            </Button>
          </div>
        )}

        <div
          className={
            isHideList ? styles.data__display__wrapper : styles.content
          }
        >
          {!isHideList && (
            <Loader loaderWidth={240} loading={loadingSales}>
              {selectedUserId ? (
                salesProcessed.length > 0 ? (
                  <div className={styles.sales__wrapper}>
                    {salesProcessed.map((sale) => {
                      return (
                        <div
                          key={sale.id}
                          className={`${styles.sales__card} ${
                            selectedDataId === sale.id ? styles.active : ""
                          }`}
                          onClick={async () => {
                            if (selectedDataId === sale.id) return;
                            setSelectedDataId(sale.id);
                            if (sale.logId) {
                              setLoadScrapedData(true);
                              const scrapersData =
                                (await getScrapersDataByLogId(
                                  sale.logId
                                )) as ScrapersData;
                              if (scrapersData)
                                setSelectedScrapersData(scrapersData);
                              else setSelectedScrapersData(null);
                              setLoadScrapedData(false);
                            }
                            if (window.innerWidth <= 855) {
                              setIsHideList(true);
                            }
                          }}
                        >
                          <span>{sale.id}</span>
                          <span>logId: {sale.logId}</span>
                          <span>
                            {new Date(sale.periodStart.toDate()).toDateString()}{" "}
                            - {new Date(sale.periodEnd.toDate()).toDateString()}
                          </span>
                          {sale.vendor && <span>Vendor: {sale.vendor}</span>}
                          {sale.deleted && (
                            <div style={{ textAlign: "end" }}>
                              <span
                                className={"bp5-tag tag-light-pink m-1 mb-2"}
                              >
                                Deleted
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>No sales found</div>
                )
              ) : null}
            </Loader>
          )}

          <div className={styles.data__display__wrapper}>
            <Loader loading={loadScrapedData}>
              {selectedDataId && (
                <DataDisplay
                  data={sales.find((sale) => sale.id === selectedDataId)?.data}
                  selectedScrapersData={selectedScrapersData}
                  userId={selectedUserId}
                  period={[
                    sales.find((sale) => sale.id === selectedDataId)
                      ?.periodStart,
                    sales.find((sale) => sale.id === selectedDataId)?.periodEnd,
                  ]}
                />
              )}
            </Loader>
          </div>
        </div>
      </div>
    </ModuleLoader>
  );
}

export default SalesPage;
