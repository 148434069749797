import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { Vertex } from "redux/ocr/types";
import { safeTimestamp } from "utils/common";

export type InvoiceState = {
  invoices: Invoice[];
  loading: boolean;
  loadingInvoice: boolean;
  loadingItems: boolean;
  loadingItemCreate: boolean;
  loadingItemUpdate: boolean;
  newItemId: string;
  userItems: Items[];
  tableViewInvoices: { data: Invoice[]; dateFrom: Date; dateTo: Date };
  userInvoicesTable: {
    [userId: string]: { loading: boolean; invoices: Invoice[] };
  };
  userItemsTable: {
    [userId: string]: { loading: boolean; userItems: Items[] };
  };
  userFeesTable: {
    [userId: string]: { loading: boolean; userFees: Fee[] };
  };
  userId: string;

  ocrData: { fileName: string; textAnnotations: any[] }[] | null;
  itemCreateWidth: number;
  loadingOcrData: boolean;
  loadingDocumentAIData: boolean;
  isUploading: boolean;
  downloadURL: string;
  documentAIData: DocumentAI[];
  loadingVotes: boolean;
  loadingSave: boolean;
  votes: {
    data: InvoiceVote[];
    firstDoc?: QueryDocumentSnapshot<DocumentData>;
    lastDoc?: QueryDocumentSnapshot<DocumentData>;
  };
  loadingInvoiceVotes: boolean;
  invoiceVotes: Invoice[];
  fees: Fee[];
  isStrictSearchEnabled: boolean;
  loadingInvoiceVoteDraft: boolean;
  resolvingDraftInvoice: string;
  votesResults: Record<string, { votes: Invoice[]; loading: boolean }>;
  userStatus: UserStatusInvoices;
  isFloatingButtonsVisible: boolean;
  loadingUploadAndCreateInvoice: boolean;
  isForSupervisorFilter: boolean;
};

export type UserStatusInvoices = {
  unchecked: Invoice[];
  checked: Invoice[];
  loadingUnchecked: boolean;
  loadingChecked: boolean;
};

export class Invoice {
  id: string;
  refPath: string;
  createdAt: Date;
  deleted: boolean;
  deliveryDate: Date;
  files: string[];
  foodTotal: number;
  items: Record<string, InvoiceItemFirebase>;
  number: string;
  path: string;
  state: string;
  supplierId: string;
  supplierName: string;
  thumbnails: Record<string, string>;
  totalByType?: Record<string, number>;
  updatedAt: Date;
  updatedCosts: Record<string, UpdatedCosts>;
  url: string;
  userId: string;
  comments: string;
  orientation: Record<string, number>;
  ocrOrientation?: Record<string, number>;
  startedAt?: Date;
  resolvedAt: Date;
  ocrDataFiles: string[];
  documentAIInvoiceDataFiles: string[];
  isFromApTransaction: boolean;
  isFromAdminPanel: boolean;
  itemsList: InvoiceItem[];
  resolvedBy: string;
  editedBy: string;
  isChecked: boolean;
  invoiceComments: string;
  checkedBy: string;
  invoiceId: string;
  checkedAt: Date;
  newAliases: {
    [itemId: string]: { name: string; addedBy: string; itemIndex: number }[];
  };
  aliasesUsed: string[];
  votedBy?: string;
  votedAt?: Date;
  voters?: string[];
  createdBy: string;
  tags?: string[];
  invoiceRefPath: string;
  resolvingDuration?: number;
  editedDuration?: { editorId: string; duration: number }[];
  isDuplicate: boolean;
  isDiverseInvoice: boolean;
  headerVoteValues?: {
    number: string;
    supplierId: string;
    supplierName: string;
    foodTotal: string;
    deliveryDateString: string;
  };
  isDraft?: boolean;
  accuracy?: number;
  invoiceConsensusReached?: boolean;
  oldestDate?: Date | null;
  lastVotedAt?: Date;
  isInvoiceNumberUsed?: boolean;
  isForSupervisor?: boolean;
  isForSupervisorMarkedBy?: string;
  commentedBy?: string;

  constructor(id: string, refPath: string, data: DocumentData) {
    this.id = id;
    this.refPath = refPath;
    this.deleted = data.deleted;
    this.deliveryDate = safeTimestamp(data.deliveryDate)?.toDate() as Date;
    this.files = data.files;
    this.foodTotal = data.foodTotal;
    this.items = data.items;
    this.number = data.number ?? "";
    this.path = data.path;
    this.state = data.state;
    this.supplierId = data.supplierId ?? "";
    this.supplierName = data.supplierName ?? "";
    this.thumbnails = data.thumbnails;
    this.totalByType = data.totalByType;
    this.updatedCosts = data.updatedCosts
      ? Object.fromEntries(
          Object.entries(data.updatedCosts).filter(
            ([key, value]) => !(value as UpdatedCosts).deleted
          )
        )
      : data.updatedCosts;
    this.url = data.url;
    this.userId = data.userId;
    this.comments = data.comments;
    this.orientation = data.orientation;
    this.ocrOrientation = data.ocrOrientation;
    this.createdAt = safeTimestamp(data.createdAt)?.toDate() as Date;
    this.updatedAt = safeTimestamp(data.updatedAt)?.toDate() ?? this.createdAt;
    this.startedAt = safeTimestamp(data.startedAt)?.toDate();
    this.resolvedAt = safeTimestamp(data.resolvedAt)?.toDate() as Date;
    this.checkedAt = safeTimestamp(data.checkedAt)?.toDate() as Date;
    this.ocrDataFiles = data.ocrDataFiles;
    this.documentAIInvoiceDataFiles = data.documentAIInvoiceDataFiles;
    this.isFromApTransaction = data.isFromApTransaction;
    this.isFromAdminPanel = data.isFromAdminPanel ?? false;
    this.itemsList = data.itemsList;
    this.resolvedBy = data.resolvedBy;
    this.editedBy = data.editedBy;
    this.isChecked = data.isChecked;
    this.invoiceComments = data.invoiceComments;
    this.checkedBy = data.checkedBy ?? "";
    this.invoiceId = data.invoiceId ?? data.id;
    this.newAliases = data.newAliases ?? {};
    this.aliasesUsed = data.aliasesUsed ?? [];
    this.votedBy = data.votedBy;
    this.votedAt = safeTimestamp(data.votedAt)?.toDate();
    this.createdBy = data.createdBy ?? "";
    this.invoiceRefPath = data.invoiceId
      ? `users/${data.userId}/invoices/${data.invoiceId}`
      : data.invoiceRefPath ?? "";
    this.resolvingDuration = data.resolvingDuration;
    this.editedDuration = data.editedDuration;
    this.isDuplicate = data.isDuplicate ?? false;
    this.isDiverseInvoice = data.isDiverseInvoice ?? false;
    this.headerVoteValues = data.headerVoteValues;
    this.invoiceConsensusReached = data.invoiceConsensusReached;
    this.oldestDate = safeTimestamp(data.oldestDate)?.toDate();
    this.lastVotedAt = safeTimestamp(data.lastVotedAt)?.toDate();
    this.voters = data.voters;
    this.isInvoiceNumberUsed = data.isInvoiceNumberUsed;
    this.isForSupervisor = data.isForSupervisor ?? false;
    this.isForSupervisorMarkedBy = data.isForSupervisorMarkedBy;
    this.commentedBy = data.commentedBy;
  }
}

// for userItems used
export type Items = {
  autoTotal: boolean;
  autoQuantity: boolean;
  id: string;
  cost: number;
  archived: boolean;
  deleted: boolean;
  isFee: boolean;
  isNew: boolean;
  name: string;
  size: number;
  type: string;
  unit: string;
  used: boolean;
  variety: string;
  nameChanged: boolean;
  aliases: string[];
  silhouettePath: string | null;
  bundleName?: string;
  hidden?: boolean;
  globalId?: string | null; // optional properties
  silhouetteName?: string | null;
  tags?: string[];
  itemId?: string | null;
  createdAt?: Timestamp | null;
  starred?: boolean;
  avgCost?: number | null;
  stock?: number;
  itemsV2?: unknown; // adjust this type as needed
  updatedAt?: Timestamp | null;
  timestamp?: number;

  globalData?: any;
  userId?: string; // for handle connecting silhouettes
};

export type Fee = {
  id: string;
  aliases: string[];
  archived: boolean;
  createdAt: Date;
  deleted: boolean;
  isFee: boolean;
  name: string;
  updatedAt: Date | null;
};

export type ProcessedUserItem = Items & {
  recipesWithItem: string[];
  posItemsWithItem: string[];
};

// for items object in invoice
export type InvoiceItemFirebase = {
  aliases: string[];
  autoQuantity: boolean;
  autoTotal: boolean;
  changedProperty: string;
  cost: number;
  errorCost: string;
  errorQuantity: string;
  errorTotal: string;
  hasError: boolean;
  index: number;
  invoicePage?: number;
  isFee: boolean;
  isNew: boolean;
  isOpenItem: boolean;
  nameChanged: boolean;
  oldCost: number;
  quantity: number;
  searchQuery?: string;
  size: number;
  totalAfterTax?: number;
  type: string;
  unit: string;
  uuid: string;
  allUuidFromVotes?: string[];
  variety: string;
  wasOpenItem: boolean;
  ocrQuery?: string;
};

// for itemsList array in invoice
export type InvoiceItem = InvoiceItemFirebase & {
  id: string;
  name: string;
  total: number;
  orientation?: Record<string, number>;
  isSponsored?: boolean;
  isDuplicate?: boolean;
  taxDeduction?: number;
  totalComputed?: boolean;
  fromVoting?: boolean;
  isIdReachConsensus?: boolean;
  itemVoteValue?: {
    total: number;
    quantity: number;
    cost: number;
  };
  isFromFloatingButtons?: boolean;
};

export type UpdatedCosts = {
  old: number;
  new: number;
  deleted: boolean;
};

export type FileUpload = {
  userId: string;
  file: File;
};

export type CheckedInvoice = {
  isChecked: boolean;
  checkedBy: string;
  newAliases: {
    [itemId: string]: {
      name: string;
      addedBy: string;
      itemIndex: number;
    }[];
  };
  supplierName: string;
  supplierId: string;
  invoiceId: string;
  userId: string;
  checkedAt: Date | null;
  items: Record<string, InvoiceItemFirebase>;
  updatedAt: Date;
};

export type DocumentAI = {
  document: {
    pages: {
      dimension: { height: number; width: number };
      tables: AITable[];
    };
  };
  fileName: string;
};

export type AITable = {
  layout: {
    boundingPoly: {
      normalizedVertices: Vertex[];
      vertices: Vertex[];
    };
  };
  tableType?: string;
  typeAutoSelected: boolean;
};

export function mapInvoiceItemToInvoiceItemFirebase(
  input: InvoiceItem
): InvoiceItemFirebase {
  const result: InvoiceItemFirebase = { ...input } as InvoiceItemFirebase;
  const inputProperties = ["id", "name", "total"];
  inputProperties.forEach((prop) => {
    Reflect.deleteProperty(result, prop);
  });
  return result;
}

export type Alias = { name: string; addedBy: string; itemIndex: number };

export type InvoiceVote = {
  id: string;
  invoiceRefPath: string;
  voters: string[];
  userId: string;
  lastVotedAt: Date;
  invoiceComments: string;
};
