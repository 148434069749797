import { DataLabelingContext } from "pages/data-labeling";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
  useCallback,
  lazy,
} from "react";

// EXTERNAL PACKAGES
import moment from "moment-timezone";
import { NumericFormat } from "react-number-format";

import { BulkAddedOpenItem } from "components/invoices/invoice-ocr";
import { runVoterBot } from "services/invoice";
// UTILITY FUNCTIONS AND CONSTANTS
import { formatNumber, formatOcrNumber } from "utils/common";
import { INVOICE, dateInvoiceKey } from "utils/constants";
import { v4 as uuidv4 } from "uuid";
import { TAX_DEDUCTIONS, voterBots } from "./constants";
import {
  calculateTotals,
  deepReplaceNaN,
  filterByItemTags,
  getItemClicksOcr,
  getItemTags,
  mergeDuplicateItems,
  serializeOcrClicks,
} from "./helpers";

import InvoiceOCR from "../invoice-ocr";
import Item from "../item";
// COMPONENTS
import ItemSearch from "../items-search";
import Suppliers from "../suppliers-dropdown";
import UploadImage from "../upload-image";
const InvoicePopover = lazy(
  () => import("components/invoices/invoice-popover")
);
const Image = lazy(() => import("components/invoices/image"));

import alertActions from "redux/alert/actions";
// REDUX
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import {
  Invoice,
  InvoiceItem,
  InvoiceItemFirebase,
  Items,
} from "redux/invoice/types";
import actionsOcr from "redux/ocr/actions";
import { Selection } from "redux/ocr/types";

import {
  Alert,
  Button,
  Checkbox,
  Classes,
  Dialog,
  Divider,
  InputGroup,
  Intent,
  Label,
  MenuItem,
  Popover,
  Position,
  useHotkeys,
} from "@blueprintjs/core";
// STYLES
import { DateFormatProps, DateInput3 } from "@blueprintjs/datetime2";
import "./index.css";
import { ItemPredicate, Select } from "@blueprintjs/select";
import { createSelector } from "@reduxjs/toolkit";
import { cn } from "@stockifi/shared";
import {
  getFormattedItem,
  getResolvingItems,
  getVoteWeight,
  highestOccurrences,
} from "components/data-voting/invoice-votes-results/helpers";
import InputActionConfirmationDialogue from "components/input-action-confirmation-dialogue";
import { httpsCallable } from "firebase/functions";
import _, { debounce } from "lodash";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { AutoSizer, List, ListRowProps } from "react-virtualized";
import { RootState } from "redux/store";
import { Supplier } from "redux/suppliers/types";
import { getUserLongCurrency } from "redux/users/helpers";
import { functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { claims, hasAccess } from "../../../permissions";
import InvoiceItemTags from "../invoice-item-tags";
import { selections } from "../invoice-ocr/constants";
import ItemCreate from "../item-create";
import {
  autoQuantityfillItemsCheck,
  autoTotalfillItemsCheck,
  hasErrorCheck,
  isItemPrefilled,
  nameChangedCheck,
  negativeCheck,
  newItemCheck,
  openItemCheck,
  percentage75Check,
  percentageCheck,
  taxDeductionCheck,
  wasOpenItemCheck,
} from "../tag.helpers";
import TotalItem from "./total-item";

type Props = {
  data: Invoice;
  invoicePageNumber: number;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  imgFile: string;
  visible: boolean;
  updateGrandTotal: (func: number) => void;
  updateMatFoodTotal: (func: number) => void;
  updateDrinkTotal: (func: number) => void;
  updateDiverseTotal: (func: number) => void;
  updateFeeTotal: (func: number) => void;
  searchInput: string;
  divScrollPosition: number;
  initialItems: InvoiceItem[];
  isTable?: boolean;
  time?: number;
  invoicesFromVote: Invoice[];
  existingVoteNumbers: string[];
};

function InvoiceComponent({
  data,
  invoicePageNumber,
  setSelectedImage,
  imgFile,
  visible,
  updateGrandTotal,
  updateMatFoodTotal,
  updateDrinkTotal,
  updateDiverseTotal,
  updateFeeTotal,
  searchInput,
  isTable,
  time,
  initialItems,
  divScrollPosition,
  invoicesFromVote,
  existingVoteNumbers,
}: Props) {
  const selectInvoiceSelections = (state: RootState) =>
    state.invoiceOcr.invoiceSelections;
  const selectMatchedOcrSelections = createSelector(
    [selectInvoiceSelections],
    (invoiceSelections) =>
      invoiceSelections.find((s) => s.invoiceId === (data.invoiceId ?? data.id))
  );
  const resolvingDraftInvoice = useAppSelector(
    (state) => state.invoices.resolvingDraftInvoice
  );
  const isFloatingButtonsVisible = useAppSelector(
    (state) => state.invoices.isFloatingButtonsVisible
  );

  const userAuth = useAppSelector((state) => state.auth.user);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const items = useAppSelector((state) => state.invoices.userItems);
  const itemsTable = useAppSelector(
    (state) => state.invoices.userItemsTable[data.userId]?.userItems
  );
  const userFees = useAppSelector((state) => state.invoices.fees);
  const feesTable = useAppSelector(
    (state) => state.invoices.userFeesTable[data.userId]?.userFees
  );
  const itemCreateWidth = useAppSelector(
    (state) => state.invoices.itemCreateWidth
  );
  const ocrSelections = useAppSelector(selectMatchedOcrSelections);
  const minConsensus = useAppSelector(
    (state) => state.settings.dataVotingConfig.minConsensus
  );
  const loadingInvoiceVoteDraft = useAppSelector(
    (state) => state.invoices.loadingInvoiceVoteDraft
  );
  const users = useAppSelector((state) => state.users.users);

  const userItems = isTable ? itemsTable : items;
  const fees = isTable ? feesTable : userFees;
  const { modification } = useContext(DataLabelingContext);

  const dispatch = useAppDispatch();
  const [initialLoad, setInitialLoad] = useState(true);
  const [newItems, setNewItems] = useState<InvoiceItem[]>([]);
  const [newInvoice, setNewInvoice] = useState<Invoice>(data);
  const [isDuplicate, setDuplicate] = useState<boolean>(data.isDuplicate);
  const [isDiverseInvoice, setDiverseInvoice] = useState<boolean>(
    data.isDiverseInvoice
  );
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [expandItems, setExpandItems] = useState(true);
  const [openNoPriceAlert, setNoPriceAlert] = useState(false);
  const [openNoItemsAlert, setNoItemsAlert] = useState(false);
  const [foodTotalError, setFoodTotalError] = useState("");
  const [invoiceNumberError, setInvoiceNumberError] = useState("");
  const [hasInputError, setHasInputError] = useState({
    date: false,
    number: false,
    item: false,
    food: false,
  });
  const [duplicateInvoices, setDuplicateInvoices] = useState<Invoice[]>([]);
  const [ocrSupplierQuery, setOcrSupplierQuery] = useState<string>("");
  const [uploadedByUserAlert, setUploadedByUserAlert] = useState(false);
  const [valueAlert, setValueAlert] = useState(false);
  const [dateAlert, setDateAlert] = useState(false);
  const [openItemCreate, setOpenItemCreate] = useState(false);
  const [disableOcr, setDisableOcr] = useState(false);
  const [ocrItemQuery, setOcrItemQuery] = useState({
    value: "",
  });
  const [notFoundItemQuery, setNotFoundItemQuery] = useState("");
  const [ocrClicks, setOcrClicks] = useState<Selection[]>();
  const [invoiceOcrInFocus, setInvoiceOcrInFocus] = useState<Selection[]>([]);
  const [feeTotal, setFeeTotal] = useState({
    delivery: 0,
    caution: 0,
    rent: 0,
    total: 0,
  });
  const [drinkTotal, setDrinkTotal] = useState(0);
  const [foodTotal, setFoodTotal] = useState(0);
  const [diverseTotal, setDiverseTotal] = useState(0);
  const [openItemTotal, setOpenItemTotal] = useState(0);
  const [fiveteenMva, setFiveteenMva] = useState(0);
  const [twentyFiveMva, setTwentyFiveMva] = useState(0);
  const [scrollPosition, setScrollPosition] = useState<number | undefined>();
  const [openItemInitialQueries, setOpenItemInitialQueries] = useState<
    Record<string, string>
  >({});
  const [openItemQueries, setOpenItemQuery] = useState<Record<string, string>>(
    {}
  );
  const [openItemTrigger, setOpenItemTrigger] = useState<
    Record<string, string>
  >({});
  const [searchInsideItemQueries, setSearchInsideItemQueries] = useState<
    Record<string, string>
  >({});
  const [searchInsideItemFocus, setSearchInsideItemFocus] = useState<
    string | undefined
  >();
  const [openItemSearchFocus, setOpenItemSearchFocus] = useState<
    string | undefined
  >();
  const [voterEngine, setVoterEngine] = useState("");

  const [autoDetectedItemTotal, setAutoDetectedItemTotal] = useState({
    value: null as string | null,
    selection: {} as Selection | null,
  });
  const [autoDetectedQty, setAutoDetectedQty] = useState({
    value: null as string | null,
    selection: {} as Selection | null,
  });
  const [quantityFocus, setQuantityFocus] = useState("");
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [fetchedOCR, setFetchedOCR] = useState<string[]>([]);
  // initiate ref to get col-4 width
  const col4Ref = useRef<HTMLDivElement>(null);

  const [stackableTags, setStackableTags] = useState({
    percentChanged: [] as InvoiceItem[],
    percentChanged75: [] as InvoiceItem[],
    new: [] as InvoiceItem[],
    negative: [] as InvoiceItem[],
    nameChanged: [] as InvoiceItem[],
    openItem: [] as InvoiceItem[],
    autoTotal: [] as InvoiceItem[],
    autoQuantity: [] as InvoiceItem[],
    wasOpenItem: [] as InvoiceItem[],
    hasError: [] as InvoiceItem[],
    sponsored: [] as InvoiceItem[],
    taxDeduction: [] as InvoiceItem[],
  });

  const [initialTags, setInitialTags] = useState<Record<string, InvoiceItem[]>>(
    {}
  );
  const [openItemCreateFromItemList, setOpenItemCreateFromItemList] = useState<{
    open: boolean;
    idx: number;
    query: string;
  }>({ open: false, idx: 0, query: "" });
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [voterBotLoading, setVoterBotLoading] = useState(false);
  const [isItemAlreadyRender, setIsItemAlreadyRender] = useState<
    Record<string, boolean>
  >({ "": false });
  const [savingInvoice, setSavingInvoice] = useState(false);
  const [activeOCRItemCard, setActiveOCRItemCard] = useState<number | null>(
    null
  );
  const [popoverSearchItemSelectOpen, setPopoverSearchItemSelectOpen] =
    useState<number | null>(null);
  const [aliasesUsed, setAliasesUsed] = useState<string[]>([]);

  const MINIMUM_TOTAL_VALUE = 500;

  // biome-ignore lint:react-hooks/exhaustive-deps
  const totalWeight = useMemo(() => {
    return invoicesFromVote.reduce((acc, vote) => {
      return acc + (vote.votedBy ? getVoteWeight(vote.votedBy) : 0);
    }, 0);
  }, [invoicesFromVote, users.length]);

  const userCurrency = useMemo(
    () => getUserLongCurrency(newInvoice.userId),
    [newInvoice.userId]
  );

  const minimumValue =
    userCurrency === "NOK" ? MINIMUM_TOTAL_VALUE : MINIMUM_TOTAL_VALUE / 10;

  const initialItemsWithTag = useMemo(
    () => Object.values(initialTags).flat(),
    [initialTags]
  );

  // biome-ignore lint:react-hooks/exhaustive-deps
  const draftInvoice = useMemo(() => {
    // get draft invoice from local storage
    return localStorage.getItem(`${data.userId}/${data.invoiceId ?? data.id}`);
  }, [data.id, data.invoiceId]);

  const [selectedItemTags, setSelectedItemTags] = useState<string[]>([]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (ocrClicks && newItems.length) {
      const newOcrClicks = ocrClicks.map((x) => {
        if (!x.selectedItem || !x.selectedItemIndex) return x;
        else {
          const items = newItems.filter((item) => item.id === x.selectedItem);
          if (!items || !items.length || items.length > 1) return x;
          return {
            ...x,
            selectedItemIndex: items[0]?.index,
          };
        }
      });
      setOcrClicks(newOcrClicks);
    }
    setStackableTags((prev) => ({
      ...prev,
      percentChanged: newItems.filter((item) => percentageCheck(item)),
      percentChanged75: newItems.filter((item) => percentage75Check(item)),
      new: newItems.filter((item) => newItemCheck(item)),
      negative: newItems.filter((item) => negativeCheck(item)),
      nameChanged: newItems.filter((item) => nameChangedCheck(item)),
      openItem: newItems.filter((item) => openItemCheck(item)),
      autoTotal: newItems.filter((item) => autoTotalfillItemsCheck(item)),
      autoQuantity: newItems.filter((item) => autoQuantityfillItemsCheck(item)),
      wasOpenItem: newItems.filter((item) => wasOpenItemCheck(item)),
      hasError: newItems.filter((item) => hasErrorCheck(item)),
      sponsored: newItems.filter((item) => item.isSponsored),
      taxDeduction: newItems.filter((item) => taxDeductionCheck(item)),
    }));
  }, [newItems]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    function handleResize() {
      if (col4Ref && col4Ref.current) {
        const col4Width = col4Ref.current?.offsetWidth;
        dispatch(actions.SET_STATE({ itemCreateWidth: col4Width * 0.8 }));
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [col4Ref, dispatch]);

  useEffect(() => {
    if (!expandItems) setY(0);
  }, [expandItems]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (ocrSelections) {
      if (ocrClicks && ocrClicks.length > 0) {
        setOcrClicks([
          ...ocrClicks,
          ...ocrSelections.ocrClicks.filter((x) => x.imageUrl === imgFile),
        ]);
      } else {
        setOcrClicks(ocrSelections.ocrClicks);
      }
    }
  }, [ocrSelections]);

  const foodRef = useRef<HTMLInputElement>(null);
  const invoiceRef = useRef<HTMLDivElement>(null);
  const itemSearchRef = useRef<HTMLInputElement>(null);

  // update grand total
  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    const totals = calculateTotals(newItems, newInvoice.foodTotal);
    setGrandTotal(totals.grandTotal);
    setFeeTotal({
      delivery: totals.feeTotal.delivery,
      caution: totals.feeTotal.caution,
      rent: totals.feeTotal.rent,
      total: totals.feeTotal.total,
    });
    setFiveteenMva(totals.fiveteenMva);
    setTwentyFiveMva(totals.twentyFiveMva);
    setFoodTotal(totals.matFoodTotal);
    setDiverseTotal(totals.diverseTotal);
    setDrinkTotal(totals.drinkTotal);
    setOpenItemTotal(totals.openItemTotal);
    updateGrandTotal(totals.grandTotal);
    updateMatFoodTotal(totals.matFoodTotal);
    updateDrinkTotal(totals.drinkTotal);
    updateDiverseTotal(totals.diverseTotal);
    updateFeeTotal(totals.feeTotal.total);
  }, [newItems, newInvoice.foodTotal]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      visible &&
      (data.state === "resolved" ||
        (data.state === "unresolved" && totalWeight >= (minConsensus ?? 1))) &&
      !fetchedOCR.includes(imgFile)
    ) {
      dispatch(
        actionsOcr.GET_OCR_SELECTION({
          userId: data.userId,
          invoiceId: data.invoiceId ?? data.id,
          imageUrl: imgFile,
        })
      );
      setFetchedOCR((prev) => [...prev, imgFile]);
    }

    if (resolvingDraftInvoice !== "")
      dispatch(
        actions.SET_STATE({
          resolvingDraftInvoice: "",
        })
      );

    return () => {
      if (!visible)
        dispatch(
          actionsOcr.REMOVE_OCR_SELECTION_STATE({
            invoiceId: data.invoiceId ?? data.id,
          })
        );
    };
  }, [data.id, data.userId, dispatch, visible, imgFile]);

  useEffect(() => {
    if (!initialLoad) return;

    const initializeInvoice = () => {
      if (draftInvoice && data.state === "unresolved") {
        const parsedDraftInvoice = JSON.parse(draftInvoice);
        if (parsedDraftInvoice) {
          parsedDraftInvoice.isDraft = true;
          for (const key of Object.keys(parsedDraftInvoice)) {
            if (parsedDraftInvoice[key] && dateInvoiceKey.includes(key)) {
              parsedDraftInvoice[key] = new Date(parsedDraftInvoice[key]);
            }
          }

          if (
            parsedDraftInvoice.id === data.id &&
            parsedDraftInvoice.userId === data.userId &&
            parsedDraftInvoice.itemsList
          ) {
            setNewItems(parsedDraftInvoice.itemsList);
            setNewInvoice(parsedDraftInvoice);
            setInitialTags(getItemTags(parsedDraftInvoice.itemsList));
          }

          setAliasesUsed(parsedDraftInvoice.aliasesUsed ?? []);
        }
      } else {
        const items = initialItems ? [...initialItems] : [];
        if (data.state === "unresolved" && totalWeight >= (minConsensus ?? 1)) {
          const formattedItemsLengthDetail = invoicesFromVote.map((vote) => ({
            value: String(vote.itemsList.length),
            weight: getVoteWeight(vote.votedBy),
          }));
          const itemsLengthConsensus = highestOccurrences(
            formattedItemsLengthDetail,
            totalWeight
          );
          const formattedItems = getFormattedItem(invoicesFromVote);
          const itemsFromVote =
            itemsLengthConsensus && Number(itemsLengthConsensus) === 0
              ? []
              : getResolvingItems(
                  formattedItems,
                  totalWeight,
                  itemsLengthConsensus
                    ? Number(itemsLengthConsensus)
                    : Math.max(
                        1,
                        ...invoicesFromVote.map((x) => x.itemsList.length)
                      )
                );
          // biome-ignore lint:noForEach
          itemsFromVote.forEach((item) => {
            items.push({
              ...item,
              fromVoting: true,
              itemVoteValue: {
                total: item.total,
                quantity: item.quantity,
                cost: item.cost,
              },
              isIdReachConsensus: item.id !== "",
            });
          });
        }

        items.sort((x, y) => x.index - y.index);
        setNewItems(items);
        const initialOpenItemQueries: Record<string, string> = {};
        for (const item of items) {
          if (!item.id && item.name && item.uuid) {
            initialOpenItemQueries[item.uuid] = item.name;
          }
        }
        if (Object.keys(initialOpenItemQueries).length > 0)
          setOpenItemInitialQueries(initialOpenItemQueries);
        setInitialTags(getItemTags(items));
      }
    };

    initializeInvoice();
    setInitialLoad(false);
  }, [
    initialLoad,
    draftInvoice,
    data,
    initialItems,
    invoicesFromVote,
    totalWeight,
    minConsensus,
  ]);

  const updateNewItemsAndSaveDraft = (
    items: InvoiceItem[],
    invoiceData?: Invoice
  ) => {
    setNewItems(items);
    saveDraftInvoice(items, invoiceData);
  };

  const validate = useCallback(
    debounce(async (value) => {
      if (!functions) return;
      const duplicateInvoicesRes = httpsCallable<
        { userId: string; number: string },
        string
      >(functions, CALLABLE_FUNCTIONS.getDuplicateInvoices);

      try {
        const resp = await duplicateInvoicesRes({
          userId: data.userId,
          number: value,
        });

        if (resp.data) {
          const duplicateInvoicesData: Invoice[] = JSON.parse(resp.data);
          setDuplicateInvoices(
            duplicateInvoicesData.filter((x) => x.id !== data.id)
          );
          setLoadingValidation(false);
        }
      } catch (error) {
        console.error("Error while validating invoice number", error);
        setLoadingValidation(false);
      }
    }, 500),
    [data.id, data.userId]
  );

  function validateInvoiceNumber(value: string) {
    setLoadingValidation(true);
    validate(String(value).trim());
    setDuplicateInvoices([]);
  }

  const isDeliveryDateMoreThanSixtyDays = (date?: string) => {
    const forbiddenClaims = !(
      authClaims?.supervisor ||
      authClaims?.admin ||
      authClaims?.headDataManager ||
      // TEMPORARY CLAIM FOR SPECIAL DATA MANAGERS TO ACCESS AND EDIT INVOICES WITH OLDER DELIVERY DATE
      authClaims?.olderInvoices
    );
    const sixtyDaysAgo = new Date().setDate(new Date().getDate() - 60);
    const deliveryDate = date
      ? new Date(date)
      : new Date(newInvoice.deliveryDate);
    return forbiddenClaims && deliveryDate <= new Date(sixtyDaysAgo);
  };

  useEffect(() => {
    if (!initialLoad) {
      let invoiceNumberUsed = "";
      let numberHasInputError = false;
      if (newInvoice.number !== "") {
        if (
          duplicateInvoices.length > 0 &&
          !existingVoteNumbers.includes(newInvoice.number)
        ) {
          invoiceNumberUsed = INVOICE.INVOICE_NUMBER_EXISTS;
          numberHasInputError = true;
        } else if (
          existingVoteNumbers.length > 0 ||
          existingVoteNumbers.includes(newInvoice.number)
        ) {
          invoiceNumberUsed = INVOICE.INVOICE_NUMBER_EXISTS_FROM_VOTE;
          numberHasInputError = true;
        }
      }
      setInvoiceNumberError(invoiceNumberUsed);
      setHasInputError((prevState) => ({
        ...prevState,
        number: numberHasInputError,
      }));
    }
  }, [existingVoteNumbers, duplicateInvoices.length, newInvoice]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (newInvoice.number !== "") {
      validateInvoiceNumber(newInvoice.number);
    }
  }, [newInvoice.number]);

  useEffect(() => {
    if (isTable && initialItems.length) setInitialLoad(true);
  }, [isTable, initialItems.length]);

  const userItemsAndFees = useMemo(() => {
    const processedFees: Items[] = fees.map((fee) => ({
      autoTotal: false,
      autoQuantity: false,
      id: fee.id,
      cost: 0,
      archived: fee.archived,
      deleted: fee.deleted,
      isFee: fee.isFee,
      isNew: false,
      name: fee.name,
      size: 0,
      type: "fee",
      unit: "",
      used: false,
      variety: "",
      nameChanged: false,
      aliases: fee.aliases ?? [],
      silhouettePath: null,
    }));
    const processedUserItems = userItems
      .map((item) => {
        const copyItem = { ...item };
        if (newInvoice.newAliases) {
          const newAliasToAdd = newInvoice.newAliases[item.id]?.map(
            (x) => x.name
          );
          if (newAliasToAdd) {
            copyItem.aliases = _.uniq([
              ...(copyItem.aliases ?? []),
              ...newAliasToAdd,
            ]);
          }
        }
        return {
          ...copyItem,
          isFee: false,
        };
      })
      .filter((item) => !(item.deleted || item.archived));
    return [...processedUserItems, ...processedFees];
  }, [userItems, fees, newInvoice.newAliases]);

  const hotkeys = useMemo(
    () => [
      {
        combo: "alt+3",
        label: "Toggle invoice floating buttons",
        onKeyDown: () => {
          dispatch(
            actions.SET_STATE({
              isFloatingButtonsVisible: !isFloatingButtonsVisible,
            })
          );
        },
        global: true,
      },
    ],
    [dispatch, isFloatingButtonsVisible]
  );

  const itemOcrSelections = useMemo(() => {
    if (newItems.length === 0 || !ocrClicks) return [];
    return getItemClicksOcr(ocrClicks, newItems);
  }, [newItems, ocrClicks]);

  const filteredNewItems = useMemo(() => {
    if (newItems.length === 0 || !newItems) return [];
    return newItems
      .filter((item) => {
        if (!selectedItemTags.includes("searchInput")) return true;
        return item.name?.toLowerCase().includes(searchInput.toLowerCase());
      })
      .filter((item) => {
        const newSelectedItemTags = selectedItemTags.filter(
          (x) =>
            x !== "searchInput" && x !== "notPrefilled" && x !== "newAliases"
        );
        const initialItem = initialItemsWithTag.find(
          (x) => x.uuid === item.uuid
        );
        return (
          filterByItemTags(data, item, newSelectedItemTags) ||
          filterByItemTags(data, initialItem, newSelectedItemTags)
        );
      })
      .filter((item) => {
        if (
          modification === "dataVoting" &&
          selectedItemTags.includes("notPrefilled")
        ) {
          return !isItemPrefilled(item);
        }
        return true;
      })
      .filter((item) => {
        if (selectedItemTags.includes("newAliases") && data.newAliases) {
          return Object.keys(data.newAliases).includes(item.id);
        }
        return true;
      });
  }, [
    newItems,
    selectedItemTags,
    modification,
    searchInput,
    data,
    initialItemsWithTag,
  ]);

  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  // biome-ignore lint:react-hooks/exhaustive-deps
  const executeVoterBot = useCallback(async () => {
    if (!voterEngine) return;

    setVoterBotLoading(true);

    const result = await runVoterBot(data.refPath, voterBots[voterEngine]);

    if (result.data) {
      const resultData = result.data.result;
      if (typeof resultData === "string") {
        dispatch(alertActions.ERROR(resultData));
      } else {
        setNewInvoice({
          ...newInvoice,
          ...resultData,
          // set time-related fields to initial values
          createdAt: newInvoice.createdAt,
          updatedAt: newInvoice.updatedAt,
          resolvedAt: newInvoice.resolvedAt,
          votedAt: newInvoice.votedAt,
          checkedAt: newInvoice.checkedAt,
          startedAt: newInvoice.startedAt,
          newAliases: newInvoice.newAliases ?? {},
        });
        setNewItems(resultData.itemsList);
        dispatch(
          alertActions.SUCCESS(
            `Voter bot executed successfully, voted by ${result.data.votedBy} bot`
          )
        );
      }
    }

    setVoterBotLoading(false);
    setVoterEngine("");
  }, [voterEngine]);

  const saveImageOrientation = useCallback(
    (newUpdatedInvoice: Invoice) => {
      dispatch(
        actions.UPDATE_INVOICE({
          invoice: newUpdatedInvoice,
          notify: true,
          refPath: newUpdatedInvoice.refPath,
        })
      );
    },
    [dispatch]
  );

  // biome-ignore lint:react-hooks/exhaustive-deps
  const changeImageOrientation = useCallback(
    (
      file: string,
      orientation: number,
      type: "img" | "ocr" | "both" = "img",
      ocrOrientation?: number
    ) => {
      const newData: Invoice = _.cloneDeep(newInvoice);

      if (type === "both" && ocrOrientation !== undefined) {
        const newUpdatedInvoice = {
          ...newData,
          orientation: {
            ...(newData.orientation ?? {}),
            [file]: orientation,
          },
          ocrOrientation: {
            ...(newData.ocrOrientation ?? {}),
            [file]: ocrOrientation,
          },
        };
        setNewInvoice(newUpdatedInvoice);
        saveImageOrientation(newUpdatedInvoice);
        return;
      }

      const keyToUpdate = type === "img" ? "orientation" : "ocrOrientation";
      const newUpdatedInvoice = {
        ...newData,
        [keyToUpdate]: {
          ...(newData[keyToUpdate] ?? {}),
          [file]: orientation,
        },
      };
      setNewInvoice(newUpdatedInvoice);
      saveImageOrientation(newUpdatedInvoice);
    },
    [newInvoice]
  );

  const orientation = useMemo(() => {
    let ocr = 0;
    let image = 0;

    if (data && data.orientation && data.orientation[imgFile]) {
      image = data.orientation[imgFile];
    }

    if (data && data.ocrOrientation && data.ocrOrientation[imgFile]) {
      ocr = data.ocrOrientation[imgFile];
    }

    return {
      ocr,
      image,
    };
  }, [data, imgFile]);

  if (!visible) return null;

  const handleStop: DraggableEventHandler = (_, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
  };

  function onItemCreated(item: Items, initialQuery: string) {
    setOpenItemCreateFromItemList((prev) => ({
      ...prev,
      open: false,
      query: "",
    }));
    if (openItemCreate) setOpenItemCreate(false);
    if (disableOcr) setDisableOcr(false);
    openItemIdSelected(
      item,
      newItems[openItemCreateFromItemList.idx],
      true,
      initialQuery
    );
  }

  function onCreateItemButtonClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    idx: number,
    query: string
  ) {
    event.currentTarget.blur();
    if (event.clientY > 250 && expandItems) {
      setY(-event.clientY - 200);
    }
    setOpenItemCreateFromItemList((prev) => {
      setDisableOcr(!prev.open);
      return {
        idx: idx,
        query: query,
        open: !prev.open,
      };
    });
  }

  function openItemIdSelected(
    selectedInvoiceItem: Items,
    oldItem: InvoiceItem,
    isItemNew: boolean,
    searchQuery = ""
  ) {
    const item = {
      ..._.cloneDeep(oldItem),
      id: selectedInvoiceItem.id,
      isFee: selectedInvoiceItem.isFee,
      isNew: isItemNew,
      name: selectedInvoiceItem.name,
      size: selectedInvoiceItem.size,
      type: selectedInvoiceItem.type,
      unit: selectedInvoiceItem.unit,
      variety: selectedInvoiceItem.variety,
      oldCost: selectedInvoiceItem.cost,
      wasOpenItem: oldItem.isOpenItem && !oldItem.isFromFloatingButtons,
      isOpenItem: false,
      autoTotal: selectedInvoiceItem.autoTotal,
      autoQuantity: selectedInvoiceItem.autoQuantity,
      aliases: selectedInvoiceItem.aliases?.filter((i) => Boolean(i)) ?? [],
      invoicePage:
        oldItem.isOpenItem &&
        oldItem.isFromFloatingButtons &&
        oldItem.invoicePage
          ? oldItem.invoicePage
          : invoicePageNumber + 1,
    };
    if (autoDetectedItemTotal.value !== null) {
      item.autoTotal = true;
      // change newItem total
      item.total = Number(formatOcrNumber(autoDetectedItemTotal.value, 2));
      item.totalAfterTax = item.total;

      if (autoDetectedItemTotal.selection !== null) {
        autoDetectedItemTotal.selection.selectedItemIndex = item.index;
        autoDetectedItemTotal.selection.selectedItem = item.id;
        // add selection to ocrClicks
        ocrClicks
          ? setOcrClicks([...ocrClicks, autoDetectedItemTotal.selection])
          : setOcrClicks([autoDetectedItemTotal.selection]);
      }
      setAutoDetectedItemTotal({ value: null, selection: null });
    }
    if (autoDetectedQty.value !== null) {
      item.autoQuantity = true;
      item.quantity = Number(formatOcrNumber(autoDetectedQty.value, 2));
      if (autoDetectedQty.selection !== null) {
        autoDetectedQty.selection.selectedItemIndex = item.index;
        autoDetectedQty.selection.selectedItem = item.id;
        // add selection to ocrClicks
        ocrClicks
          ? setOcrClicks([...ocrClicks, autoDetectedQty.selection])
          : setOcrClicks([autoDetectedQty.selection]);
      }
      setAutoDetectedQty({ value: null, selection: null });
    }
    if (item.quantity !== 0 && item.total !== 0 && !item.cost) {
      item.cost = Number(
        formatNumber(item.total / item.quantity, 2).replace(/,/g, "")
      );
    } else {
      item.cost = item.cost === 0 ? selectedInvoiceItem.cost : item.cost;
    }
    onItemValueChange(item);
    if (item.quantity === 0) setQuantityFocus(item.uuid);

    let newData = _.cloneDeep(newInvoice);
    if (
      openItemInitialQueries[item.uuid] !== "" ||
      searchInsideItemQueries[item.uuid] !== "" ||
      searchQuery.toLowerCase() === item.name.toLowerCase()
    ) {
      let newAliasToAdd = searchQuery;
      if (ocrClicks && !item.isFromFloatingButtons) {
        if (openItemInitialQueries[item.uuid] !== "" && ocrClicks) {
          const itemQueryInRow = ocrClicks.find((click) => {
            const fullText = Object.values(click.texts).join(" ");
            return fullText === openItemInitialQueries[item.uuid];
          });

          const lastClick = ocrClicks[ocrClicks.length - 1];
          if (
            itemQueryInRow &&
            Object.keys(lastClick.boundingPoly).length &&
            Object.keys(lastClick.texts).length &&
            Object.keys(itemQueryInRow.texts).length &&
            Object.keys(itemQueryInRow.boundingPoly).length
          ) {
            const lastClickYValue = lastClick.boundingPoly[0][0].y;
            const itemQueryInRowYValue = itemQueryInRow.boundingPoly[0][0].y;

            const difference = Math.abs(itemQueryInRowYValue - lastClickYValue);

            if (difference < 5) {
              newAliasToAdd = openItemInitialQueries[item.uuid];
            }
          }
          setNotFoundItemQuery("");
        }
      } else if (
        openItemInitialQueries[item.uuid] !== "" &&
        openItemInitialQueries[item.uuid] !== item.name
      ) {
        newAliasToAdd = openItemInitialQueries[item.uuid];
      }

      // remove initial query when item aliases doesn't contain initial query
      if (
        openItemInitialQueries[item.uuid] &&
        !item.aliases.includes(openItemInitialQueries[item.uuid])
      ) {
        setOpenItemInitialQueries((prev) => {
          if (!prev[item.uuid]) return prev;
          const newState = { ...prev };
          delete newState[item.uuid];
          return newState;
        });
      }

      newData = _.cloneDeep(newInvoice);
      if (
        !!newAliasToAdd?.replace(/\s/g, "") &&
        (!item.aliases || !item.aliases.includes(newAliasToAdd))
      ) {
        if (
          typeof item.aliases === "string" ||
          item.aliases instanceof String
        ) {
          item.aliases = [String(item.aliases), newAliasToAdd];
        } else {
          item.aliases = [...(item.aliases ?? []), newAliasToAdd];
        }
      }

      if (
        !!newAliasToAdd?.replace(/\s/g, "") &&
        !newData.newAliases[item.id]?.some(
          (alias) => alias.name === newAliasToAdd
        ) &&
        !selectedInvoiceItem.aliases?.includes(newAliasToAdd)
      ) {
        const oldData = newData.newAliases[item.id] ?? [];
        newData.newAliases = {
          ...newData.newAliases,
          [item.id]: [
            ...oldData,
            {
              name: newAliasToAdd,
              addedBy: userAuth ?? "",
              itemIndex: item.index,
            },
          ],
        };
      }

      const action = item.isFee
        ? actions.SET_USER_FEES_ALIASES
        : actions.SET_USER_ITEM_ALIASES;

      dispatch(
        action({
          itemId: item.id,
          aliases: item.aliases,
        })
      );

      const oldItemAliases = newData.newAliases[oldItem.id];
      const itemsWithSameId = newItems.filter((x) => x.id === oldItem.id);
      if (
        oldItemAliases &&
        oldItemAliases.length > 0 &&
        itemsWithSameId.length === 1
      ) {
        /// Delete newly added alias from the item state
        const action = oldItem.isFee
          ? actions.SET_USER_FEES_ALIASES
          : actions.SET_USER_ITEM_ALIASES;

        const newAliases = oldItem.aliases.filter(
          (alias) => !oldItemAliases.map((x) => x.name).includes(alias)
        );

        dispatch(
          action({
            itemId: oldItem.id,
            aliases: newAliases,
          })
        );
        // remove new added aliases of the replaced item from invoice newAliases
        newData.newAliases[item.id] = newData.newAliases[oldItem.id];
        delete newData.newAliases[oldItem.id];
      }

      if (ocrClicks && ocrClicks?.length > 0) {
        setOcrClicks((prev) => {
          if (!prev) return prev;
          const lastAddedItemIndex = prev.findLastIndex(
            (selection) =>
              selection.selectedType === selections.Item &&
              !selection.selectedItem
          );
          if (lastAddedItemIndex === -1) return prev;
          const newOcrClicks = prev.map((x, idx) => {
            if (
              idx === lastAddedItemIndex &&
              x.selectedItemIndex === undefined
            ) {
              return {
                ...x,
                selectedItemIndex: item.index,
                selectedItem: item.id,
                selectedUuid: item.uuid,
              };
            }
            return x;
          });
          return newOcrClicks;
        });
      }

      setNewItems((prev) => {
        const newItems = _.cloneDeep(prev);
        newItems[item.index] = item;
        return newItems;
      });
      setNewInvoice(newData);
      setSearchInsideItemQueries((prev) => {
        if (!prev[item.uuid]) return prev;
        const newState = { ...prev };
        delete newState[item.uuid];
        return newState;
      });
    }
  }

  function getMomentFormatter(format: string): DateFormatProps {
    // note that locale argument comes from locale prop and may be undefined
    return {
      formatDate: (date, _) => moment(date).tz("Europe/Oslo").format(format),
      parseDate: (str, _) => moment(str, format).toDate(),
      placeholder: "Select a date...",
    };
  }

  function addInvoiceItem(item: Items, searchQuery: string, isNew = false) {
    const invoiceItem: InvoiceItem = {
      index: newItems.length,
      id: item.id,
      name: item.name,
      cost: Number(item.cost) ?? 0,
      oldCost: Number(item.cost) ?? 0,
      quantity: 0,
      total: 0,
      isFee: item.isFee,
      isNew: isNew,
      type: item.type,
      size: item.size,
      unit: item.unit,
      variety: item.variety,
      errorCost: "",
      errorQuantity: "",
      errorTotal: "",
      changedProperty: "",
      isOpenItem: false,
      wasOpenItem: false,
      nameChanged: false,
      autoTotal: false,
      autoQuantity: false,
      invoicePage: invoicePageNumber + 1,
      uuid: uuidv4(),
      hasError: false,
      aliases: item.aliases,
      searchQuery: searchQuery,
      totalAfterTax: 0,
    };

    // Add new used alias
    const alias = invoiceItem.aliases.find((alias) =>
      alias.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (alias && !aliasesUsed.includes(alias)) {
      setAliasesUsed([...aliasesUsed, alias]);
    }

    if (item.name === "Discount") {
      invoiceItem.quantity = 1;
      invoiceItem.taxDeduction = 0;
    }

    // check if there is autodetected total
    if (autoDetectedItemTotal.value !== null) {
      // change newItem total
      const totalFromOcr = Number(
        formatOcrNumber(autoDetectedItemTotal.value, 2)
      );

      if (totalFromOcr !== 0 && !totalFromOcr) {
        dispatch(
          alertActions.WARNING(
            `OCR autofilled total is not a number : ${autoDetectedItemTotal.value}`
          )
        );
      } else {
        invoiceItem.autoTotal = true;
        invoiceItem.total = totalFromOcr;
        invoiceItem.totalAfterTax = invoiceItem.total;
      }

      if (totalFromOcr === 0) {
        invoiceItem.cost = invoiceItem.oldCost;
        invoiceItem.isSponsored = true;
      }

      if (autoDetectedItemTotal.selection !== null) {
        autoDetectedItemTotal.selection.selectedItemIndex = invoiceItem.index;
        autoDetectedItemTotal.selection.selectedItem = invoiceItem.id;
        // add selection to ocrClicks
        setOcrClicks((prev) => {
          if (autoDetectedItemTotal.selection === null) return prev;
          if (prev) {
            return [...prev, autoDetectedItemTotal.selection];
          }
          return [autoDetectedItemTotal.selection];
        });
      }
      setAutoDetectedItemTotal({ value: null, selection: null });
    }

    if (autoDetectedQty.value !== null) {
      const qtyFromOcr = Number(formatOcrNumber(autoDetectedQty.value, 2));

      if (qtyFromOcr !== 0 && !qtyFromOcr) {
        dispatch(
          alertActions.WARNING(
            `OCR autofilled quantity is not a number : ${autoDetectedQty.value}`
          )
        );
      } else {
        invoiceItem.autoQuantity = true;
        invoiceItem.quantity = qtyFromOcr;
      }

      if (autoDetectedQty.selection !== null) {
        autoDetectedQty.selection.selectedItemIndex = invoiceItem.index;
        autoDetectedQty.selection.selectedItem = invoiceItem.id;
        // add selection to ocrClicks
        setOcrClicks((prev) => {
          if (autoDetectedQty.selection === null) return prev;
          if (prev) {
            return [...prev, autoDetectedQty.selection];
          }
          return [autoDetectedQty.selection];
        });
      }

      setAutoDetectedQty({ value: null, selection: null });
    }

    // Compute the cost total divide to quantity
    if (
      invoiceItem.autoQuantity &&
      invoiceItem.autoTotal &&
      !invoiceItem.isSponsored
    )
      invoiceItem.cost = invoiceItem.total / invoiceItem.quantity;
    let newData = newInvoice;

    if (
      (notFoundItemQuery !== "" && ocrClicks) ||
      searchQuery.toLowerCase() === invoiceItem.name.toLowerCase()
    ) {
      let newAliasToAdd = searchQuery;
      if (notFoundItemQuery !== "" && ocrClicks) {
        const itemQueryInRow = ocrClicks.find((click) => {
          const fullText = Object.values(click.texts).join(" ");
          return fullText === notFoundItemQuery;
        });

        if (itemQueryInRow) {
          const lastClick = ocrClicks[ocrClicks.length - 1];

          const lastClickYValue = lastClick.boundingPoly[0][0].y;
          const itemQueryInRowYValue = itemQueryInRow.boundingPoly[0][0].y;

          const difference = Math.abs(itemQueryInRowYValue - lastClickYValue);

          if (difference < 5) {
            newAliasToAdd = notFoundItemQuery;
          }
        }
        setNotFoundItemQuery("");
      }
      newData = _.cloneDeep(newInvoice);
      if (
        !invoiceItem.aliases ||
        !invoiceItem.aliases.includes(newAliasToAdd)
      ) {
        if (
          typeof invoiceItem.aliases === "string" ||
          invoiceItem.aliases instanceof String
        ) {
          invoiceItem.aliases = [String(invoiceItem.aliases), newAliasToAdd];
        } else {
          invoiceItem.aliases = [...(invoiceItem.aliases ?? []), newAliasToAdd];
        }
      }

      if (
        !!newAliasToAdd?.replace(/\s/g, "") &&
        !newData.newAliases[invoiceItem.id]?.some(
          (alias) => alias.name === newAliasToAdd
        ) &&
        !item.aliases?.includes(newAliasToAdd)
      ) {
        const oldData = newData.newAliases[invoiceItem.id] ?? [];
        newData.newAliases = {
          ...newData.newAliases,
          [invoiceItem.id]: [
            ...oldData,
            {
              name: newAliasToAdd,
              addedBy: userAuth ?? "",
              itemIndex: invoiceItem.index,
            },
          ],
        };
      }

      const action = invoiceItem.isFee
        ? actions.SET_USER_FEES_ALIASES
        : actions.SET_USER_ITEM_ALIASES;

      dispatch(
        action({
          itemId: invoiceItem.id,
          aliases: invoiceItem.aliases,
        })
      );

      setNewInvoice(newData);
    }

    // if last ocr click was item, add selectedItemIndex and selectedItem to it
    if (ocrClicks && ocrClicks?.length > 0) {
      setOcrClicks((prev) => {
        if (!prev) return prev;
        const lastAddedItemIndex = prev.findLastIndex(
          (selection) =>
            selection.selectedType === selections.Item &&
            !selection.selectedItem
        );
        if (lastAddedItemIndex === -1) return prev;
        const newOcrClicks = prev.map((x, idx) => {
          if (idx === lastAddedItemIndex) {
            return {
              ...x,
              selectedItemIndex: invoiceItem.index,
              selectedItem: invoiceItem.id,
              selectedUuid: invoiceItem.uuid,
            };
          }
          return x;
        });
        return newOcrClicks;
      });
    }

    // If total computed
    if (
      invoiceItem.autoQuantity &&
      invoiceItem.cost !== 0 &&
      invoiceItem.quantity !== 0 &&
      invoiceItem.total === 0
    ) {
      invoiceItem.total = invoiceItem.cost * invoiceItem.quantity;
      invoiceItem.totalAfterTax = invoiceItem.total;
      invoiceItem.totalComputed = true;
    }

    if (invoiceRef.current) {
      invoiceRef.current.scrollTop = divScrollPosition;
    }

    updateNewItemsAndSaveDraft([...newItems, invoiceItem], newData);
    setScrollPosition(invoiceItem.index);
    setQuantityFocus(invoiceItem.uuid);
    if (ocrItemQuery) setOcrItemQuery({ value: "" });
    setActiveOCRItemCard(invoiceItem.index);
  }

  function handleDeleteItem(uuid: string) {
    // filter out the item and update index
    const targetItem = newItems.find((x) => x.uuid === uuid);
    if (!targetItem) return;
    const targetOnFilteredItemsIndex = filteredNewItems.findIndex(
      (x) => x.uuid === targetItem.uuid
    );
    if (targetOnFilteredItemsIndex === activeOCRItemCard)
      setActiveOCRItemCard(null);
    if (ocrClicks) {
      const notDeletedOcrClicks = ocrClicks.filter(
        (x) =>
          x.selectedUuid !== targetItem.uuid ||
          x.selectedItemIndex !== targetItem.index
      );

      const newOcrClicks = notDeletedOcrClicks.map((x) => {
        if (
          x.selectedItemIndex !== undefined &&
          x.selectedItemIndex > targetItem.index
        ) {
          return { ...x, selectedItemIndex: x.selectedItemIndex - 1 };
        }
        return x;
      });

      setOcrClicks(newOcrClicks);
    }
    const updatedItems = newItems
      .filter((x) => x.uuid !== uuid)
      .map((x, index) => ({ ...x, index }));

    // update ocr empty item query
    setOpenItemInitialQueries((prev) => {
      if (prev[targetItem.uuid] === undefined) return prev;
      const newOcrEmptyItemQuery = { ...prev };
      delete newOcrEmptyItemQuery[targetItem.uuid];
      return newOcrEmptyItemQuery;
    });
    setSearchInsideItemQueries((prev) => {
      if (prev[targetItem.uuid] === undefined) return prev;
      const newOcrEmptyItemQuery = { ...prev };
      delete newOcrEmptyItemQuery[targetItem.uuid];
      return newOcrEmptyItemQuery;
    });

    updateNewItemsAndSaveDraft(updatedItems);

    const invoiceNewAliases = newInvoice.newAliases;
    if (!invoiceNewAliases) return;

    const itemNewAliases = invoiceNewAliases[targetItem.id];
    const itemsWithSameId = newItems.filter((x) => x.id === targetItem.id);
    if (
      itemNewAliases &&
      itemNewAliases.length > 0 &&
      itemsWithSameId.length === 1
    ) {
      /// Delete newly added alias from the item state
      const action = targetItem.isFee
        ? actions.SET_USER_FEES_ALIASES
        : actions.SET_USER_ITEM_ALIASES;

      const newAliases = targetItem.aliases.filter(
        (alias) => !itemNewAliases.map((x) => x.name).includes(alias)
      );

      dispatch(
        action({
          itemId: targetItem.id,
          aliases: newAliases,
        })
      );
      // remove new added aliases from invoice newAliases
      delete invoiceNewAliases[targetItem.id];
      setNewInvoice((prev) => ({ ...prev, newAliases: invoiceNewAliases }));
    }

    // Remove alias from aliasesUsed
    const alias = targetItem.aliases.find((alias) =>
      aliasesUsed.includes(alias)
    );
    const numberOfAliasesUsed = newItems.reduce((count, itemData) => {
      return (
        count + (itemData.aliases.some((e) => e.includes(alias ?? "")) ? 1 : 0)
      );
    }, 0);

    if (numberOfAliasesUsed === 1 && alias) {
      setAliasesUsed(aliasesUsed.filter((x) => x !== alias));
    }

    // Scroll to the top of the invoice
    if (invoiceRef.current) {
      invoiceRef.current.scrollTop = divScrollPosition;
    }
    setScrollPosition(targetItem.index);
  }

  function onItemValueChange(item: InvoiceItem) {
    // update state item
    const newState = newItems.map((x) => {
      if (x.id === item.id && x.uuid === item.uuid && x.index === item.index)
        return item;
      else return x;
    });

    setNewItems(newState);
  }

  async function saveInvoice() {
    interface SaveInvoiceProps {
      newInvoice: Invoice;
      newItems: InvoiceItem[];
      isDuplicate: boolean;
      isDiverseInvoice: boolean;
      modification: string;
      time?: number;
      userAuth: string;
      ocrClicks?: Selection[];
      grandTotal: number;
      currentUser: string;
      forbiddenClaim: boolean;
    }

    interface CheckResult {
      error: boolean;
      errors: string[];
      errorQueries: string[];
      inputErrors: Record<string, boolean>;
      newItems: InvoiceItem[];
    }

    if (!functions) return;
    const save = httpsCallable<SaveInvoiceProps, CheckResult | string>(
      functions,
      CALLABLE_FUNCTIONS.saveInvoice
    );

    try {
      setSavingInvoice(true);

      const invoiceCopy = _.cloneDeep(newInvoice) as Invoice & { ref?: string }; // just to delete ref, it is not actually string
      invoiceCopy.updatedAt = new Date();
      invoiceCopy.invoiceComments = data.invoiceComments;
      invoiceCopy.aliasesUsed = aliasesUsed;
      // delete ref
      delete invoiceCopy.ref;

      const payload: SaveInvoiceProps = {
        newInvoice: invoiceCopy,
        newItems,
        isDuplicate,
        isDiverseInvoice,
        modification,
        time,
        userAuth: userAuth ?? "",
        ocrClicks: serializeOcrClicks(ocrClicks),
        grandTotal,
        currentUser: userAuth ?? "",
        forbiddenClaim: !(
          authClaims?.supervisor ||
          authClaims?.admin ||
          authClaims?.headDataManager ||
          // TEMPORARY CLAIM FOR SPECIAL DATA MANAGERS TO ACCESS AND EDIT INVOICES WITH OLDER DELIVERY DATE
          authClaims?.olderInvoices
        ),
      };
      const result = await save(deepReplaceNaN(payload));

      if (typeof result.data === "string") {
        dispatch(alertActions.SUCCESS(result.data));
        localStorage.removeItem(
          `${invoiceCopy.userId}/${invoiceCopy.invoiceId ?? invoiceCopy.id}`
        );
      } else {
        if (result.data.error) {
          const errorRes = result.data;

          // set error classes
          // biome-ignore lint:noForEach
          errorRes.errorQueries.forEach((key) => {
            const err = document.querySelector(key);
            err?.classList.add(Classes.INTENT_DANGER);
          });

          // set input errors
          // biome-ignore lint:noForEach
          Object.keys(errorRes.inputErrors).forEach((key) => {
            setHasInputError((prevState) => ({
              ...prevState,
              [key]: errorRes.inputErrors[key],
            }));
          });

          // set alerts
          const errStr = errorRes.errors.join("\n");
          dispatch(alertActions.ERROR(errStr));
        }
      }
    } catch (error) {
      const errorStr: string =
        typeof error === "string"
          ? String(error)
          : typeof (error as Record<string, string>).message === "string"
            ? (error as Record<string, string>).message
            : "Error while saving invoice";

      console.error(error); // this is intended
      console.log(errorStr); // this is intended
      dispatch(alertActions.ERROR(errorStr));
    } finally {
      setSavingInvoice(false);
    }
  }

  function checkAllAlert(...checkAlertList: string[]) {
    const errorChecking = {
      uploadedByUserAlert:
        data.isFromApTransaction === undefined || !data.isFromApTransaction,
      valueAlert: grandTotal > 100000 || grandTotal < minimumValue,
      noPriceAlert:
        newItems.length < 1 &&
        (newInvoice.foodTotal === undefined || newInvoice.foodTotal <= 0),
      dateAlert: isDeliveryDateMoreThanSixtyDays(),
      noItemsAlert: newItems.length === 0,
    };

    if (checkAlertList.length > 0) {
      // biome-ignore lint:noForEach
      Object.keys(errorChecking).forEach((key) => {
        if (!checkAlertList.includes(key)) {
          errorChecking[key as keyof typeof errorChecking] = false;
        }
      });
    }

    if (errorChecking.noItemsAlert) {
      setNoItemsAlert(true);
      return;
    } else if (errorChecking.dateAlert) {
      setDateAlert(true);
      return;
    } else if (errorChecking.uploadedByUserAlert) {
      setUploadedByUserAlert(true);
      return;
    } else if (errorChecking.valueAlert) {
      setValueAlert(true);
      return;
    } else if (errorChecking.noPriceAlert) {
      setNoPriceAlert(true);
      return;
    } else {
      saveInvoice();
    }
  }

  function saveDraftInvoice(
    items: InvoiceItem[],
    invoiceData?: Invoice,
    notify = false
  ) {
    if (data.state !== "unresolved") return;
    const dataInvoice = invoiceData || newInvoice;

    const newData: Invoice = {
      ...dataInvoice,
      items: mergeDuplicateItems(items.filter((item) => item.type !== "fee")),
      itemsList: [...items],
      foodTotal: dataInvoice.foodTotal ? Number(dataInvoice.foodTotal) : 0,
      isDuplicate,
      isDiverseInvoice,
      invoiceRefPath: `users/${dataInvoice.userId}/invoices/${
        dataInvoice.invoiceId ?? dataInvoice.id
      }`,
      votedBy: userAuth ?? "",
      aliasesUsed: aliasesUsed,
    };
    try {
      // save draft to localStorage
      localStorage.setItem(
        `${newData.userId}/${newData.invoiceId ?? newData.id}`,
        JSON.stringify(newData)
      );

      if (notify) dispatch(alertActions.SUCCESS("Draft saved"));

      // store ocr selections
      // dispatch save ocr selections if there are any
      if (ocrClicks) {
        dispatch(
          actionsOcr.SAVE_DATA({
            userId: newData.userId,
            invoiceId: dataInvoice.invoiceId ?? dataInvoice.id,
            data: ocrClicks,
          })
        );
      }
    } catch (error) {
      const isQuotaExceededError =
        error instanceof DOMException &&
        // everything except Firefox
        (error.code === 22 ||
          // Firefox
          error.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          error.name === "QuotaExceededError" ||
          // Firefox
          error.name === "NS_ERROR_DOM_QUOTA_REACHED");
      if (isQuotaExceededError) {
        dispatch(
          alertActions.ERROR("Error saving draft, local Storage is full")
        );
      } else {
        dispatch(
          alertActions.ERROR({
            message: "Error saving draft",
            data: { error },
          })
        );
      }
    }
  }

  function onDataChange(
    value: string | number | Date | Record<string, InvoiceItemFirebase> | null,
    prop: string
  ) {
    let valueToUse = value;
    if (prop === "number" && typeof value === "string") {
      const prefixes = ["br", "BR", "Br", "bR"];
      valueToUse = prefixes.some((prefix) => value.startsWith(prefix))
        ? value.replace(/^br\.?/i, "") ?? value
        : value;
    }
    setNewInvoice({
      ...newInvoice,
      [prop]: valueToUse,
    });

    if (prop === "deliveryDate") {
      const isDateInvalid = isDeliveryDateMoreThanSixtyDays(value as string);
      const err = document.querySelector(`.deliveryDate > input`);
      if (isDateInvalid) {
        err?.classList.add(Classes.INTENT_DANGER);
      } else {
        err?.classList.remove(Classes.INTENT_DANGER);
      }
    }
  }

  function onSupplierDataChange(value: Supplier) {
    setNewInvoice((invoice) => ({
      ...invoice,
      supplierId: value.id,
      supplierName: value.name,
    }));
  }

  function handleFoodTotalChange(value: string) {
    const numString = value;
    const numNumber = Number(numString);

    // get ref
    const food = foodRef.current;
    if (food == null || food.value == null) return;
    if (isNaN(numNumber)) {
      setFoodTotalError(INVOICE.INVALID_INPUT);
      setHasInputError((prevState) => ({
        ...prevState,
        food: true,
      }));
    } else {
      if (numString.charAt(0) === "0" && numNumber > 1) {
        setFoodTotalError(INVOICE.INVALID_INPUT);
        setHasInputError((prevState) => ({
          ...prevState,
          food: true,
        }));
      } else {
        setFoodTotalError("");
        setHasInputError((prevState) => ({
          ...prevState,
          food: false,
        }));
      }
    }
    food.value = numString;
    onDataChange(numString, "foodTotal");
  }

  // function to handle input from ocr image
  function handleOCRInput(
    value: string,
    type: string,
    selection: Selection,
    multiplier?: number,
    values?: string[],
    autoQty?: { value: string; selection: Selection },
    autoTotal?: { value: string; selection: Selection }
  ) {
    if (Object.keys(selection.texts).length === 0) {
      dispatch(
        alertActions.ERROR({
          message: "No text selected from OCR, please try again.",
          data: {
            ocrSelection: selection,
          },
        })
      );
      return;
    }
    value = value.trim();
    switch (type) {
      case selections.AddOpenItem:
        // add open item to invoice
        addOpenItem(selection, value, autoQty, autoTotal);
        break;
      case selections.InvoiceNumber: {
        if (
          (data.headerVoteValues && data.headerVoteValues.number !== "") ||
          !expandItems
        )
          return;
        onDataChange(value?.replace(/\s/g, ""), "number");
        validateInvoiceNumber(value);
        break;
      }
      case selections.Supplier:
        if (!expandItems) return;
        document.getElementById("supplierId")?.focus();
        setOcrSupplierQuery(value);
        break;
      case selections.DeliveryDate: {
        if (
          (data.headerVoteValues &&
            data.headerVoteValues.deliveryDateString !== "") ||
          !expandItems
        )
          return;
        const formats = ["DD.MM.YYYY", "DD/MM/YYYY", "YYYY-MM-DD", "DD/MM/YY"];
        const momentDateFormated = moment(value, formats);
        if (momentDateFormated.isValid()) {
          const date = momentDateFormated.toDate();
          document.getElementById("deliveryDate")?.focus();
          date.setHours(9, 0, 0, 0);
          onDataChange(date, "deliveryDate");
          setHasInputError((prevState) => ({
            ...prevState,
            date: isDeliveryDateMoreThanSixtyDays(value),
          }));
        } else {
          dispatch(alertActions.ERROR(INVOICE.INVALID_DATE_FORMAT));
        }

        break;
      }
      case selections.FoodTotal:
        if (
          (data.headerVoteValues && data.headerVoteValues.foodTotal !== "") ||
          !expandItems
        )
          return;
        document.getElementById("foodTotal")?.focus();
        handleFoodTotalChange(formatOcrNumber(value.toString(), 2));
        break;
      case selections.Item:
        if (activeOCRItemCard === null) {
          setOcrItemQuery({ value: value });
          if (itemSearchRef.current) itemSearchRef.current.focus();
        } else {
          if (
            !filteredNewItems[activeOCRItemCard].isOpenItem &&
            filteredNewItems[activeOCRItemCard].id !== "" &&
            popoverSearchItemSelectOpen === activeOCRItemCard
          ) {
            editSpecificItem(
              "FillSearchInsideItem",
              value,
              selection,
              activeOCRItemCard
            );
          } else if (
            filteredNewItems[activeOCRItemCard].isOpenItem ||
            filteredNewItems[activeOCRItemCard].id === ""
          ) {
            editSpecificItem(
              "emptyItemOpenLine",
              value,
              selection,
              activeOCRItemCard
            );
          } else {
            setOcrItemQuery({ value: value });
            if (itemSearchRef.current) itemSearchRef.current.focus();
          }
        }
        break;
      case selections.LastItemCost:
        if (activeOCRItemCard !== null) {
          editSpecificItem(
            "cost",
            formatOcrNumber(value, 2),
            selection,
            activeOCRItemCard,
            multiplier
          );
        }
        break;
      case selections.LastItemQuantity:
        if (activeOCRItemCard !== null) {
          editSpecificItem(
            "quantity",
            formatOcrNumber(value, 2),
            selection,
            activeOCRItemCard,
            multiplier
          );
        }
        break;
      case selections.LastItemTotal:
        if (activeOCRItemCard !== null) {
          editSpecificItem(
            "total",
            formatOcrNumber(value, 2),
            selection,
            activeOCRItemCard,
            multiplier
          );
        }
        break;
      case selections.AddDiverseItem:
        addDiverseItem(selection, Number(formatOcrNumber(value, 2)));
        break;
      case selections.AutoDetectDiverseItems:
        values?.forEach((value, index) => {
          addDiverseItem(selection, Number(value), index + newItems.length);
        });
        break;
      default:
        console.log("unknown type", type);
        break;
    }

    // add selection to ocrClicks
    ocrClicks
      ? setOcrClicks([...ocrClicks, selection])
      : setOcrClicks([selection]);
  }

  // function to edit specific Item card based on their activeOCRItemCard
  function editSpecificItem(
    prop: string,
    value: string,
    selection: Selection,
    activeOCRItemCard: number | null,
    multiplier?: number
  ) {
    if (activeOCRItemCard === null) return;

    const targetIndex = "selectedItemIndex";
    const realIndex = filteredNewItems[activeOCRItemCard].index;
    const newItemsCopy = _.cloneDeep(newItems);
    if (newItemsCopy.length < 1) return;
    selection[targetIndex] = realIndex;
    if (!newItemsCopy[realIndex]) return;

    if (prop === "emptyItemOpenLine") {
      if (!(newItems[realIndex].isOpenItem || newItems[realIndex].id === ""))
        return;
      const itemUuid = newItems[realIndex].uuid;
      if (!openItemInitialQueries[itemUuid]) {
        setOpenItemInitialQueries((prev) => ({
          ...prev,
          [itemUuid]: value,
        }));
      }
      setOpenItemQuery((prev) => ({ ...prev, [itemUuid]: value }));
      setOpenItemTrigger((prev) => ({ ...prev, [itemUuid]: uuidv4() }));
      setOpenItemSearchFocus(itemUuid);
      setNewItems((prev) =>
        prev.map((item) => {
          if (item.uuid === itemUuid) {
            return {
              ...item,
              name: value,
              ocrQuery: value,
            };
          }
          return item;
        })
      );
      if (activeOCRItemCard !== null) setScrollPosition(activeOCRItemCard);

      return;
    }
    if (prop === "FillSearchInsideItem") {
      if (newItems[realIndex].isOpenItem || newItems[realIndex].id === "")
        return;
      const itemUuid = newItems[realIndex].uuid;
      setSearchInsideItemQueries((prev) => ({
        ...prev,
        [itemUuid]: value,
      }));
      setSearchInsideItemFocus(itemUuid);
      if (activeOCRItemCard !== null) setScrollPosition(activeOCRItemCard);

      return;
    }
    newItemsCopy[realIndex].changedProperty = prop;

    switch (prop) {
      case "cost":
        newItemsCopy[realIndex].cost = Number(value) * (multiplier || 1);
        break;
      case "quantity":
        if (
          newItemsCopy[realIndex].itemVoteValue &&
          newItemsCopy[realIndex].itemVoteValue?.quantity !== 0
        )
          return;
        newItemsCopy[realIndex].quantity = Number(value) * (multiplier || 1);
        break;
      case "total":
        if (
          newItemsCopy[realIndex].itemVoteValue &&
          newItemsCopy[realIndex].itemVoteValue?.total !== 0
        )
          return;
        if (Number(value) === 0) {
          newItemsCopy[realIndex].total = 0;
          newItemsCopy[realIndex].isSponsored = true;
          break;
        }
        if (Number(value) !== 0 && newItemsCopy[realIndex].isSponsored) {
          newItemsCopy[realIndex].isSponsored = false;
        }
        newItemsCopy[realIndex].total = Number(value) * (multiplier || 1);
        break;
      default:
        console.log("unknown prop", prop);
        newItemsCopy[realIndex].changedProperty = "";
        break;
    }
    document.getElementById(`invoiceItems-${activeOCRItemCard}`)?.focus();
    setNewItems(newItemsCopy);
    return;
  }

  // function to create open item ( item without id and connection to user item list)
  function addOpenItem(
    selection?: Selection,
    value?: string,
    autoQty?: { value: string; selection: Selection },
    autoTotal?: { value: string; selection: Selection }
  ) {
    const invoiceItem: InvoiceItem = {
      index: newItems.length,
      id: "",
      name: value ? value : "",
      cost: 0,
      oldCost: 0,
      quantity: 0,
      total: 0,
      type: "",
      size: 0,
      unit: "",
      variety: "",
      errorCost: "",
      errorQuantity: "",
      errorTotal: "",
      changedProperty: "",
      isOpenItem: true,
      wasOpenItem: false,
      isNew: false,
      nameChanged: false,
      autoTotal: false,
      autoQuantity: false,
      invoicePage: invoicePageNumber + 1,
      uuid: uuidv4(),
      hasError: false,
      aliases: [],
      isFee: false,
      ocrQuery: value,
    };

    if (autoTotal) {
      invoiceItem.autoTotal = true;
      // change newItem total
      invoiceItem.total = Number(formatOcrNumber(autoTotal.value, 2));
      invoiceItem.totalAfterTax = invoiceItem.total;

      if (autoTotal.selection) {
        autoTotal.selection.selectedItemIndex = invoiceItem.index;
        autoTotal.selection.selectedItem = invoiceItem.id;
        // add selection to ocrClicks
        ocrClicks
          ? setOcrClicks([...ocrClicks, autoTotal.selection])
          : setOcrClicks([autoTotal.selection]);
      }
    }
    if (autoQty) {
      invoiceItem.autoQuantity = true;
      invoiceItem.quantity = Number(formatOcrNumber(autoQty.value, 2));
      if (autoQty.selection) {
        autoQty.selection.selectedItemIndex = invoiceItem.index;
        autoQty.selection.selectedItem = invoiceItem.id;
        // add selection to ocrClicks
        ocrClicks
          ? setOcrClicks([...ocrClicks, autoQty.selection])
          : setOcrClicks([autoQty.selection]);
      }
    }
    if (selection) {
      selection.selectedItemIndex = invoiceItem.index;
      selection.selectedUuid = invoiceItem.uuid;
    }

    if (invoiceRef.current) {
      invoiceRef.current.scrollTop = divScrollPosition;
    }
    setScrollPosition(invoiceItem.index);
    updateNewItemsAndSaveDraft([...newItems, invoiceItem]);
    setActiveOCRItemCard(invoiceItem.index);
  }

  function bulkAddOpenItems(items: BulkAddedOpenItem[]) {
    if (scrollPosition !== undefined) setScrollPosition(undefined);
    const newOpenItems = items.map((item, idx) => {
      const uuid = uuidv4();
      setOpenItemInitialQueries((prev) => ({
        ...prev,
        [uuid]: item.name,
      }));

      const itemIndex = newItems.length + idx;
      setOcrClicks((prev) => {
        const newOcrClicks = prev ? [...prev] : [];
        if (item.nameSelection)
          newOcrClicks.push({
            ...item.nameSelection,
            selectedItemIndex: itemIndex,
            selectedUuid: uuid,
          });
        if (item.qtySelection)
          newOcrClicks.push({
            ...item.qtySelection,
            selectedItemIndex: itemIndex,
            selectedUuid: uuid,
          });
        if (item.totalSelection)
          newOcrClicks.push({
            ...item.totalSelection,
            selectedItemIndex: itemIndex,
            selectedUuid: uuid,
          });
        if (item.multiplierSelection)
          newOcrClicks.push({
            ...item.multiplierSelection,
            selectedItemIndex: itemIndex,
            selectedUuid: uuid,
          });
        return newOcrClicks;
      });

      return {
        index: itemIndex,
        id: "",
        name: item.name,
        cost:
          !isNaN(item.total) && !isNaN(item.qty) ? item.total / item.qty : 0,
        oldCost: 0,
        quantity: !isNaN(item.qty) ? item.qty : 0,
        total: !isNaN(item.total) ? item.total : 0,
        totalAfterTax: !isNaN(item.total) ? item.total : 0,
        type: "",
        size: 0,
        unit: "",
        variety: "",
        errorCost: "",
        errorQuantity: "",
        errorTotal: "",
        changedProperty: "",
        isOpenItem: true,
        wasOpenItem: false,
        isNew: false,
        nameChanged: false,
        autoTotal: false,
        autoQuantity: false,
        invoicePage: invoicePageNumber + 1,
        uuid,
        hasError: false,
        aliases: [],
        isFee: false,
        isFromFloatingButtons: true,
        ocrQuery: item.name,
      };
    });

    updateNewItemsAndSaveDraft([...newItems, ...newOpenItems]);
  }

  function addDiverseItem(
    selection?: Selection,
    totalValue?: number,
    index?: number,
    qtyValue?: number
  ) {
    const invoiceItem: InvoiceItem = {
      index: index || newItems.length,
      id: fees.find((fee) => fee.name === "Diverse Item")?.id || "",
      name: "Diverse Item",
      cost: 0,
      oldCost: 0,
      quantity: 1, // set default to 1
      total: 0,
      type: "fee",
      size: 0,
      unit: "",
      variety: "",
      errorCost: "",
      errorQuantity: "",
      errorTotal: "",
      changedProperty: "",
      isOpenItem: false,
      wasOpenItem: false,
      isNew: false,
      nameChanged: false,
      autoTotal: false,
      autoQuantity: false,
      invoicePage: invoicePageNumber + 1,
      uuid: uuidv4(),
      hasError: false,
      aliases: [],
      totalAfterTax: 0,
      isFee: true,
    };
    if (selection) {
      selection.selectedUuid = invoiceItem.uuid;
      if (index) {
        selection.selectedItemIndex = index;
      } else {
        selection.selectedItemIndex = invoiceItem.index;
      }
    }
    // check if there is autodetected total
    if (totalValue) {
      invoiceItem.autoTotal = true;
      invoiceItem.total = totalValue;
      if (!qtyValue) invoiceItem.cost = totalValue;
    }
    if (qtyValue) {
      invoiceItem.autoQuantity = true;
      invoiceItem.quantity = qtyValue;
      if (totalValue) invoiceItem.cost = invoiceItem.total / qtyValue;
    }
    if (invoiceRef.current) {
      invoiceRef.current.scrollTop = divScrollPosition;
    }
    setScrollPosition(invoiceItem.index);
    updateNewItemsAndSaveDraft([...newItems, invoiceItem]);
    setActiveOCRItemCard(invoiceItem.index);
  }

  // function returns which Image component will be rendered
  function imageComponentSelect() {
    // check if data has ocrDataFiles
    if (data && data.ocrDataFiles && data.ocrDataFiles.includes(imgFile))
      return (
        <InvoiceOCR
          imageUrl={imgFile}
          onTextSelected={handleOCRInput}
          userId={data.userId}
          imageOrientation={orientation.image}
          ocrOrientation={orientation.ocr}
          saveOrientationFunction={changeImageOrientation}
          openItemCreate={openItemCreate || disableOcr}
          onAutoDetectTotal={(value, selection) =>
            setAutoDetectedItemTotal({ value, selection })
          }
          onAutoDetectQuantity={(value, selection) =>
            setAutoDetectedQty({ value, selection })
          }
          invoiceOcrInFocus={invoiceOcrInFocus}
          module="invoice"
          itemOcrSelections={itemOcrSelections}
          bulkAddOpenItems={bulkAddOpenItems}
          invoiceId={data.id}
          expandItems={expandItems}
        />
      );
    else {
      return (
        <Image
          imgFile={imgFile}
          imageOrientation={orientation.image}
          saveFunction={changeImageOrientation}
        />
      );
    }
  }

  function nextPreviousClick(
    direction: "next" | "prev",
    dataFiles: string[],
    imageUrl: string
  ) {
    const imgCurrentIndex = dataFiles.findIndex((f) => f === imageUrl);

    if (direction === "next") setSelectedImage(dataFiles[imgCurrentIndex + 1]);
    else setSelectedImage(dataFiles[imgCurrentIndex - 1]);
  }

  const getRenderer =
    (_: InvoiceItem[]) =>
    ({ index, style, key }: ListRowProps) => {
      return (
        <div
          key={key}
          style={{
            ...style,
            padding: "0 5px",
          }}
        >
          <Item
            key={`invoiceItem_${newInvoice.id}_${filteredNewItems[index].id}_${filteredNewItems[index].uuid}_${index}`}
            isTable={isTable}
            setSelectedImage={setSelectedImage}
            imgFile={imgFile}
            item={filteredNewItems[index]}
            newInvoice={newInvoice}
            newItems={newItems}
            setNewItems={setNewItems}
            handleDeleteItem={handleDeleteItem}
            onItemValueChange={onItemValueChange}
            setHasInputError={setHasInputError}
            searchInput={searchInput}
            setInvoiceOcrInFocus={setInvoiceOcrInFocus}
            stackableTags={stackableTags}
            initialTags={initialTags}
            itemLength={newItems.length}
            data={data}
            fillConvertToOpenItem={(value: string, itemUuid: string) =>
              setOpenItemQuery((prev) => ({ ...prev, [itemUuid]: value }))
            }
            setNewInvoice={setNewInvoice}
            userItemsAndFees={userItemsAndFees}
            openItemIdSelected={openItemIdSelected}
            onCreateItemButtonClick={onCreateItemButtonClick}
            initialQuery={
              openItemQueries && openItemQueries[filteredNewItems[index].uuid]
            }
            queryTrigger={openItemTrigger[filteredNewItems[index].uuid]}
            searchInsideItemQuery={
              searchInsideItemQueries &&
              searchInsideItemQueries[filteredNewItems[index].uuid]
            }
            searchInsideItemFocus={searchInsideItemFocus}
            isItemAlreadyRender={isItemAlreadyRender}
            setIsItemAlreadyRender={setIsItemAlreadyRender}
            index={index}
            quantityFocus={quantityFocus}
            onMarkAsDuplicate={onMarkAsDuplicate}
            ocrClicks={ocrClicks}
            setOcrClicks={setOcrClicks}
            openItemSearchFocus={openItemSearchFocus}
            setOpenItemSearchFocus={setOpenItemSearchFocus}
            setActiveOCRItemCard={setActiveOCRItemCard}
            activeOCRItemCard={activeOCRItemCard}
            setPopoverSearchItemSelectOpen={setPopoverSearchItemSelectOpen}
          />
        </div>
      );
    };

  function onMarkAsDuplicate(item: InvoiceItem) {
    const isDuplicate = Boolean(item.isDuplicate);

    // mark this index and below as duplicate
    const newItemsCopy = _.cloneDeep(newItems);
    const index = newItemsCopy.findIndex((x) => x.uuid === item.uuid);
    if (index === -1) return;

    for (let i = index; i < newItemsCopy.length; i++) {
      newItemsCopy[i].isDuplicate = !isDuplicate;
    }

    setNewItems(newItemsCopy);
  }

  function changeAllItemsDuplicateStatus(isDuplicate: boolean) {
    if (newItems.length === 0) return;
    const newItemsCopy = _.cloneDeep(newItems);
    // biome-ignore lint:noForEach
    newItemsCopy.forEach((item) => {
      item.isDuplicate = isDuplicate;
    });
    setNewItems(newItemsCopy);
  }

  function renderDuplicateButton() {
    if (loadingValidation)
      return <div style={{ marginTop: 5 }}>Checking for duplicates...</div>;

    return (
      <div className="invoice-duplicate-error">
        <div>
          {invoiceNumberError && existingVoteNumbers.length > 0 && (
            <div>
              <span className="text-danger">
                {invoiceNumberError} in {existingVoteNumbers.length} vote
                {existingVoteNumbers.length > 1 ? "s." : "."}
              </span>
            </div>
          )}
          {invoiceNumberError && duplicateInvoices.length > 0 && (
            <div>
              <span className="text-danger">
                {invoiceNumberError} in {duplicateInvoices.length} invoice
                {duplicateInvoices.length > 1 ? "s." : "."}
              </span>
              <Popover
                content={
                  <InvoicePopover
                    userItemProp={isTable ? userItems : undefined}
                    data={duplicateInvoices || ({} as Invoice)}
                  />
                }
                placement="right"
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              >
                <span className="text-danger">
                  View <u className="handle">here</u>.
                </span>
              </Popover>
            </div>
          )}
        </div>
        <div>
          <Checkbox
            checked={isDuplicate}
            onChange={(e) => {
              setDuplicate(e.currentTarget.checked);
              changeAllItemsDuplicateStatus(e.currentTarget.checked);
            }}
          >
            Duplicate
          </Checkbox>
        </div>
      </div>
    );
  }

  const handleDeleteAllItems = () => {
    updateNewItemsAndSaveDraft([]);
    setOpenItemInitialQueries({});
    setSearchInsideItemQueries({});
    setActiveOCRItemCard(null);
    if (ocrClicks) {
      const newOcrClicks = ocrClicks.filter((click) => !click.selectedUuid);
      setOcrClicks(newOcrClicks);
    }
    setNewInvoice((prev) => ({
      ...prev,
      newAliases: {},
    }));
  };

  const handleBulkApplyTaxDeduction = (opt: string) => {
    const taxAsNumber = Number(opt);
    if (isNaN(taxAsNumber)) return;

    const taxDeductedItems = newItems.map((item) => {
      const originalTotal = item.totalAfterTax || item.total;
      const newTotal =
        taxAsNumber > 0
          ? (originalTotal / (100 + taxAsNumber)) * 100
          : originalTotal * (1 - taxAsNumber / 100);

      const finalTotal = taxAsNumber !== 0 ? newTotal : originalTotal;

      return {
        ...item,
        taxDeduction: taxAsNumber,
        total: finalTotal,
        cost: finalTotal / item.quantity,
      };
    });

    setNewItems(taxDeductedItems);
  };

  const filterTaxDeduction: ItemPredicate<string> = (
    query,
    tax,
    _index,
    exactMatch
  ) => {
    if (tax) {
      const normalizedQuery = query.toLowerCase();

      if (exactMatch) {
        return `${tax}` === normalizedQuery;
      } else {
        return `${tax}`.indexOf(normalizedQuery) >= 0;
      }
    }
    return false;
  };

  const handleCustomTaxDeduction = (tax: string) => {
    const taxAsNumber = Number(tax);
    if (isNaN(taxAsNumber)) {
      dispatch(
        alertActions.ERROR({
          message: "Please input a valid number without %.",
          position: Position.TOP,
        })
      );
      return;
    }
    handleBulkApplyTaxDeduction(tax);
  };

  return (
    <div
      className="row"
      ref={invoiceRef}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    >
      <Divider className="mx-0 my-2" />
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue"
        canEscapeKeyCancel
        canOutsideClickCancel
        intent={Intent.DANGER}
        isOpen={openNoPriceAlert}
        onCancel={() => setNoPriceAlert(false)}
        onConfirm={() => {
          setNoPriceAlert(false);
          saveInvoice();
        }}
      >
        <p>{INVOICE.CONFIRM_SAVE_ZERO_COST}</p>
      </Alert>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue"
        canEscapeKeyCancel
        canOutsideClickCancel
        intent={Intent.DANGER}
        isOpen={valueAlert}
        onCancel={() => {
          setValueAlert(false);
        }}
        onConfirm={() => {
          setValueAlert(false);
          checkAllAlert("noPriceAlert");
        }}
      >
        <p>
          {grandTotal > 100000
            ? INVOICE.CONFIRM_SAVE_HUNDRED_K
            : `Are you sure you want to save an invoice with a total value under ${minimumValue} ${userCurrency}?`}
        </p>
      </Alert>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue"
        canEscapeKeyCancel
        canOutsideClickCancel
        intent={Intent.DANGER}
        isOpen={uploadedByUserAlert}
        onCancel={() => {
          setUploadedByUserAlert(false);
        }}
        onConfirm={() => {
          setUploadedByUserAlert(false);
          checkAllAlert("valueAlert", "noPriceAlert");
        }}
      >
        <p>{INVOICE.CONFIRM_SAVE_UPLOADED_BY_USER}</p>
      </Alert>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue"
        canEscapeKeyCancel
        canOutsideClickCancel
        intent={Intent.DANGER}
        isOpen={dateAlert}
        onCancel={() => {
          setDateAlert(false);
        }}
        onConfirm={() => {
          setDateAlert(false);
          checkAllAlert("uploadByUserAlert", "valueAlert", "noPriceAlert");
        }}
      >
        <p>{INVOICE.CONFIRM_SAVE_DATE_MORE_THAN_SIXTY_DAYS}</p>
      </Alert>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Continue"
        canEscapeKeyCancel
        canOutsideClickCancel
        intent={Intent.DANGER}
        isOpen={openNoItemsAlert}
        onCancel={() => setNoItemsAlert(false)}
        onConfirm={() => {
          setNoItemsAlert(false);
          checkAllAlert(
            "uploadByUserAlert",
            "valueAlert",
            "noPriceAlert",
            "dateAlert"
          );
        }}
      >
        <p>{INVOICE.CONFIRM_SAVE_NO_ITEMS}</p>
      </Alert>
      <div className="col-md-4 p-0" ref={col4Ref}>
        <div className="row p-2">
          <Label>
            Invoice #:
            <InputGroup
              value={newInvoice.number ?? ""}
              fill
              id="invoice-no"
              name="number"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                onDataChange(
                  e.currentTarget.value?.replace(/\s/g, ""),
                  "number"
                );
                const a = document.getElementById("invoice-no");
                a?.classList.remove(Classes.INTENT_DANGER);
              }}
              autoComplete="off"
              intent={
                invoiceNumberError === ""
                  ? Intent.NONE
                  : existingVoteNumbers.length > 0
                    ? Intent.DANGER
                    : Intent.NONE
              }
              disabled={
                data.headerVoteValues &&
                data.headerVoteValues.number !== "" &&
                !existingVoteNumbers
              }
            />
            {renderDuplicateButton()}
            <div className="pt-3">
              <Checkbox
                checked={isDiverseInvoice}
                onChange={(e) => setDiverseInvoice(e.currentTarget.checked)}
              >
                Diverse Invoice
              </Checkbox>
            </div>
          </Label>
          <Label>
            Supplier:
            <Suppliers
              ItemSelect={(supplier) => {
                onSupplierDataChange(supplier);
                // clear ocrInput
                setOcrSupplierQuery("");
              }}
              selectedSupplierId={newInvoice.supplierId}
              initialQuery={ocrSupplierQuery}
              invoiceData={newInvoice}
              disable={
                data.headerVoteValues && data.headerVoteValues.supplierId !== ""
              }
            />
          </Label>
          <Label>
            Delivery Date:
            <DateInput3
              className="deliveryDate"
              disabled={
                data.headerVoteValues &&
                data.headerVoteValues.deliveryDateString !== ""
              }
              value={
                newInvoice.deliveryDate
                  ? new Date(newInvoice.deliveryDate).toISOString()
                  : null
              }
              highlightCurrentDay
              showActionsBar
              shortcuts
              showTimezoneSelect={false}
              timePickerProps={{ className: "hidden" }}
              timePrecision="minute"
              {...getMomentFormatter("ll")}
              onChange={(selectedDate) => {
                foodRef.current?.focus();

                if (selectedDate) {
                  const date = new Date(selectedDate);
                  date.setHours(9, 0, 0, 0);
                  onDataChange(date, "deliveryDate");

                  setHasInputError((prevState) => ({
                    ...prevState,
                    date: isDeliveryDateMoreThanSixtyDays(selectedDate),
                  }));
                } else onDataChange(null, "deliveryDate");
              }}
              maxDate={moment().toDate()}
            />
            {hasInputError.date && (
              <span className="text-danger">
                Delivery date is missing or more than 60 days.
              </span>
            )}
          </Label>
          <Label>
            Food Total:
            <NumericFormat
              id="foodTotal"
              disabled={
                data.headerVoteValues && data.headerVoteValues.foodTotal !== ""
              }
              inputRef={foodRef}
              value={newInvoice.foodTotal?.toString()}
              customInput={InputGroup}
              placeholder="Food Total"
              thousandSeparator={false}
              allowNegative={true}
              decimalScale={2}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                handleFoodTotalChange(e.currentTarget.value);
                const remclass = document.getElementById("foodTotal");
                remclass?.classList.remove(Classes.INTENT_DANGER);
              }}
              onBlur={(e: React.FormEvent<HTMLInputElement>) => {
                handleFoodTotalChange(e.currentTarget.value);
              }}
              intent={foodTotalError === "" ? Intent.NONE : Intent.DANGER}
            />
            <span className="text-danger">{foodTotalError}</span>
          </Label>
        </div>
        <div className="row p-2">
          <TotalItem label="Grand Total" value={grandTotal} as="h2" />
          <TotalItem label="Food Total" value={foodTotal} />
          <TotalItem label="Drink Total" value={drinkTotal} />
          <TotalItem label="Diverse Total" value={diverseTotal} />
          <TotalItem label="Open Item Total" value={openItemTotal} />
          <TotalItem label="Fee Total" value={feeTotal.total.toFixed(2)} />
          <TotalItem label="Delivery" value={feeTotal.delivery} as="h5" />
          <TotalItem label="Caution" value={feeTotal.caution} as="h5" />
          <TotalItem label="Rent" value={feeTotal.rent} as="h5" />
          <TotalItem label="15% MVA" value={fiveteenMva} />
          <TotalItem label="25% MVA" value={twentyFiveMva} />
        </div>
        <div className="row p-2">
          <InvoiceItemTags
            initialItems={initialItems}
            data={newInvoice}
            items={newItems}
            modification={modification}
            selectedItemTags={selectedItemTags}
            setSelectedItemTags={setSelectedItemTags}
            searchInput={searchInput}
            inactive={!expandItems}
          />
        </div>
        <Divider />
        <div className="row" style={{ marginBottom: 130 }}>
          <div className="ps-4">
            <Button
              icon={expandItems ? "expand-all" : "collapse-all"}
              small
              onClick={() => setExpandItems(!expandItems)}
              className={cn(
                "icon-right",
                expandItems ? "float-right" : "pos-fixed left-35 border-style"
              )}
            />
            <InputActionConfirmationDialogue
              hasDoubleConfirmation={true}
              onConfirm={handleDeleteAllItems}
              title="Delete All Items"
              confirmationText="DELETE"
              secondConfirmationTitle="Confirm Delete All Items"
              secondConfirmationDescription="Are you sure you want to delete all items?"
            >
              <Button
                icon={"trash"}
                small
                className={cn(
                  "icon-right",
                  expandItems ? "float-right" : "pos-fixed left-35 border-style"
                )}
                style={{
                  transform: !expandItems
                    ? "translateY(100%)"
                    : "translateY(0)",
                  marginRight: expandItems ? "10px" : "0",
                }}
                text={expandItems ? "Delete All Items" : ""}
              />
            </InputActionConfirmationDialogue>
            <Popover
              placement="right"
              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              content={
                <div>
                  <Select<string>
                    items={TAX_DEDUCTIONS}
                    itemPredicate={filterTaxDeduction}
                    createNewItemFromQuery={(query: string) => {
                      return query;
                    }}
                    createNewItemRenderer={(val) => {
                      return (
                        <MenuItem
                          key={val}
                          text={`${val}%`}
                          icon="add"
                          roleStructure="listoption"
                          onClick={() => handleCustomTaxDeduction(val)}
                        />
                      );
                    }}
                    createNewItemPosition="first"
                    noResults={
                      <MenuItem
                        disabled={true}
                        text="No results."
                        roleStructure="listoption"
                      />
                    }
                    itemRenderer={(taxAsString, { handleClick, modifiers }) => {
                      return (
                        <MenuItem
                          active={modifiers.active}
                          key={taxAsString}
                          onClick={handleClick}
                          text={`${taxAsString}%`}
                        />
                      );
                    }}
                    onItemSelect={(option) => {
                      handleBulkApplyTaxDeduction(option);
                    }}
                    filterable
                    popoverProps={{ minimal: true }}
                    menuProps={{
                      style: { overflowY: "auto", maxHeight: "100px" },
                    }}
                  >
                    <Button
                      text={`Select a tax deduction`}
                      rightIcon="double-caret-vertical"
                    />
                  </Select>
                </div>
              }
            >
              <Button
                icon={"percentage"}
                small
                className={cn(
                  "icon-right",
                  expandItems ? "float-right" : "pos-fixed left-35 border-style"
                )}
                style={{
                  transform: !expandItems
                    ? "translateY(200%)"
                    : "translateY(0)",
                  marginRight: expandItems ? "10px" : "0",
                }}
                text={expandItems ? "Apply Tax Deductions" : ""}
              />
            </Popover>
          </div>
          <div
            className="ps-2 pe-0"
            style={{
              zIndex: !expandItems ? 10 : openItemCreate ? 1 : 0,
            }}
          >
            <AutoSizer disableHeight>
              {(width) => (
                <div
                  className={cn(
                    "container-padding",
                    expandItems ? "invoice-items" : "pos-fixed h-85 width-31"
                  )}
                >
                  <List
                    className={"customScrollbar"}
                    onRowsRendered={({ startIndex, stopIndex }) => {
                      if (
                        startIndex === scrollPosition ||
                        stopIndex === scrollPosition
                      ) {
                        setScrollPosition(undefined);
                      }
                    }}
                    width={width.width}
                    height={expandItems ? 620 : 0.85 * window.innerHeight}
                    rowCount={filteredNewItems.length}
                    estimatedRowSize={filteredNewItems.length}
                    overscanRowCount={4}
                    rowHeight={235}
                    rowRenderer={getRenderer(filteredNewItems)}
                    scrollToAlignment="start"
                    scrollToIndex={scrollPosition}
                  />
                </div>
              )}
            </AutoSizer>
          </div>
        </div>
        {(!data ||
          !data.ocrDataFiles ||
          !data.ocrDataFiles.includes(imgFile)) && (
          <div className="row row-button-margin">
            <div className="col-md-3">
              <Button onClick={() => addOpenItem()}>Add Open Item</Button>
            </div>
            <div className="col-md-9">&nbsp;</div>
          </div>
        )}
        <div className="row">
          <div className={expandItems ? "" : "pos-fixed-search"}>
            {openItemCreateFromItemList.open && (
              <div
                style={{
                  zIndex: 10,
                  position: "relative",
                }}
              >
                <Draggable position={{ x: x, y: y }} onStop={handleStop}>
                  <div
                    className="m-2 over-top"
                    style={{
                      width: itemCreateWidth > 0 ? itemCreateWidth : 400,
                      zIndex: 10,
                    }}
                  >
                    <Dialog
                      isOpen={openItemCreateFromItemList.open}
                      onClose={() => {
                        setOpenItemCreateFromItemList((prev) => {
                          return {
                            ...prev,
                            open: false,
                            query: "",
                          };
                        });
                        setDisableOcr(false);
                      }}
                      autoFocus
                      enforceFocus
                      usePortal={false}
                      className="pb-0"
                      title="Create Item"
                      icon="add"
                    >
                      <ItemCreate
                        name={openItemCreateFromItemList.query ?? ""}
                        initialQuery={openItemCreateFromItemList.query}
                        targetUserId={data.userId}
                        onFinish={(item, initialQuery) =>
                          onItemCreated(item, initialQuery ?? "")
                        }
                        isFromInvoice
                      />
                    </Dialog>
                  </div>
                </Draggable>
              </div>
            )}
            <ItemSearch
              userItemsAndFees={userItemsAndFees}
              fixed={!expandItems}
              onItemSelect={addInvoiceItem}
              initialQuery={ocrItemQuery}
              isOpenLine={true}
              openItemCreate={openItemCreate}
              setOpenItemCreate={setOpenItemCreate}
              setNotFoundItemQuery={setNotFoundItemQuery}
              ref={itemSearchRef}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              disabled={
                hasInputError.date || hasInputError.food || hasInputError.item
              }
              loading={savingInvoice}
              className="save-invoice"
              onClick={() => checkAllAlert()}
              text="Save"
            />
            {data.state === "unresolved" && (
              <Button
                disabled={
                  hasInputError.date || hasInputError.food || hasInputError.item
                }
                loading={loadingInvoiceVoteDraft}
                className="draft-invoice"
                onClick={() => saveDraftInvoice(newItems, undefined, true)}
                text="Save Draft"
              />
            )}
            <UploadImage data={data} setNewInvoice={setNewInvoice} />
          </div>
          {(authClaims?.admin || hasAccess(authClaims, claims.voterEngine)) && (
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div style={{ flex: "1 1 200px" }}>
                <Select
                  items={Object.keys(voterBots)}
                  itemRenderer={(
                    item,
                    { handleClick, handleFocus, modifiers }
                  ) => {
                    if (!modifiers.matchesPredicate) {
                      return null;
                    }
                    return (
                      <MenuItem
                        active={modifiers.active}
                        disabled={modifiers.disabled}
                        key={item}
                        onClick={handleClick}
                        onFocus={handleFocus}
                        roleStructure="listoption"
                        text={item}
                      />
                    );
                  }}
                  onItemSelect={setVoterEngine}
                  filterable={false}
                  activeItem={voterEngine || null}
                  popoverProps={{ matchTargetWidth: true, minimal: true }}
                  fill
                >
                  <Button
                    fill
                    rightIcon="caret-down"
                    text={voterEngine || "Select Voter"}
                  />
                </Select>
              </div>
              <Button
                text="Execute"
                style={{ flex: "1 1 40px" }}
                disabled={!voterEngine}
                intent={Intent.SUCCESS}
                loading={voterBotLoading}
                onClick={executeVoterBot}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-md-8">
        {imageComponentSelect()}

        <div className="col-md-12 pt-2">
          <div className="row">
            <div className="col-md-12">
              <div
                className="d-flex"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    onClick={() =>
                      nextPreviousClick("prev", data.files, imgFile)
                    }
                    icon="chevron-left"
                    className="m-1 col align-self-start"
                    disabled={
                      data.files?.length <= 1 ||
                      data.files?.findIndex((f) => f === imgFile) <= 0
                    }
                  >
                    Previous
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    className="m-1 col align-self-end"
                    rightIcon="chevron-right"
                    disabled={
                      data.files?.length <= 1 ||
                      data.files?.findIndex((f) => f === imgFile) >=
                        data.files?.length - 1
                    }
                    onClick={() =>
                      nextPreviousClick("next", data.files, imgFile)
                    }
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceComponent;
