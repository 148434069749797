import { DocumentData, Timestamp } from "firebase/firestore";

export type SalesState = {
  loadingSales: boolean;
  loadingSalesRefs: boolean;
  sales: MunuFile[];
  firstSaleId: string | null;
  lastSaleId: string | null;
  salesRefs: string[];
  dailySale: DailySale | null;
  loadingDailySales: boolean;
  notDeletedSales: MunuFile[];
};

export type DailySale = {
  data: any[];
  date: Date;
  updatedAt: Date | null;
  munuFileId: string;
  userId: string;
};

export class MunuFile {
  id: string;
  createdAt: Timestamp;
  data: string;
  periodEnd: Timestamp;
  periodStart: Timestamp;
  userId: string;
  deleted: boolean;
  vendor: string;
  clientName?: string;
  department?: string;

  constructor(id: string, data: DocumentData) {
    this.id = id;
    this.createdAt = data.createdAt ?? data.periodEnd;
    this.data = data.data;
    this.periodEnd = data.periodEnd;
    this.periodStart = data.periodStart;
    this.userId = data.userId;
    this.deleted = data.deleted ?? false;
    this.vendor = data.vendor ?? "";
    this.clientName = data.clientName;
    this.department = data.department;
  }
}
