import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import { setUserSalesListener } from "services/listeners/sales";
import {
  getDailySales,
  getFirstAndLastSaleId,
  getUserSales,
} from "services/sales";
import { COMMON } from "utils/constants";
import actions from "./actions";
import { MunuFile } from "./types";

export interface GET_USER_SALES_payload {
  userId: string;
  direction?: "next" | "previous";
  firstSaleId?: string;
  lastSaleId?: string;
}

export function* GET_USER_SALES(
  input: ReturnType<typeof actions.GET_USER_SALES>
) {
  const { userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSales: true,
    })
  );

  if (input.payload.direction) {
    if (input.payload.direction === "next" && input.payload.lastSaleId) {
      const result: ServiceReturn = yield call(
        getUserSales,
        userId,
        input.payload.lastSaleId,
        input.payload.direction
      );
      if (result.data) {
        yield put(
          actions.SET_STATE({
            sales: result.data,
            loadingSales: false,
          })
        );
      } else {
        yield put(actions.SET_STATE({ loadingSales: false }));
        yield put(
          alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR)
        );
      }
    } else if (
      input.payload.direction === "previous" &&
      input.payload.firstSaleId
    ) {
      const result: ServiceReturn = yield call(
        getUserSales,
        userId,
        input.payload.firstSaleId,
        input.payload.direction
      );
      if (result.data) {
        yield put(
          actions.SET_STATE({
            sales: result.data,
            loadingSales: false,
          })
        );
      } else {
        yield put(actions.SET_STATE({ loadingSales: false }));
        yield put(
          alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR)
        );
      }
    }
    return;
  }

  const firstAndLastSale: ServiceReturn = yield call(
    getFirstAndLastSaleId,
    userId
  );

  const result: ServiceReturn = yield call(getUserSales, userId);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        sales: result.data,
        loadingSales: false,
        firstSaleId: firstAndLastSale.data?.firstSaleId,
        lastSaleId: firstAndLastSale.data?.lastSaleId,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingSales: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface GET_DAILY_SALES_payload {
  userId: string;
  date: Date;
}
export function* GET_DAILY_SALES({
  payload,
}: ReturnType<typeof actions.GET_DAILY_SALES>) {
  const { userId, date } = payload;
  yield put(
    actions.SET_STATE({
      loadingDailySales: true,
    })
  );
  const result: ServiceReturn = yield call(getDailySales, userId, date);

  if (result.data) {
    yield put(
      actions.SET_STATE({
        dailySale: result.data,
        loadingDailySales: false,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingDailySales: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}
export interface SUBSCRIBE_USER_SALES_payload {
  userId: string;
  showDeletedSales: boolean;
  limit?: number;
}
export function* SUBSCRIBE_USER_SALES(
  input: ReturnType<typeof actions.SUBSCRIBE_USER_SALES>
) {
  const { userId, limit, showDeletedSales } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSales: true,
      sales: [],
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setUserSalesListener,
    userId,
    showDeletedSales,
    limit
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_USER_SALES);
    channel.close();
  });

  try {
    while (true) {
      const result: MunuFile[] = yield take(channel);
      if (result) {
        if (showDeletedSales)
          yield put(
            actions.SET_STATE({
              loadingSales: false,
              sales: result,
            })
          );
        else
          yield put(
            actions.SET_STATE({
              loadingSales: false,
              notDeletedSales: result,
            })
          );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingSales: false,
        })
      );
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USER_SALES, GET_USER_SALES),
    takeLatest(actions.SUBSCRIBE_USER_SALES, SUBSCRIBE_USER_SALES),
    takeLatest(actions.GET_DAILY_SALES, GET_DAILY_SALES),
  ]);
}
