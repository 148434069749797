import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import module from "./index.module.css";
import { cn } from "../../styles";
export const Loader = ({ loading, children, className, loaderHeight, loaderWidth, }) => {
    const isLoading = useMemo(() => !!loading, [loading]);
    return (_jsxs("div", Object.assign({ className: cn(module.wrapper, className) }, { children: [_jsxs("div", Object.assign({ className: cn(module.load_indicator, !isLoading && module.avail), style: {
                    height: loaderHeight,
                    minHeight: loaderHeight,
                    width: loaderWidth,
                    minWidth: loaderWidth,
                }, id: "comp_load_indicator" }, { children: [_jsxs("div", Object.assign({ className: module.head }, { children: [_jsx("div", { className: module.first }), _jsx("div", { className: module.second })] })), _jsx("div", { className: module.frame })] })), _jsx("div", Object.assign({ className: cn(module.child, !isLoading && module.show) }, { children: children }))] })));
};
