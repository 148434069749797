import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, HTMLSelect, Intent, Tooltip, } from "@blueprintjs/core";
import { getPaginationState } from "./helpers";
import { memo } from "react";
export default function Pagination({ table }) {
    const state = getPaginationState(table);
    return (_jsxs("div", Object.assign({ className: "d-flex flex-row" }, { children: [_jsx("div", Object.assign({ className: "me-2" }, { children: _jsxs(ButtonGroup, { children: [_jsx(TooltipBtn, { tooltip: "First Page", disabled: !table.getCanPreviousPage(), icon: "double-chevron-left", action: () => table.setPageIndex(0) }), _jsx(TooltipBtn, { tooltip: "Previous Page", disabled: !table.getCanPreviousPage(), icon: "chevron-left", action: table.previousPage }), state.showStartEllipsis && _jsx(Button, { disabled: true, icon: "more" }), state.pages.map((page) => (_jsx(Button, { intent: state.currentPage === page ? Intent.PRIMARY : Intent.NONE, onClick: () => table.setPageIndex(page - 1), text: page }, page))), state.showEndEllipsis && _jsx(Button, { disabled: true, icon: "more" }), _jsx(TooltipBtn, { tooltip: "Next Page", disabled: !table.getCanNextPage(), icon: "chevron-right", action: table.nextPage }), _jsx(TooltipBtn, { tooltip: "Last Page", disabled: !table.getCanNextPage(), icon: "double-chevron-right", action: () => table.setPageIndex(table.getPageCount() - 1) })] }) })), _jsx(PageSizeBtn, { pageSize: table.getState().pagination.pageSize, setPageSize: table.setPageSize })] })));
}
const TooltipBtn = memo(({ disabled, icon, action, tooltip }) => (_jsx(Tooltip, Object.assign({ compact: true, content: tooltip, disabled: disabled, position: "top" }, { children: _jsx(Button, { disabled: disabled, icon: icon, onClick: () => action() }) }))));
const PageSizeBtn = memo(({ pageSize, setPageSize }) => (_jsx(Tooltip, Object.assign({ compact: true, content: "Page size" }, { children: _jsx(HTMLSelect, { className: "ms-2", options: [10, 20, 50, 100], value: pageSize, onChange: (e) => setPageSize(parseInt(e.target.value)) }) }))));
