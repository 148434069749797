import {
  DocumentData,
  DocumentReference,
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getBlob, getDownloadURL, ref } from "firebase/storage";
import { getAuthUserID } from "redux/auth/helpers";
import { ServiceReturn } from "redux/types";
import { ConfirmedStatus, StockReportData, User } from "redux/users/types";
import { checkCounter } from "services/counter";
import { db, functions, storage } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { SERVER_COUNTS } from "utils/constants";

export async function getUser(uid: string): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const userRef = doc(db, "users", uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    return { data: { ...userDoc.data(), id: userDoc.id }, error: null };
  } else {
    return { data: null, error: "No such document!" };
  }
}

export async function getUsers() {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: DocumentData[] = [];

  const querySnapshot = await getDocs(
    query(collection(db, "users"), orderBy("name"))
  );
  querySnapshot.forEach((doc) => {
    data.push(new User(doc.id, doc.ref.path, doc.data()));
  });

  return data;
}

export async function updateUser(
  data: DocumentData,
  userId: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    await updateDoc(doc(db, "users", userId), data);
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function batchUpdateUsers(
  data: DocumentData,
  usersArray: string[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const batch = writeBatch(db);
  usersArray.forEach(async (user) => {
    if (!db) return;
    const userRef = doc(db, "users", user);
    batch.update(userRef, {
      [data.attr]: data.value,
    });
  });

  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function dismissUserCount(
  userId: string,
  countId: string,
  userStatusNote: { data: string; archived: boolean }[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const batch = writeBatch(db);
  const countRef = doc(db, "users/" + userId + "/counts/" + countId);
  const userRef = doc(db, "users/" + userId);
  batch.update(countRef, {
    dismissed: true,
  });
  batch.update(userRef, {
    userStatusNote:
      userStatusNote.length > 0
        ? userStatusNote.map((note) => ({
            ...note,
            archived: true,
          }))
        : [],
    userStatusNoteTags: [],
  });
  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function batchDismissUserCount(
  usersArray: User[],
  state: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const batch = writeBatch(db);

  for (const user of usersArray) {
    if (!user.activeCounts || user.activeCounts.length === 0) continue;

    for (const activeCount of user.activeCounts) {
      const countId = activeCount.countId;
      const countState = activeCount.state;
      if (!countId || countState !== state) continue;

      const countRef = doc(db, "users/" + user.id + "/counts/" + countId);
      batch.update(countRef, {
        dismissed: true,
      });
    }

    const userRef = doc(db, "users/" + user.id);
    batch.update(userRef, {
      activeCounts: user.activeCounts
        .filter((x) => x.state === state)
        .map((activeCount) => ({
          ...activeCount,
          dismissed: true,
        })),
      userStatusNote:
        user.userStatusNote && user.userStatusNote.length > 0
          ? user.userStatusNote.map((note) => ({ ...note, archived: true }))
          : [],
    });
  }

  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateCountConfirmedStatus(
  userId: string,
  countId: string,
  data: ConfirmedStatus
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(doc(db, "users/" + userId + "/counts/" + countId), {
    confirmedStatus: data,
  })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateUserConfirmedStatus(
  userId: string,
  data: ConfirmedStatus
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(doc(db, "users/" + userId), {
    confirmedStatus: data,
  })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateTransactionsResolved(
  transactionRefs: { ref: DocumentReference }[],
  resolved: boolean,
  resolvedBy: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  // Get a new write batch
  const batch = writeBatch(db);
  transactionRefs.forEach((transactionRef) => {
    batch.update(transactionRef.ref, {
      resolved: resolved,
      resolvedBy: resolvedBy,
    });
  });

  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateNoteSuggestions(
  newNoteSuggestion: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  // Get a new write batch
  const ref = doc(db, "adminPanelSettings", "userStatusNoteSuggestions");
  const document = await getDoc(ref);
  const isExists = document.exists();
  if (!isExists)
    return await setDoc(ref, { data: [newNoteSuggestion] })
      .then(() => {
        return { data: true, error: null };
      })
      .catch((err) => {
        return { data: null, error: err };
      });
  else
    return await updateDoc(ref, {
      data: arrayUnion(newNoteSuggestion),
    })
      .then(() => {
        return { data: true, error: null };
      })
      .catch((err) => {
        return { data: null, error: err };
      });
}

export async function getUserTransactionFile(
  path: string
): Promise<ServiceReturn> {
  if (!storage) return { data: null, error: "No storage connection" };

  try {
    const fileRef = ref(storage, path);
    const file = await getBlob(fileRef);

    // convert as text
    const text = await file.text();

    const json = JSON.parse(text);
    if (json) {
      return { data: null, error: json.message || "No data" };
    }

    throw new Error("continue");
  } catch (err) {
    const downloadURL = await getDownloadURL(ref(storage, path));

    return {
      data: downloadURL,
      error: null,
    };
  }
}

export async function getUserHistory(userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: DocumentData[] = [];

  const querySnapshot = await getDocs(
    collection(db, "users", userId, "userHistory")
  );

  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });

  return data;
}

export async function addUserHistory(
  data: DocumentData,
  userId: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const historyRef = collection(db, "users", userId, "userHistory");
  return await addDoc(historyRef, data)
    .then((docRef) => {
      return { data: docRef.id, error: null };
    })
    .catch((error) => {
      return { data: null, error: error };
    });
}

export async function getTableColumns(
  module: string
): Promise<ServiceReturn<Record<string, number>>> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const userId = getAuthUserID();
  if (userId) {
    const docRef = doc(db, "users", userId, "tableColumns", module);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { data: docSnap.data(), error: null };
    } else {
      setDoc(doc(db, "users", userId, "tableColumns", module), {});
      return { data: null, error: null };
    }
  } else {
    return { data: null, error: null };
  }
}

export async function editTableColumns(
  data: Record<string, number>,
  module: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const userId = getAuthUserID();
    if (!userId) return { data: null, error: "No user ID" };
    await setDoc(doc(db, "users", userId, "tableColumns", module), data);
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getChecklistItems(userId: string, countId: string) {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const docRef = doc(
      db,
      "users",
      userId,
      "counts",
      countId,
      "checklist",
      "--checklist"
    );

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) return { data: docSnapshot.data(), error: null };
    else return { data: null, error: "Document does not exist" };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getStockCountData(userId: string, countId: string) {
  if (!functions) return { data: null, error: "No functions connection" };
  try {
    const getStockCountData = httpsCallable<
      { userId: string; countId: string },
      StockReportData
    >(functions, CALLABLE_FUNCTIONS.getStockCountData, { timeout: 540000 });
    const resp = await getStockCountData({ userId, countId });

    if (resp.data) {
      return { data: resp.data, error: null };
    } else {
      return { data: null, error: "No data" };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getUserDocumentHistoryLogs(
  userId: string,
  lastDocId?: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: DocumentData[] = [];

  const constraints = [];

  if (lastDocId) {
    const docRef = doc(db, "users", userId, "documentHistoryLogs", lastDocId);
    const lastDoc = await getDoc(docRef);
    constraints.push(startAfter(lastDoc));
  }

  const querySnapshot = await getDocs(
    query(
      collection(db, "users", userId, "documentHistoryLogs"),
      orderBy("createdAt", "desc"),
      ...constraints,
      limit(50)
    )
  );

  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });

  return { data, error: null };
}
