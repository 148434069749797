import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, Callout, Classes, InputGroup, Intent, } from "@blueprintjs/core";
import { CodeCell } from "../edit-attribute-dialog";
export default function ConfirmationPopover({ fieldName, value, onConfirm, }) {
    const [confirmationText, setConfirmationText] = useState("");
    return (_jsxs("div", Object.assign({ className: "w-100" }, { children: [_jsxs(Callout, Object.assign({ intent: "warning" }, { children: ["Are you sure you want to set ", _jsx(CodeCell, { children: fieldName }), " to", " ", _jsx(CodeCell, { children: value.toString() || "" }), "? If yes, please type \"CONFIRM\" in the box below."] })), _jsx(InputGroup, { style: { margin: "10px 0 10px" }, onChange: (e) => setConfirmationText(e.currentTarget.value) }), _jsxs("div", Object.assign({ style: {
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 15,
                } }, { children: [_jsx(Button, Object.assign({ className: Classes.POPOVER_DISMISS, style: { marginRight: 10 }, onClick: () => setConfirmationText("") }, { children: "Cancel" })), _jsx(Button, Object.assign({ intent: Intent.DANGER, className: Classes.POPOVER_DISMISS, onClick: () => onConfirm(), disabled: confirmationText !== "CONFIRM" }, { children: "Confirm" }))] }))] })));
}
