import { createActions } from "redux/create-actions";
import {
  UPDATE_LOOKER_CONFIG_Payload,
  UPDATE_LOOKER_EMBEDS_Payload,
  UPDATE_SCRAPERS_CONFIG_Payload,
  UPDATE_SETTINGS_Payload,
} from "./sagas";
import { SettingsState } from "./types";

const actions = createActions("settings", {
  SET_STATE: {} as Partial<SettingsState>,
  GET_SETTINGS: undefined,
  UPDATE_SETTINGS: {} as UPDATE_SETTINGS_Payload,
  GET_SCRAPERS_CONFIG: undefined,
  UPDATE_SCRAPERS_CONFIG: {} as UPDATE_SCRAPERS_CONFIG_Payload,
  GET_ALL_FIELDS: undefined,
  GET_LOOKER_CONFIG: undefined,
  UPDATE_LOOKER_CONFIG: {} as UPDATE_LOOKER_CONFIG_Payload,
  GET_LOOKER_EMBEDS: undefined,
  UPDATE_LOOKER_EMBEDS: {} as UPDATE_LOOKER_EMBEDS_Payload,
});

export default actions;
