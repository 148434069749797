import {
  ImMatricData,
  LogsCount,
  ScrapersLog,
} from "redux/integration-monitor/types";
import store from "redux/store";

/**
 * Function to get vendor type based on vendor name
 * @category Integration Monitor
 * @function
 * @param {string} vendorName Vendor name
 * @returns {"POS" | "AP" | "SUPPLIER"} Vendor type
 * @example
 * import { getVendorType } from "./helpers";
 * const vendorType = getVendorType("Munu");
 */
export function getVendorType(vendorName: string) {
  const vendorTypes = store.getState().integrationMonitor.integrationsFields;

  return vendorTypes[vendorName]?.type ?? "UNKNOWN";
}

/**
 * Checks if a file path has the ".xlsx" extension.
 *
 * @param {string} filePath - The file path to check.
 * @returns {boolean} Returns true if the file has the ".xlsx" extension, false otherwise.
 * @example
 * import { getFileExtension } from "./helpers";
 * const filePath = '"https://firebasestorage.googleapis.com/v0/b/stocklio-playground.appspot.com/o/scrapersLogs%2Fu0jhhDY7KS3RdABdrbvP%2Fscreenshots%2F1703778964814.xlsx?alt=media&token=....."';
 * const fileType = getFileExtension(filePath);
 * console.log(fileType); // "xlsx"
 */
export function getFileExtension(filePath: string) {
  if (typeof filePath !== "string") return;
  const path = new URL(filePath).pathname;

  const fileExtension = path.split(".").pop()?.toLowerCase();

  return fileExtension;
}

export function sumCounts(imMatricsData: ImMatricData[]) {
  const initialCounts: LogsCount = {
    all: 0,
    allScheduledIntegrationMustRun: 0,
    run: 0,
    notRun: 0,
    success: 0,
    fail: 0,
    posNotRun: 0,
    apNotRun: 0,
    supplierNotRun: 0,
    undefinedTypeNotRun: 0,
    loginError: 0,
    credentialsError: 0,
    loginSuccess: 0,
    scrapeFail: 0,
    scrapeSuccessNoData: 0,
    scrapeSuccessWithData: 0,
    allScheduledIntegrationMustRun: 0,

    apiAll: 0,
    allAPIScheduledIntegrationMustRun: 0,
    apiRun: 0,
    apiNotRun: 0,
    apiSuccess: 0,
    apiFail: 0,
    apiPosNotRun: 0,
    apiApNotRun: 0,
    apiSupplierNotRun: 0,
    apiUndefinedTypeNotRun: 0,
    apiScrapeSuccessNoData: 0,
    apiScrapeSuccessWithData: 0,
    allAPIScheduledIntegrationMustRun: 0,
  };

  const manualCounts = imMatricsData.reduce(
    (acc, curr) => {
      for (const key of Object.keys(curr.manualCounts)) {
        acc[key as keyof LogsCount] +=
          curr.manualCounts[key as keyof LogsCount] ?? 0;
      }
      return acc;
    },
    { ...initialCounts }
  );

  const scheduledCounts = imMatricsData.reduce(
    (acc, curr) => {
      for (const key of Object.keys(curr.scheduledCounts)) {
        acc[key as keyof LogsCount] +=
          curr.scheduledCounts[key as keyof LogsCount] ?? 0;
      }
      return acc;
    },
    { ...initialCounts }
  );

  const allCounts = imMatricsData.reduce(
    (acc, curr) => {
      for (const key of Object.keys(curr.allCounts)) {
        acc[key as keyof LogsCount] += curr.allCounts[key as keyof LogsCount];
      }
      return acc;
    },
    { ...initialCounts }
  );

  return { manualCounts, scheduledCounts, allCounts };
}

export function getLogState(log: ScrapersLog) {
  let state = "";
  if (log.success && !log.isLoginProcess) {
    if (log.dataId) state = "Success Scraping New Data";
    else if (!log.dataId) state = "Success Scraping No New Data";
  } else if (log.success && log.isLoginProcess) {
    state = "Login Process Success";
  } else if (!log.success && log.isLoginProcess) {
    if (log.steps.length && log.steps[0].name === "invalid login credential")
      state = "Error Login Credentials";
    else if (log.steps.length && log.steps[0].name === "error in login process")
      state = "Error Login Process";
    else state = "Login Process Failed";
  } else state = "Error Scraping Process";
  return state;
}
