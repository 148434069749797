import _ from "lodash";
import { InvoiceItem } from "redux/invoice/types";
import { INVOICE } from "utils/constants";

export const updateValues = (
  numString: string,
  numNumber: number,
  name: string,
  item: InvoiceItem,
  isTotalFirst: boolean,
  isSponsored: boolean,
  setIsTotalFirst: (val: boolean) => void,
  isCalculateCost: boolean
) => {
  const { hasError, copyItem } = checkValue(
    item,
    name,
    numString,
    numNumber,
    isSponsored
  );

  if (isSponsored) {
    copyItem.total = 0;
  }

  if (name === "cost") {
    if (
      !!copyItem.quantity &&
      !isNaN(parseInt(copyItem.quantity.toString())) &&
      copyItem.quantity !== 0 &&
      numNumber !== 0
    ) {
      copyItem.total = numNumber * copyItem.quantity;
      copyItem.errorTotal = "";
      copyItem.cost = numNumber;
      copyItem.totalComputed = true;
    }
  } else if (name === "quantity") {
    if (numNumber !== 0 && !isSponsored) {
      if ((isTotalFirst || isCalculateCost) && !copyItem.totalComputed) {
        if (isTotalFirst) setIsTotalFirst(false);
        copyItem.cost = copyItem.total / numNumber;
        copyItem.totalComputed = false;
        copyItem.errorTotal = "";
      } else if (copyItem.cost !== 0) {
        copyItem.total = copyItem.cost * numNumber;
        copyItem.totalAfterTax = copyItem.cost * numNumber;
        copyItem.totalComputed = true;
        copyItem.errorTotal = "";
      } else if (item.type?.toLowerCase() === "fee" && copyItem.total !== 0) {
        copyItem.cost = copyItem.total / numNumber;
      }
    }

    copyItem.quantity = numNumber === 0 ? (numString as any) : numNumber;
  } else {
    // check if copyItem.quantity has value and it is not 0
    if (
      copyItem.quantity &&
      !isNaN(copyItem.quantity) &&
      copyItem.quantity !== 0 &&
      numNumber !== 0
    ) {
      setIsTotalFirst(true);
      copyItem.totalComputed = false;
      copyItem.cost = numNumber / copyItem.quantity;
    }

    copyItem.total =
      item.type?.toLowerCase() === "fee"
        ? numNumber === 0
          ? (numString as any)
          : numNumber
        : numNumber;

    copyItem.totalAfterTax = copyItem.total;
    copyItem.errorCost = "";
  }
  return { copyItem, hasError };
};

function checkValue(
  item: InvoiceItem,
  name: string,
  numString: string,
  numNumber: number,
  isSponsored: boolean
) {
  let hasError = false;
  const copyItem = _.clone(item);

  function assignError(name: string, reset?: boolean) {
    if (name === "cost") {
      if (reset) copyItem.errorCost = "";
      else copyItem.errorCost = INVOICE.INVALID_INPUT;
    } else if (name === "quantity") {
      if (reset) copyItem.errorQuantity = "";
      else copyItem.errorQuantity = INVOICE.INVALID_INPUT;
    } else {
      if (reset) copyItem.errorTotal = "";
      else copyItem.errorTotal = INVOICE.INVALID_INPUT;
    }
  }

  if (isNaN(numString as any)) {
    hasError = true;
    assignError(name);
  } else {
    if (!isSponsored && numString.charAt(0) === "0" && numNumber >= 1) {
      hasError = true;
      assignError(name);
    } else {
      assignError(name, true);
      hasError = false;
      if (name === "total") copyItem.autoTotal = false;
      if (name === "quantity") copyItem.autoQuantity = false;
    }
  }

  return { hasError, copyItem };
}
