import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, InputGroup, Menu, MenuItem, Popover } from "@blueprintjs/core";
import Pagination from "../pagination";
import ToggleColumnsBtn from "../toggle-columns";
export default function TableToolbar({ table, searchQuery, setSearchQuery, }) {
    var _a;
    const bulkActions = (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.bulkActions;
    const selectedRows = table
        .getSelectedRowModel()
        .rows.map((row) => row.original);
    return (_jsxs("div", Object.assign({ className: "mb-3 d-flex flex-row justify-content-between align-items-center" }, { children: [_jsxs("div", Object.assign({ className: "d-flex", style: { gap: 8 } }, { children: [_jsx(ToggleColumnsBtn, { table: table }), bulkActions && (_jsx(Popover, Object.assign({ content: _jsx(Menu, { children: bulkActions === null || bulkActions === void 0 ? void 0 : bulkActions.map((action) => (_jsx(MenuItem, { icon: action.icon, text: action.name, onClick: () => action.action(selectedRows) }, action.name))) }), fill: true, placement: "bottom-start", minimal: true }, { children: _jsx(Button, { text: "Bulk Actions", icon: "tick", rightIcon: "caret-down", disabled: !selectedRows.length }) }))), setSearchQuery && (_jsx(InputGroup, { placeholder: "Search by Username or Vendor...", value: searchQuery, onChange: (e) => setSearchQuery(e.currentTarget.value), style: { minWidth: 300 } }))] })), _jsx(Pagination, { table: table })] })));
}
