import { createWorkerFactory } from "@shopify/web-worker";
import { Timestamp, doc } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Filter } from "redux/config/types";
import { db } from "services/firebase";
import {
  WorkerStatus,
  WorkerStatusItem,
  getStatusColor,
} from "../helpers/worker-status";

const createRefilter = createWorkerFactory(
  () => import("../helpers/filter-worker")
);

/**
 * React hook for filtering items on AdvancedFilterPanel component.
 * @param {Object[]} initialItems - Array of objects to be filtered.
 * @param {React.Dispatch<React.SetStateAction<any[]>>} setFilteredUsers - A function to set the state of filtered objects.
 * @category Advanced Filter Panel
 */
const useFilteredItems = (
  initialItems: any[],

  setFilteredUsers: React.Dispatch<React.SetStateAction<any[]>>,
  module: string
) => {
  const [filteredItems, setFilteredItems] = useState<any[]>(initialItems);
  const [activeFilters, setActiveFilters] = useState<Filter[]>([]);
  // For web worker
  const hasPendingWork = useRef(false);
  const statusRef = useRef<WorkerStatusItem>(WorkerStatus.IDLE);
  const latestFilters = useRef<Filter[]>([]);

  // Return filtered results to module every time filteredItems changes
  useEffect(
    () => setFilteredUsers(filteredItems),
    [filteredItems, setFilteredUsers]
  );

  const checkPendingWork = async () => {
    if (hasPendingWork.current) {
      hasPendingWork.current = false;
      await handleRefilter(latestFilters.current);
    }
  };

  /**
   * A function to refilter the initial items.
   * @method handleRefilter
   * @param {Filter[]} filters - An array of filters.
   * @category Advanced Filter Panel
   */
  const handleRefilter = React.useCallback(
    async (filters: Filter[]) => {
      if (filters.length === 0) {
        setFilteredItems(initialItems);
        return;
      }
      // Update latestFilters with latest filters data
      latestFilters.current = filters;
      if (statusRef.current !== WorkerStatus.RUNNING) {
        try {
          statusRef.current = WorkerStatus.RUNNING;
          const refilterAgent = createRefilter();
          let refiltered = await refilterAgent.refilter(
            JSON.parse(JSON.stringify(initialItems)),
            filters
          );
          if (module === "invoices-data" || module === "invoices-table-view") {
            refiltered = refiltered.map((item) => ({
              ...item,
              ref: db
                ? doc(db, "users", item.userId, "invoices", item.id)
                : item.ref,
              createdAt: item.createdAt && moment(item.createdAt).toDate(),
              deliveryDate:
                item.deliveryDate && moment(item.deliveryDate).toDate(),
              updatedAt: item.updatedAt && moment(item.updatedAt).toDate(),
              startedAt: item.startedAt && moment(item.startedAt).toDate(),
              resolvedAt: item.resolvedAt && moment(item.resolvedAt).toDate(),
              oldestDate: item.oldestDate && moment(item.oldestDate).toDate(),
            }));
          } else if (module === "posItemTasks-data") {
            refiltered = refiltered.map((item) => ({
              ...item,
              createdAt: moment(item.createdAt).toDate(),
            }));
          } else if (module === "userStatus") {
            refiltered = refiltered.map((item) => ({
              ...item,
              createdAt:
                item.createdAt &&
                new Timestamp(
                  item.createdAt.seconds,
                  item.createdAt.nanoseconds
                ),
              updatedAt:
                item.updatedAt &&
                new Timestamp(
                  item.updatedAt.seconds,
                  item.updatedAt.nanoseconds
                ),
              oldestInvoiceDate:
                item.oldestInvoiceDate &&
                new Timestamp(
                  item.oldestInvoiceDate.seconds,
                  item.oldestInvoiceDate.nanoseconds
                ),
              checkingOldestInvoiceDate:
                item.checkingOldestInvoiceDate &&
                new Timestamp(
                  item.checkingOldestInvoiceDate.seconds,
                  item.checkingOldestInvoiceDate.nanoseconds
                ),
              oldestUnresolvedAssumptionDate:
                item.oldestUnresolvedAssumptionDate &&
                new Timestamp(
                  item.oldestUnresolvedAssumptionDate.seconds,
                  item.oldestUnresolvedAssumptionDate.nanoseconds
                ),
              invoiceOldestDeliveryDate:
                item.invoiceOldestDeliveryDate &&
                new Timestamp(
                  item.invoiceOldestDeliveryDate.seconds,
                  item.invoiceOldestDeliveryDate.nanoseconds
                ),
              invoiceLatestDeliveryDate:
                item.invoiceLatestDeliveryDate &&
                new Timestamp(
                  item.invoiceLatestDeliveryDate.seconds,
                  item.invoiceLatestDeliveryDate.nanoseconds
                ),
            }));
          }
          statusRef.current = WorkerStatus.SUCCESS;
          setFilteredItems(refiltered);
        } catch (err) {
          console.log(err);
          statusRef.current = WorkerStatus.ERROR;
        } finally {
          // Check if handleRefilter needs to be run again
          checkPendingWork();
        }
      } else {
        // Make it pending so that handleRefilter can run again
        // after the current run has finished
        hasPendingWork.current = true;
      }
    },

    [initialItems, statusRef.current]
  );

  /**
   * A function to refilter the initial items.
   * @method handleResetFilter
   * @category Advanced Filter Panel
   */
  const handleResetFilter = React.useCallback(() => {
    setActiveFilters([]);
    setFilteredItems(initialItems);
  }, [initialItems]);

  const numberOfFilteredItems = React.useMemo(
    () => filteredItems.length,
    [filteredItems]
  );

  const statusColor = getStatusColor(statusRef.current);

  return {
    activeFilters,
    setActiveFilters,
    numberOfFilteredItems,
    statusColor,
    setFilteredItems,
    handleRefilter,
    handleResetFilter,
  };
};

export default useFilteredItems;
