/**
 * Group of Integration Monitor Services. These function will connect this app to the database (firestore)
 * @module IntegrationMonitorServices
 * @category Integration Monitor
 */

import {
  DocumentData,
  DocumentReference,
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import moment from "moment";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db, storage } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

/**
 * Function to get scrapers data by its ID
 * @async
 * @param {string} dataId Scrapers data ID
 * @returns {Promise<DocumentData>} Return promise, with type `DocumentData`
 * @example
 * // In redux saga
 * const data = yield call(getScrapersDataByDataId, dataId);
 */
export async function getScrapersDataByDataId(
  dataId: string
): Promise<DocumentData> {
  if (!db) return {};
  if (!checkCounter()) return {};

  return getDoc(doc(db, "scrapersData", dataId)).then((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getScrapersLogByLogId(logId: string) {
  if (!db) return {};
  if (!checkCounter()) return {};

  const querySnapshot = await getDoc(doc(db, "scrapersLogs", logId));
  return { ...querySnapshot.data(), id: querySnapshot.id };
}

/**
 * Function to get scrapers data by User ID.
 * @async
 * @param {string} userId Integration Monitor User ID
 * @returns {Promise<DocumentData[]>} Return promise of `Array<DocumentData>`
 * @example
 * // In redux saga
 * const data: DocumentData[] = yield call(getScrapersDataByUserId, userId);
 */
export async function getScrapersDataByUserId(userId: string) {
  if (!db) return [];
  if (!checkCounter()) return [];

  const querySnapshot = await getDocs(
    query(collection(db, "scrapersData"), where("userId", "==", userId))
  );
  const testData: DocumentData[] = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  return testData;
}

export async function getScrapersDataByLogId(logId: string) {
  if (!db) return 0;
  if (!checkCounter()) return;

  try {
    const q = query(
      collection(db, "scrapersData"),
      where("logId", "==", logId),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length) return querySnapshot.docs[0].data();
  } catch (error) {
    console.log(error);
  }
}

/**
 * Function to get image download URL. This function accept multiple image url object
 * @async
 * @param {Array<object>} imageUrl Array of image url object
 * @param {string} imageUrl.url URL/path of the image
 * @param {string} imageUrl.name Name of the image
 * @returns {Promise<any[] | {url:string, name:string}>} Returns download URL and error flag (if any)
 * @example
 * // In redux saga
 * const images: DocumentData[] = yield call(getImageDownloadUrl, imageUrls);
 */
export async function getImageDownloadUrl(
  imageUrl: { url: string; name: string }[]
) {
  const downloadUrl: { url: string; name: string }[] = [];

  if (!storage) return { downloadUrl, error: "No storage connection" };
  if (!checkCounter())
    return { downloadUrl, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  // Create a reference with an initial file path and name

  for (const image of imageUrl) {
    try {
      const downloadURL = await getDownloadURL(ref(storage, image.url));
      downloadUrl.push({ url: downloadURL, name: image.name });
    } catch (error) {
      console.error(error);
    }
  }
  return downloadUrl;
}

/**
 * Function to update notification of scrapers log.
 * @async
 * @param {any} logRef Scrapers log firestore docs ref
 * @param {boolean} notificationShow Indicates whether system should show notification or not
 * @returns {Promise<ServiceReturn>} Return an object, with property `data` and `error` to indicates whether the operation was successful or not
 * @example
 * // In redux saga
 * yield call(updateLogNotification, logRef, notificationShow);
 */
export async function updateLogNotification(
  logRef: DocumentReference,
  notificationShow: boolean
): Promise<ServiceReturn> {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(logRef, { notificationShow })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

/**
 * Function to get POS files for User who has connected to POS vendor
 * @async
 * @param {string} userId User ID
 * @param {Date} startTime Desired start time of POS files created
 * @param {Date} endTime Desired end time of POS files created
 * @returns {Promise<ServiceReturn>} Return an object, with property `data` and `error` to indicates whether the operation was successful or not
 * @example
 * // In redux saga
 * const result: ServiceReturn = yield call(
 *   getPosFiles,
 *   userId,
 *   startTime,
 *   endTime
 * );
 */
export async function getPosFiles(
  userId: string,
  startTime: Date,
  endTime: Date,
  onlyNotDeleted = true
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const constraints = [
      where("userId", "==", userId),
      where("periodEnd", ">=", startTime),
      where("periodEnd", "<=", endTime),
    ];
    if (onlyNotDeleted) {
      constraints.push(where("deleted", "==", false));
    }
    const querySnapshot = await getDocs(
      query(collection(db, "munuFiles"), ...constraints)
    );

    const posFiles: DocumentData[] = querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
        data: JSON.parse(doc.data().data).slice(1),
        periodEnd: doc.data().periodEnd.toDate(),
        periodStart: doc.data().periodStart.toDate(),
      };
    });

    let data = posFiles.filter(
      (file) =>
        moment(file.periodStart).isSameOrAfter(moment(startTime), "day") &&
        moment(file.periodEnd).isSame(moment(endTime), "day")
    );

    if (data.length > 1 && !onlyNotDeleted) {
      data = data.filter((posFile) => !posFile.deleted);
    }

    return { data: data, error: null };
  } catch (err) {
    console.log(err);
    return { data: null, error: err };
  }
}

/**
 * Function to get all field from each vendor from adminPanelSettings collection
 * @async
 * @returns {Promise<ServiceReturn>} Return an object, with property `data` and `error` to indicates whether the operation was successful or not
 * @example
 * // In redux saga
 *   const result: ServiceReturn = yield call(getIntegrationsFlag);
 */
export async function getIntegrationsFlag(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const adminPanelRef = doc(db, "adminPanelSettings", "integrations");
    const querySnapshot = await getDoc(adminPanelRef);
    if (querySnapshot.exists()) {
      const data = querySnapshot.data();
      return { data: data, error: null };
    } else {
      return { data: null, error: "Error when fetching admin panel setting." };
    }
  } catch (err) {
    console.log(err);
    return { data: null, error: err };
  }
}

export async function getScraperLogs(userId: string, type: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const logRef = query(
      collection(db, "scrapersLogs"),
      where("userId", "==", userId),
      where("type", "==", type),
      where("success", "==", true)
    );

    const querySnapshot = await getDocs(logRef);

    const logs = querySnapshot.docs
      .map((doc) => {
        const lastStep = doc.data().steps[doc.data().steps.length - 2];
        return {
          id: doc.id,
          createdAt: doc.data().createdAt?.toDate(),
          lastStep: {
            name: lastStep.name,
            createdAt: lastStep.createdAt.toDate(),
          },
          vendor: doc.data().name,
        };
      })
      .sort(
        (a, b) =>
          b.lastStep.createdAt.getTime() - a.lastStep.createdAt.getTime()
      );

    return { data: logs, error: null };
  } catch (err) {
    console.log(err);
    return { data: null, error: err };
  }
}

export async function getLogIdBasedOnSales(
  userId: string,
  vendor: string,
  saleCreatedAt: Timestamp
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const logRef = query(
      collection(db, "scrapersLogs"),
      where("userId", "==", userId),
      where("name", "==", vendor),
      where("success", "==", true),
      where("createdAt", "<=", saleCreatedAt),
      where(
        "createdAt",
        ">=",
        moment(saleCreatedAt.toDate()).subtract(1, "days").toDate()
      ),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(logRef);
    const logs = querySnapshot.docs.map((doc) => {
      const lastStep = doc.data().steps[doc.data().steps.length - 2];
      return {
        id: doc.id,
        createdAt: doc.data().createdAt?.toDate(),
        lastStep: {
          name: lastStep.name,
          createdAt: lastStep.createdAt.toDate(),
        },
      };
    });

    const targetLogId = logs.find(
      (x) =>
        moment(saleCreatedAt.toDate().getTime()).format("YYYY-MM-DD") ===
          moment(x.createdAt.getTime()).format("YYYY-MM-DD") &&
        saleCreatedAt.toDate() > x.lastStep.createdAt
    );
    return { data: String(targetLogId?.id) ?? null, error: null };
  } catch (err) {
    console.log(err);
    return { data: null, error: err };
  }
}
