import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { UsersState } from "./types";

export const usersInitialState: UsersState = {
  users: [],
  usersWithCounts: [],
  loading: false,
  counts: [],
  transactions: [],
  userTransactions: [],
  loadingCategories: false,
  loadingUpdate: false,
  loadingBulkEdit: false,
  tableColumns: {},
  loadingTableColumns: false,
  loadingSaveColumnOrder: false,
  loadingNoteSuggestions: false,
  noteSuggestions: [],
  loadingUpdateNoteSuggestions: false,
};

export default createReducer(usersInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addCase(actions.UPDATE_USER, (state, action) => {
    const user = [...state.users];
    return {
      ...state,
      users: user.map((obj) =>
        action.payload.userId === obj.id
          ? { ...obj, ...action.payload.user }
          : obj
      ),
    };
  });
  builder.addDefaultCase((state) => state);
});
