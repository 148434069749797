import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { useMemo, useState } from "react";
import VisibleColsPanel from "../visible-cols-panel";
import InvisibleColsPanel from "../invisible-cols-panel";
function ToggleColumnDialog({ isOpen, setOpen, table, }) {
    var _a, _b;
    const handleSave = (_b = (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.columnProps) === null || _b === void 0 ? void 0 : _b.onColumnOrderSave;
    const nonVisibleColumns = table
        .getAllLeafColumns()
        .filter((col) => !col.getIsVisible() && !col.getIsPinned());
    return (_jsxs(Dialog, Object.assign({ isOpen: isOpen, onClose: () => setOpen(false), autoFocus: true, enforceFocus: true, usePortal: true, className: "pb-0", icon: "settings", title: "Toggle Column Visibility" }, { children: [_jsxs(DialogBody, { children: [_jsx(VisibleColsPanel, { table: table }), nonVisibleColumns.length > 0 && (_jsx(InvisibleColsPanel, { columns: nonVisibleColumns }))] }), _jsx(DialogFooter, { actions: _jsx(Button, Object.assign({ disabled: !handleSave, text: "Save Column Order" }, (handleSave && {
                    onClick: () => handleSave(table.getVisibleLeafColumns()),
                }))) })] })));
}
export default function useToggleColumnDialog(table) {
    const [isOpen, setOpen] = useState(false);
    const DialogComponent = (_jsx(ToggleColumnDialog, { isOpen: isOpen, setOpen: setOpen, table: table }));
    return useMemo(() => ({ setOpen, DialogComponent }), [setOpen, DialogComponent]);
}
