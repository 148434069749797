import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { Supplier } from "redux/suppliers/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function getSuppliers() {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: Supplier[] = [];
  const querySnapshot = await getDocs(collection(db, "suppliers"));
  querySnapshot.forEach((doc) => {
    const sup: Supplier = doc.data() as Supplier;
    sup.id = doc.id;
    data.push(sup);
  });
  return data;
}

export async function updateSuppliers(
  suppliers: Supplier[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    for (const supplier of suppliers) {
      await setDoc(doc(db, "suppliers/" + supplier.id), supplier);
    }
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
