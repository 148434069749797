import { DocumentData, Timestamp } from "firebase/firestore";
import { APTransaction } from "redux/ap-transactions/types";
import { Count } from "redux/counts/types";

/**
 * User count object of a {@link User}
 * @category Users
 */
export interface UserCount {
  /** Count data */
  count: Count;
  /** Count ID */
  id: string;
  /** User ID */
  userId: string;
}

/**
 * State change with changedAt property as Firestore Timestamp
 * @category Users
 */
export interface RawStateChange {
  /** State name */
  state: string;
  /** State change date, in Firestore Timestamp */
  changedAt: Timestamp;
  /** Who moved the state */
  movedBy?: "admin" | "user";
}

/**
 * State change with changedAt property as Date
 * @category Users
 */
export interface StateChange {
  /** State name */
  state: string;
  /** State change date, in Date */
  changedAt: Date;
  /** Who moved the state */
  movedBy?: "admin" | "user";
}

export interface ConfirmedStatus {
  cost: boolean;
  ioh: boolean;
  priceChanges: boolean;
  salesMatch: boolean;
}

export interface ActiveCount {
  id: string;
  countId: string;
  startTime: number;
  endTime: number;
  isDone: boolean;
  dismissed: boolean;
  confirmedStatus: ConfirmedStatus;
  state: string;
  sortKey: number;
}

export interface UserTutorial {
  order: number;
  id: string;
  checked: boolean;
}

/** A User object
 * @category Users
 * @param {string} id - The user ID.
 * @param {string} refPath - The Firestore reference path.
 * @param {DocumentData} data - The user data.
 */
export class User {
  id: string;
  refPath: string;
  name: string;
  email: string;
  accessLevel: number;
  accessLevelName: string;
  demo: boolean;
  premium: boolean;
  employee: boolean;
  organization: boolean;
  organizationId: string;
  organizationName?: string;
  priority: number;
  isPerKiloEnabled: boolean;
  isWastageEnabled: boolean;
  hasApTransactions: boolean;
  apUploadRecipients: string[];
  transactionCount: number;
  unresolvedCount?: number;
  apTransactionsVendor: string;
  apTransactionsCount: number;
  integrations: Integration[];
  sortReport: string;
  numberFormat: string;

  customTypes: Record<string, any>;
  customPosItemsTypes: Record<string, string>;
  customReport: boolean;

  customReportAreas: Record<string, any>;
  differenceReport: boolean;
  differenceValueReport: boolean;
  alcoholGroupReport: boolean;
  summaryDifferenceReport: boolean;

  downloadedReports: Map<string, any>[];
  inventoryScreen: boolean;
  isOnline?: boolean;
  lastSeenOnline?: { seconds: number; nanoseconds: number };
  tasksCount: Record<string, number>;
  invoicesCount: Record<string, number>;
  extraColumnEnabled: boolean;
  isCameraEnabled: boolean;
  isCountReportPreviewEnabled: boolean;
  isTutorialsEnabled: boolean;
  hasAnActiveCount: boolean;
  active: boolean;
  language: string;
  isSearchHighlightEnabled: boolean;
  isItemTagsEnabled: boolean;
  isLocalizationEnabled: boolean;
  hiddenUserIds: string[];
  confirmedStatus: ConfirmedStatus;
  updatedAt?: Timestamp;
  createdAt?: Timestamp;
  isRecipeNoteEnabled: boolean;
  isPosItemsAsMenuItemsEnabled: boolean;
  isIFrameDashboardEnabled: boolean;
  isTransferItemsEnabled: boolean;
  isCountSupervisorFeatureOn: boolean;
  isCountSupervisorFeatureAvailable: boolean;
  isItemCutawayEnabled: boolean;
  isDepartmentsEnabled: boolean;
  isStrictSearchSettingsAvailable: boolean;
  isFlaggingEnabled: boolean;
  isEditingDepartmentCodeEnabled: boolean;
  isCustomChecklistLengthEnabled: boolean;
  customChecklistLength: {
    Food: number;
    Wine: number;
    Other: number;
    Drink: number;
  };
  hasPosItems: boolean;
  invoiceNotes: string;
  resolvingUserId: string | null;
  checkingUserId: string | null;
  resolvingUserName?: string;
  checkingUserName?: string;
  scanRate: number;
  hasBlurredInvoices: boolean;
  isFastScrollingEnabled: boolean;
  oldestInvoiceDate?: Timestamp;
  checkingOldestInvoiceDate?: Timestamp;
  resolvingInvoiceId: string | null;
  checkingInvoiceId: string | null;
  isInvoiceVotingEnabled: boolean;
  currencyLong?: string;
  currencyShort?: string;
  isApTransactionsAutoResolveEnabled?: boolean;
  strictSearchSetting: StrictSearchSetting;
  invoiceVotingWeight: number;
  isExcludedFromPriorityListAtInvoices: boolean;
  isExcludedFromPriorityListAtPOSItemTasks: boolean;
  isNewAtInvoices: boolean;
  isNewAtPOSItemTasks: boolean;
  hasInvoiceCreateByBot?: boolean;
  userStatusNote?: { data: string; archived: boolean }[];
  openPeriods: {
    from: string;
    to: string;
  }[];
  activeCounts?: ActiveCount[] | null;
  oldestUnresolvedAssumptionDate?: Timestamp | null;
  invoiceOldestDeliveryDate?: Timestamp;
  invoiceLatestDeliveryDate?: Timestamp;
  nameForCloseAPI: string;
  customEmailOrderForCloseAPI?: CustomEmailOrderForCloseAPI;
  isCVReportIncludesGraph: boolean;
  userStatusNoteTags?: string[];
  isAutoCreateInvoiceFromGoogleDriveEnabled: boolean;
  isFoodVarianceExcluded: boolean;
  tutorials: UserTutorial[];

  // allow other properties

  [index: string]: any;

  constructor(id: string, refPath: string, data: DocumentData) {
    /** Unique ID
     * @type {string}
     */
    this.id = id;
    /** Firestore reference
     * @type {DocumentReference<DocumentData>}
     */
    this.refPath = refPath;
    /** User access level
     * @type {number}
     * @default 0
     */
    this.accessLevel = data.accessLevel ?? 0;
    /** User-defined parameter for sorting report
     * @type {string}
     * @default type
     */
    this.sortReport = data.sortReport ?? "type";
    /** Amount number format, defaults to EU
     * @type {string}
     * @default eu
     */
    this.numberFormat = data.numberFormat ?? "eu";
    /** User language
     * @type {string}
     * @default en
     */
    this.language = data.language ?? "en";
    /** Custom types of items
     * @type {Object}
     */
    this.customTypes = data.customTypes ?? {};
    /** Custom types of POS Items
     * @type {Object}
     */
    this.customPosItemsTypes = data.customPosItemsTypes ?? {};
    /** Is custom report enabled by user
     * @type {boolean}
     * @default false
     */
    this.customReport = data.customReport ?? false;
    /** Custom report areas
     * @type {Object}
     */
    this.customReportAreas = data.customReportAreas ?? {};
    /** Is difference report enabled by user
     * @type {boolean}
     * @default false
     */
    this.differenceReport = data.differenceReport ?? false;
    /** Is difference value report enabled by user
     * @type {boolean}
     * @default false
     */
    this.differenceValueReport = data.differenceValueReport ?? false;
    /** Is alcohol group report enabled by user
     * @type {boolean}
     * @default false
     */
    this.alcoholGroupReport = data.alcoholGroupReport ?? false;
    /** Is summary difference report enabled by user
     * @type {boolean}
     * @default true
     */
    this.summaryDifferenceReport = data.summaryDifferenceReport ?? true;
    /** Downloaded reports
     * @type {Object[]}
     */
    this.downloadedReports = data.downloadedReports ?? [];
    /** Is inventory screen enabled by user
     * @type {boolean}
     * @default false
     */
    this.inventoryScreen = data.inventoryScreen ?? false;
    /** Organization ID of user
     * @type {string}
     */
    this.organizationId = data.organizationId ?? null;
    /** Is user online
     * @type {boolean}
     * @default false
     */
    this.isOnline = data.isOnline ?? false;
    /** Is the user an organization
     * @type {boolean}
     * @default false
     */
    this.organization = data.organization ?? false;
    /** Is extra column enabled by user
     * @type {boolean}
     * @default false
     */
    this.extraColumnEnabled = data.extraColumnEnabled ?? false;
    /** Is camera enabled by user
     * @type {boolean}
     * @default false
     */
    this.isCameraEnabled = data.isCameraEnabled ?? false;
    /** Is wastage enabled by user
     * @type {boolean}
     * @default false
     */
    this.isWastageEnabled = data.isWastageEnabled ?? false;
    /** Is per kilo enabled by user
     * @type {boolean}
     * @default false
     */
    this.isPerKiloEnabled = data.isPerKiloEnabled ?? false;
    /** Is count report preview enabled by user
     * @type {boolean}
     * @default false
     */
    this.isCountReportPreviewEnabled =
      data.isCountReportPreviewEnabled ?? false;
    /** Is tutorials enabled by user
     * @type {boolean}
     * @default false
     */
    this.isTutorialsEnabled = data.isTutorialsEnabled ?? false;
    /** Is search highlight enabled by user
     * @type {boolean}
     * @default true
     */
    this.isSearchHighlightEnabled = data.isSearchHighlightEnabled ?? true;
    /** Is item tags enabled by user
     * @type {boolean}
     * @default false
     */
    this.isItemTagsEnabled = data.isItemTagsEnabled ?? false;
    /** Is localization enabled by user
     * @type {boolean}
     * @default false
     */
    this.isLocalizationEnabled = data.isLocalizationEnabled ?? false;
    /** Does user have an active count
     * @type {boolean}
     * @default false
     */
    this.hasAnActiveCount = data.hasAnActiveCount ?? false;
    /** User IDs that are hidden from this user
     * @type {string[]}
     */
    this.hiddenUserIds = data.hiddenUserIds ?? [];
    /** Last online timestamp
     * @type {Timestamp}
     */
    this.lastSeenOnline = data.lastSeenOnline ?? null;
    /** Records of number of tasks
     * @type {Object}
     */
    this.tasksCount = data.tasksCount ?? {};
    /** Records of number of invoices
     * @type {Object}
     */
    this.invoicesCount = data.invoicesCount ?? {};
    /** Invoice notes
     * @type {string}
     */
    this.invoiceNotes = data.invoiceNotes ?? "";
    /** User ID that is being resolved by this user
     * @type {string}
     */
    this.resolvingUserId = data.resolvingUserId ?? null;
    /** User ID that is being checked by this user
     * @type {string}
     */
    this.checkingUserId = data.checkingUserId ?? null;
    /** User name
     * @type {string}
     */
    this.name = data.name ?? "";
    /** User email
     * @type {string}
     */
    this.email = data.email ?? "";
    /** Organization name
     * @type {string}
     */
    this.organizationName = data.organizationName ?? "";
    /** AP transactions vendor name
     * @type {string}
     */
    this.apTransactionsVendor = data.apTransactionsVendor ?? "";
    /** Is the user get premium features
     * @type {boolean}
     * @default false
     */
    this.premium = data.premium ?? false;
    /** Is the user a demo account
     * @type {boolean}
     * @default false
     */
    this.demo = data.demo ?? false;
    /** Is the user an employee
     * @type {boolean}
     * @default false
     */
    this.employee = data.employee ?? false;
    /** Does the user have AP transactions
     * @type {boolean}
     * @default false
     */
    this.hasApTransactions = data.hasApTransactions ?? false;
    /** Is the user active
     * @type {boolean}
     * @default false
     */
    this.active = data.active ?? false;
    /** Priority level
     * @type {number}
     * @default 0
     */
    this.priority = data.priority ?? 0;
    /** The number of user transactions
     * @type {number}
     * @default 0
     */
    this.transactionCount = data.transactionCount ?? 0;
    /** The number of AP transactions
     * @type {number}
     * @default 0
     */
    this.apTransactionsCount = data.apTransactionsCount ?? 0;
    /** A list of AP transactions upload recipients
     * @type {string[]}
     */
    this.apUploadRecipients = data.apUploadRecipients ?? [];
    /** A list of external POS or AP integrations
     * @type {Integration[]}
     */
    this.integrations = data.integrations ?? [];
    /** User access level name
     * @type {string}
     */
    this.accessLevelName = data.accessLevelName ?? "";
    /** Is recipe note enabled by user
     * @type {boolean}
     * @default false
     */
    this.isRecipeNoteEnabled = data.isRecipeNoteEnabled ?? false;
    /** Is POS items as menu items enabled by user
     * @type {boolean}
     * @default false
     */
    this.isPosItemsAsMenuItemsEnabled =
      data.isPosItemsAsMenuItemsEnabled ?? false;
    /** Is IFrame dashboard enabled by user
     * @type {boolean}
     * @default false
     */
    this.isIFrameDashboardEnabled = data.isIFrameDashboardEnabled ?? false;
    /** Is transfer items enabled by user
     * @type {boolean}
     * @default false
     */
    this.isTransferItemsEnabled = data.isTransferItemsEnabled ?? false;
    /** Is count supervisor feature activated by user
     * @type {boolean}
     * @default false
     */
    this.isCountSupervisorFeatureOn = data.isCountSupervisorFeatureOn ?? false;
    /** Is count supervisor feature available for user
     * @type {boolean}
     * @default false
     */
    this.isCountSupervisorFeatureAvailable =
      data.isCountSupervisorFeatureAvailable ?? false;
    /** Is item cutaway enabled by user
     * @type {boolean}
     * @default false
     */
    this.isItemCutawayEnabled = data.isItemCutawayEnabled ?? false;
    /** Is departments enabled by user
     * @type {boolean}
     * @default false
     */
    this.isDepartmentsEnabled = data.isDepartmentsEnabled ?? false;
    /** Is strict search settings available for user
     * @type {boolean}
     * @default false
     */
    this.isStrictSearchSettingsAvailable =
      data.isStrictSearchSettingsAvailable ?? false;
    /** Does the user has custom checklist length enabled
     * @type {boolean}
     * @default false
     */
    this.isCustomChecklistLengthEnabled =
      data.isCustomChecklistLengthEnabled ?? false;
    /** An object filled with the custom length for each checklist type - Defaults to 10 each
     * @type {{ Food: number, Wine: number, Other: number, Drink: number }}
     * @default { Food: 10, Wine: 10, Other: 10, Drink: 10 }
     */
    this.customChecklistLength =
      data.customChecklistLength &&
      Object.keys(data.customChecklistLength).length
        ? data.customChecklistLength
        : {
            Food: 10,
            Wine: 10,
            Other: 10,
            Drink: 10,
          };
    /** Does the user have POS Items
     * @type {boolean}
     * @default false
     */
    this.hasPosItems = data.hasPosItems ?? false;
    /** Is flagging enabled by user
     * @type {boolean}
     * @default false
     */
    this.isFlaggingEnabled = data.isFlaggingEnabled ?? false;
    /** Is editing department code enabled by user
     * @type {boolean}
     * @default false
     */
    this.isEditingDepartmentCodeEnabled =
      data.isEditingDepartmentCodeEnabled ?? false;
    /** The camera scan rate
     * @type {number}
     * @default 0
     */
    this.scanRate = data.scanRate ?? 0;
    /** Does the user have blurred invoices
     * @type {boolean}
     * @default false
     */
    this.hasBlurredInvoices = data.hasBlurredInvoices ?? false;
    /** Is fast scrolling enabled by user
     * @type {boolean}
     * @default false
     */
    this.isFastScrollingEnabled = data.isFastScrollingEnabled ?? false;
    /** The date of the oldest unresolved invoice
     * @type {Timestamp}
     * @default null
     */
    this.oldestInvoiceDate = data.oldestInvoiceDate ?? null;
    /** The date of the oldest unchecked resolved invoice
     * @type {Timestamp}
     * @default null
     */
    this.checkingOldestInvoiceDate = data.checkingOldestInvoiceDate ?? null;
    /** Invoice Id that is beeing resolved by the user
     * @type {string}
     * @default null
     */
    this.resolvingInvoiceId = data.resolvingInvoiceId ?? null;
    /** Note for each user on user status
     * @type {string}
     * @default null
     */
    this.userStatusNote = data.userStatusNote ?? null;
    /** Invoice Id that is being checked by the user
     * @type {string}
     * @default null
     */
    this.checkingInvoiceId = data.checkingInvoiceId ?? null;
    /** Is Invoice Voting enabled for the user
     * @type {boolean}
     * @default false
     */
    this.isInvoiceVotingEnabled = data.isInvoiceVotingEnabled ?? false;
    /** Short currency name
     * @type {string}
     * @default "kr"
     */
    this.currencyShort = data.currencyShort ?? "kr";
    /** Long currency name
     * @type {string}
     * @default "NOK"
     */
    this.currencyLong = data.currencyLong ?? "NOK";
    /** Can the user's AP Transactions be resolved automatically
     * @type {boolean}
     * @default false
     */
    this.isApTransactionsAutoResolveEnabled =
      data.isApTransactionsAutoResolveEnabled ?? false;
    /** Strict search settings for the Flutter app
     * @type {StrictSearchSetting}
     * @default {}
     */
    this.strictSearchSetting = Object.assign(
      {},
      new StrictSearchSetting(data.strictSearchSetting ?? {})
    );
    /** Indicates how much we can trust a user's invoice votes.
     * @type {number}
     * @default 1
     */
    this.invoiceVotingWeight = data.invoiceVotingWeight ?? 1;
    /**
     * Used to determine the initial count status for each user
     * @type {Object}
     * @default { cost: false, ioh: false, priceChanges: false }
     */
    this.confirmedStatus = data.confirmedStatus ?? {
      cost: false,
      ioh: false,
      priceChanges: false,
      salesMatch: false,
    };
    /**
     * Used to determine if the user should be excluded from the priority list in invoices
     * This user is flagged when they have "DO NOT DO THIS ACCOUNT" in the invoice notes/guidelines
     * @type {Boolean}
     * @default false
     */
    this.isExcludedFromPriorityListAtInvoices =
      data.isExcludedFromPriorityListAtInvoices ?? false;
    /**
     * Used to determine if the user should be excluded from the priority list in POS Item Tasks
     * @type {Boolean}
     * @default false
     */
    this.isExcludedFromPriorityListAtPOSItemTasks =
      data.isExcludedFromPriorityListAtPOSItemTasks ?? false;
    /**
     * Indicates if the user is a new user at Invoices
     * @type {Boolean}
     * @default false
     */
    this.isNewAtInvoices = data.isNewAtInvoices ?? false;
    /**
     * Indicates if the user is a new user at POS Item Tasks
     * @type {Boolean}
     * @default false
     */
    this.isNewAtPOSItemTasks = data.isNewAtPOSItemTasks ?? false;

    /**
     * Indicates if the user has invoices created by bot
     * @type {Boolean}
     * @default false
     */
    this.hasInvoiceCreateByBot = data.hasInvoiceCreateByBot ?? false;
    /**
     * Indicates the periods in which the user is open
     * - If it's empty, the user is open all year round
     *   @type {Object[]}
     *   @default []
     */
    this.openPeriods = data.openPeriods ?? [];

    /**
     * Indicates oldest assmuption date from unresolved invoices
     */
    this.oldestUnresolvedAssumptionDate =
      data.oldestUnresolvedAssumptionDate ?? null;

    /**
     * Indicates oldest delivery date from resolved invoices
     */
    this.invoiceOldestDeliveryDate = data.invoiceOldestDeliveryDate ?? null;

    /**
     * Indicates latest invoice delivery date
     * @type {Timestamp}
     * @default null
     * */
    this.invoiceLatestDeliveryDate = data.invoiceLatestDeliveryDate ?? null;

    /**
     * Summary data for latest or active count on user for user-status
     * @type {ActiveCount[] | null}
     * @default null
     */
    this.activeCounts = data.activeCounts || [];

    if (data.activeCount) {
      const isIdIncluded = data.activeCounts?.some(
        (count: ActiveCount) =>
          count.id === data.activeCount.countId ||
          count.countId === data.activeCount.countId
      );

      if (!isIdIncluded) {
        this.activeCounts = [data.activeCount, ...(this.activeCounts ?? [])];
      }
    }

    /**
     * Alternative name used for the Close API
     * @type {string}
     * @default ""
     */
    this.nameForCloseAPI = data.nameForCloseAPI ?? "";

    /**
     * Alternative name used for the Close API
     * @type {CustomEmailOrderForCloseAPI | null}
     * @default null
     */
    this.customEmailOrderForCloseAPI = data.customEmailOrderForCloseAPI;

    /**
     * Indicates if the user's CV report includes the CV graph or not
     * @type {boolean}
     * @default false
     */
    this.isCVReportIncludesGraph = data.isCVReportIncludesGraph ?? false;

    /**
     * User status note tag
     * @type {string}
     * @default ""
     */
    this.userStatusNoteTags = data.userStatusNoteTags ?? [];

    /**
     * Auto creates invoice from AP transactions if vendor is Google Drive
     * @type {boolean}
     * @default false
     */
    this.isAutoCreateInvoiceFromGoogleDriveEnabled =
      data.isAutoCreateInvoiceFromGoogleDriveEnabled ?? false;

    /**
     * If set to true, user's food variance will be excluded from
     * the C&V report, but food items won't be ignored @ invoices.
     * @type {boolean}
     * @default false
     */
    this.isFoodVarianceExcluded = data.isFoodVarianceExcluded ?? false;

    /**
     * Tutorials that are assigned to each specific users
     * @type {Object[]}
     * @default []
     */
    this.tutorials =
      data.tutorials?.sort(
        (a: UserTutorial, b: UserTutorial) => a.order - b.order
      ) ?? [];

    // add any other boolean property
    for (const key in data) {
      const type = typeof data[key];
      const targetTypes = ["boolean", "number", "string"];
      if (
        !Object.prototype.hasOwnProperty.call(this, key) &&
        targetTypes.includes(type)
      ) {
        this[key] = data[key];
      }
    }
  }
}

export type CustomEmailOrderForCloseAPI = {
  to?: string[];
  cc?: string[];
  bcc?: string[];
};

export type ProfitDataSummary = {
  definedSalesProfitValue: number;
  definedSalesProfitPercentage: number;
  undefinedSalesProfitValue: number;
  undefinedSalesProfitPercentage: number;
  zeroPriceSalesProfitValue: number;
  zeroPriceSalesProfitPercentage: number;
  totalSalesProfitValue: number;
  totalSalesProfitPercentage: number;
};

class StrictSearchSetting {
  isStrictSearchSettingEnabled: boolean;
  enabledOnTagsSearch: boolean;
  enabledOnListItemsSearch: boolean;
  enabledOnRecipesSearch: boolean;
  enabledOnSuggestionsList: boolean;
  enabledOnWastageList: boolean;
  enabledOnRecipeIngredientsSearch: boolean;
  enabledOnCountItemFiters: boolean;
  enabledOnSearchAl1CountAreas: boolean;
  enabledOnPOSButtonIngredientsSearch: boolean;
  enabledOnInvoiceFiltersSearch: boolean;
  enabledOnInventorySearch: boolean;
  enabledOnItemsListFiltersSearch: boolean;
  createdAt?: Date | null;
  updatedAt?: Date | null;

  constructor(data: Record<string, boolean | Timestamp>) {
    this.isStrictSearchSettingEnabled =
      (data.isStrictSearchSettingEnabled as boolean) ?? false;
    this.enabledOnTagsSearch = (data.enabledOnTagsSearch as boolean) ?? false;
    this.enabledOnListItemsSearch =
      (data.enabledOnListItemsSearch as boolean) ?? false;
    this.enabledOnRecipesSearch =
      (data.enabledOnRecipesSearch as boolean) ?? false;
    this.enabledOnSuggestionsList =
      (data.enabledOnSuggestionsList as boolean) ?? false;
    this.enabledOnWastageList = (data.enabledOnWastageList as boolean) ?? false;
    this.enabledOnRecipeIngredientsSearch =
      (data.enabledOnRecipeIngredientsSearch as boolean) ?? false;
    this.enabledOnCountItemFiters =
      (data.enabledOnCountItemFiters as boolean) ?? false;
    this.enabledOnSearchAl1CountAreas =
      (data.enabledOnSearchAl1CountAreas as boolean) ?? false;
    this.enabledOnPOSButtonIngredientsSearch =
      (data.enabledOnPOSButtonIngredientsSearch as boolean) ?? false;
    this.enabledOnInvoiceFiltersSearch =
      (data.enabledOnInvoiceFiltersSearch as boolean) ?? false;
    this.enabledOnInventorySearch =
      (data.enabledOnInventorySearch as boolean) ?? false;
    this.enabledOnItemsListFiltersSearch =
      (data.enabledOnItemsListFiltersSearch as boolean) ?? false;
    this.createdAt = data.createdAt
      ? typeof data.createdAt === "string"
        ? new Date(data.createdAt)
        : (data.createdAt as Timestamp).toDate()
      : null;
    this.updatedAt = data.updatedAt
      ? typeof data.updatedAt === "string"
        ? new Date(data.updatedAt)
        : (data.updatedAt as Timestamp).toDate()
      : null;
  }
}

/**
 * Users Redux state
 * @memberof User
 */
export type UsersState = {
  /** List of users with {@link User} type */
  users: User[];
  /** List of users with counts data */
  usersWithCounts: User[];
  /** Is the data loading */
  loading: boolean;
  /** User counts data from counts collection*/

  counts: any[];
  /** Single user transactions data from invoiceTransactions collection */

  transactions: any[];
  /** User transactions data from invoiceTransactions collection */
  userTransactions: APTransaction[];
  /** Is the categories data loading */
  loadingCategories: boolean;
  /** Is the update loading */
  loadingUpdate: boolean;
  /** Is the bulk edit process loading */
  loadingBulkEdit: boolean;
  /** Users table columns */
  tableColumns: Record<string, number>;
  /** Has the table columns finished loading */
  loadingTableColumns: boolean;
  /** Is the save column order process loading */
  loadingSaveColumnOrder: boolean;
  loadingNoteSuggestions: boolean;
  noteSuggestions: string[];
  loadingUpdateNoteSuggestions: boolean;
};

/**
 * Represents the data structure for a stock report.
 *
 * @remarks
 * This type is used to store information about a stock report, including email, grand total, groups, and lock date.
 */
export type StockReportData = {
  /**
   * The email associated with the stock report.
   */
  email?: string;
  /**
   * The grand total of the stock report.
   */
  grandTotal: number;
  /**
   * An array of groups in the stock report.
   */
  groups: {
    /**
     * The type of the group.
     */
    type: string;
    /**
     * The total value of the group.
     */
    total: number;
    /**
     * An array of items in the group.
     */
    items: {
      /**
       * The name of the item.
       */
      name: string;
      /**
       * The size of the item.
       */
      size: number;
      /**
       * The unit of measurement for the item.
       */
      unit: string;
      /**
       * The quantity of the item.
       */
      quantity: number;
      /**
       * The price of the item.
       */
      price: number;
      /**
       * The total value of the item.
       */
      total: number;
    }[];
  }[];
  /**
   * The lock date of the stock report in the format "YYYY-MM-DD HH:mm:ss.SSS".
   */
  lockDate: string;
};

/**
 * User with report data for use in user status module
 * @category Users
 * @memberof User
 */
export type UserWithReport = UserWithActiveCount & {
  /** User report data */
  reportData: UserReport;
};

export type UserWithActiveCount = User & {
  activeCount?: ActiveCount | null;
};

/**
 * User report data for use in user status module
 * @category Users
 * @memberof User
 */
export type UserReport = {
  /** User report status: started, pending, complete, done */
  status: string;
  /** Inventory on Hand (IoH) report data with {@link ReportData} type */
  ioh: ReportData;
  /** Cost report data with {@link ReportData} type */
  cost: ReportData;
  /** Variance report data with {@link ReportData} type */
  variance: ReportData;
  /** Top items report data with {@link ReportData} type */
  topItems: ReportData;
};

/**
 * Single processed report datum for use in user status module
 * @category Users
 * @memberof User
 */
type ReportDatum = {
  /** Id Identifier for count collection. */
  countId: string;
  /** Month of report */
  month: string;
  /** Date period of report */
  period: string;
  /** The total number of items counted. Just used on IOH report */
  totalItemCounted?: number;
  /** Main values records, with values of {@link ReportDataMain} type */
  data: Record<string, ReportDataMain>;
  /** Extra values, with values of {@link ReportDataExtra} type */
  extras?: Record<string, ReportDataExtra[]>;
};
export type ReportData = Array<ReportDatum>;

/**
 * Main value of each report data.
 * @category Users
 * @param {number} data - Number value
 * @param {boolean} isPercentage - Is the number a percentage
 * @param {string} description - Description for tooltip
 *
 * @example
 * import { ReportDataMain } from 'redux/users/types';
 * const data = new ReportDataMain(100, true, 'Main Value');
 */
export class ReportDataMain {
  data: number;
  description: string;
  isPercentage: boolean;
  color: string;

  constructor(data: number, isPercentage: boolean, description = "Main Value") {
    /** Value of the report data
     * @type {number}
     */
    this.data = data;
    /** Description of the report data
     * @type {string}
     */
    this.description = description;
    /** Is the value a percentage
     * @type {boolean}
     */
    this.isPercentage = isPercentage;
    /** Text color of the value
     * @type {string}
     * @default #FFFFFF
     */
    this.color = "#FFFFFF";
  }
  /**
   * Set the text color of the value.
   * @param {string} color - Text color
   */
  setColor(color: string) {
    this.color = color;
  }
  /**
   * Sum up the value with a new value.
   * @param {number} data - Number value
   */
  addData(data: number) {
    this.data += data;
  }
}

/**
 * Extra value of each report data.
 * @category Users
 * @param {number} data - Number value
 * @param {string} description - Description for tooltip
 * @param {boolean} isPercentage - Is the number a percentage
 *
 * @example
 * import { ReportDataExtra } from 'redux/users/types';
 * const data = new ReportDataExtra(1, 'description', true);
 */
export class ReportDataExtra {
  data: number;
  description: string;
  isPercentage: boolean;
  color: string;

  constructor(data: number, description: string, isPercentage: boolean) {
    /** Value of the report data.
     * @type {number}
     */
    this.data = data;
    /** Description for tooltip.
     * @type {string}
     */
    this.description = description;
    /** Is the number a percentage
     * @type {boolean}
     */
    this.isPercentage = isPercentage;
    /** Text color
     * @type {string}
     */
    this.color = "#FFFFFF";
  }
  /**
   * Set the text color of the value.
   * @param {string} color - The new text color
   */
  setColor(color: string) {
    this.color = color;
  }
  /**
   * Update the value with a new value.
   * @param {number} data - The new number value
   */
  updateData(data: number) {
    this.data = data;
  }
}

/**
 * ChatGPT prompt
 * @category Users
 */
export interface ChatGPTPrompt {
  /** Prompt to be selected by user */
  user: string;
  /** Prompt to be sent to ChatGPT */
  chatgpt: string;
}

export type ScrapersStates =
  | "success"
  | "no-new-data"
  | "scrape-process-error"
  | "invalid-credentials"
  | "login-process-error"
  | "login-failed";

export type TodaysState = Record<
  "day" | "manual" | "scheduled",
  ScrapersStates | null
>;

/** User integration object */
export interface Integration {
  /** Is the integration active */
  active: boolean;
  /** Is the integration connect */
  connected: boolean;
  /** Integration credential keys */
  credentials: string[];
  /** Integration type: AP or POS */
  type: string;
  /** User ID */
  userId?: string;
  /** Integration vendor */
  vendor: string;
  /** State of the last scrape */
  state?: TodaysState;
}

export type IntegrationUser = Omit<User, "integrations"> &
  Integration & { isScheduled: boolean };

export interface ChecklistItem {
  itemCostDifference: number;
  areaId: string[];
  type: string;
  isSelected: boolean;
  unit: string;
  text: string;
  itemId: string;
  name: string;
  variety: string;
  absoluteValue: number;
}
