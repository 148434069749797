import { createActions } from "redux/create-actions";
import { UPDATE_SUPPLIERS_Payload } from "./sagas";
import { Supplier, SuppliersState } from "./types";

const actions = createActions("suppliers", {
  SET_STATE: {} as Partial<SuppliersState>,
  GET_SUPPLIERS: undefined,
  UPDATE_SUPPLIERS: {} as UPDATE_SUPPLIERS_Payload,
  ADD_SUPPLIERS_STATE: {} as Supplier,
  SUBSCRIBE_TO_SUPPLIERS: undefined,
  UNSUBSCRIBE_FROM_SUPPLIERS: undefined,
});

export default actions;
