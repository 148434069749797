import { Tag, Tooltip } from "@blueprintjs/core";
import { useCallback } from "react";
import { Invoice, InvoiceItem } from "redux/invoice/types";
import { ITEM_TAGS, ItemTag } from "../tag.helpers";
import { useGrandTotal, useNumberOfDays } from "./hooks";
import "./index.scss";
import { cn } from "@stockifi/shared";
import { getUserName } from "redux/users/helpers";

type Props = {
  data: Invoice;
  initialItems: InvoiceItem[];
  existingVoteNumbers: string[];
};

function InvoiceTags({ data, initialItems, existingVoteNumbers }: Props) {
  const { grandTotal } = useGrandTotal(data);
  const { numberOfDays } = useNumberOfDays(data);

  const getNumberofItems = useCallback(
    (tag: ItemTag) => {
      if (
        (tag.name === "percentChanged" || tag.name === "percentChanged75") &&
        !data?.updatedCosts
      )
        return 0;
      return initialItems?.filter((i) => tag.filterFn(i)).length;
    },
    [initialItems, data?.updatedCosts]
  );

  return (
    <div className="tags-wrapper">
      {(data.isFromApTransaction === undefined ||
        !data.isFromApTransaction) && (
        <Tag className="tag-uploaded-by-user m-1 mb-2" icon="upload">
          By user
        </Tag>
      )}
      {existingVoteNumbers.length > 0 && (
        <Tag className="tag-duplicate-invoice m-1 mb-2">
          Duplicate invoice from votes
        </Tag>
      )}
      {(data.isDuplicate || existingVoteNumbers.length > 0) && (
        <Tag className="tag-duplicate-invoice m-1 mb-2">Duplicate invoice</Tag>
      )}
      {data.isDiverseInvoice && (
        <Tag className="tag-diverse-invoice m-1 mb-2">Diverse invoice</Tag>
      )}
      {data.isForSupervisor && (
        <Tooltip
          content={`Marked By: ${
            data.isForSupervisorMarkedBy
              ? getUserName(data.isForSupervisorMarkedBy)
              : "No User Found"
          }`}
        >
          <Tag className="tag-for-supervisor m-1 mb-2">For Supervisor</Tag>
        </Tooltip>
      )}
      {data.isDraft && <Tag className="tag-draft m-1 mb-2">Draft</Tag>}
      {data.state === "resolved" && <Tag className="m-1 mb-2">Resolved</Tag>}
      {ITEM_TAGS.filter((tag) => {
        return getNumberofItems(tag) > 0;
      }).map((tag) => {
        const numberOfItems = getNumberofItems(tag);
        const colorClassName = tag.bgColorClass;
        return (
          <Tag className={cn(colorClassName, "m-1 mb-2")} key={tag.name}>
            {tag.text}
            {numberOfItems > 1 && (
              <Tag className="tag-stacked" round={true}>
                {numberOfItems}
              </Tag>
            )}
          </Tag>
        );
      })}

      {numberOfDays >= 31 && <Tag className="tag-31-days m-1 mb-2">31days</Tag>}
      {grandTotal < 500 &&
      (data.state !== "unresolved" || data.votedBy !== undefined) ? (
        <Tag className="tag-light-pink m-1">Under 500</Tag>
      ) : null}
      {grandTotal > 100000 && (
        <Tag className="tag-light-pink m-1 mb-2">Over 100K</Tag>
      )}
      {data.isInvoiceNumberUsed &&
        (data.state !== "unresolved" || data.votedBy !== undefined) && (
          <Tag className="tag-invoice m-1 mb-2">Invoice #{data.number}</Tag>
        )}
      {data &&
        data.newAliases &&
        Object.values(data.newAliases).flat(1).length > 0 && (
          <Tag className="tag-light-green m-1 mb-2">
            New Aliases
            {Object.values(data.newAliases).flat(1).length > 1 && (
              <Tag className="tag-stacked" round={true}>
                {Object.values(data.newAliases).flat(1).length}
              </Tag>
            )}
          </Tag>
        )}
      {data.createdBy === "bot" && (
        <Tag className="tag-created-by-bot m-1 mb-2">Created By Bot</Tag>
      )}
      {data.invoiceConsensusReached && (
        <Tag className="tag-purple m-1 mb-2">Consensus Reached</Tag>
      )}
    </div>
  );
}

export default InvoiceTags;
