import {
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { EventChannel, eventChannel } from "redux-saga";
import { APTransaction } from "redux/ap-transactions/types";
import { User } from "redux/users/types";
import { db, isLocalhost } from "services/firebase";

export function setUsersListener(
  loadAllUsers: boolean = false
): EventChannel<User[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const activeUsersCondition = [
      where("active", "==", true),
      where("organization", "==", false),
      where("employee", "==", false),
    ];
    const queryConstraints = [
      ...(loadAllUsers ? [] : activeUsersCondition),
      orderBy("name"),
    ];
    const userQuery = query(collection(db, "users"), ...queryConstraints);
    const unsub = onSnapshot(
      userQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const users = snapshot.docs.map(
          (doc) => new User(doc.id, doc.ref.path, doc.data())
        );

        emitter(users);
      }
    );

    return () => unsub();
  });
}

export function setUserTransactionsListener(
  userId: string,
  targetDate?: Date
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const constraints: QueryConstraint[] = [orderBy("createdAt", "desc")];

    if (targetDate) {
      const limitDate = moment(targetDate).subtract(61, "day").toDate();
      constraints.push(where("createdAt", ">=", limitDate));
      constraints.push(where("createdAt", "<=", targetDate));
    } else {
      const limitDate = moment().subtract(61, "day").toDate();
      constraints.push(where("createdAt", ">=", limitDate));
    }
    if (isLocalhost) constraints.push(limit(400));

    const userTransactionsQuery = query(
      collection(db, `users/${userId}/invoiceTransactions`),
      ...constraints
    );
    const unsub = onSnapshot(
      userTransactionsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const userTransactions: APTransaction[] = [];

        snapshot.forEach((doc) => {
          const current = {
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          } as APTransaction;
          const existingTransaction = userTransactions.find(
            (x) => x.invoiceNumber && x.invoiceNumber === current.invoiceNumber
          );

          if (existingTransaction) {
            const combinedFiles = new Set<string>([
              ...(existingTransaction.files || []),
              ...(existingTransaction.path ? [existingTransaction.path] : []),
              ...(current.files || []),
              ...(current.path ? [current.path] : []),
            ]);

            existingTransaction.files = Array.from(combinedFiles);
          } else userTransactions.push(current);
        });

        emitter(userTransactions);
      }
    );

    return () => unsub();
  });
}

export function setUserStatusTransactionsListener(
  userId: string,
  resolved = false,
  maxResolvedTransactions = 15
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 61);

    const constraints: QueryConstraint[] = [
      where("createdAt", ">=", newDate),
      orderBy("createdAt", "desc"),
    ];

    if (resolved) {
      constraints.push(where("resolved", "==", true));
      constraints.push(limit(maxResolvedTransactions));
    } else constraints.push(where("resolved", "==", false));

    const userTransactionsQuery = query(
      collection(db, `users/${userId}/invoiceTransactions`),
      ...constraints
    );
    const unsub = onSnapshot(
      userTransactionsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const userTransactions: APTransaction[] = [];

        snapshot.forEach((doc) => {
          const current = {
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          } as APTransaction;
          const existingTransaction = userTransactions.find(
            (x) => x.invoiceNumber && x.invoiceNumber === current.invoiceNumber
          );

          if (existingTransaction) {
            const combinedFiles = new Set<string>([
              ...(existingTransaction.files || []),
              ...(existingTransaction.path ? [existingTransaction.path] : []),
              ...(current.files || []),
              ...(current.path ? [current.path] : []),
            ]);

            existingTransaction.files = Array.from(combinedFiles);
          } else userTransactions.push(current);
        });

        emitter(userTransactions);
      }
    );

    return () => unsub();
  });
}

export function setNoteSuggestions(): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      doc(db, "adminPanelSettings", "userStatusNoteSuggestions"),
      (doc) => {
        const noteSuggestions: DocumentData[] = doc.data()?.data ?? [];

        emitter(noteSuggestions);
      }
    );

    return () => unsub();
  });
}
