import {
  DocumentData,
  Query,
  QuerySnapshot,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { MunuFile } from "redux/sales/types";
import { db } from "services/firebase";

export function setUserSalesListener(
  userId: string,
  showDeletedSales: boolean,
  LIMIT?: number
): EventChannel<object> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const salesRef = collection(db, "munuFiles");

    const salesQuery: Query<DocumentData> =
      showDeletedSales && LIMIT
        ? query(
            salesRef,
            where("userId", "==", userId),
            where("deleted", "==", true),
            orderBy("periodEnd", "desc"),
            limit(LIMIT)
          )
        : query(
            salesRef,
            where("userId", "==", userId),
            where("deleted", "==", false),
            orderBy("periodEnd", "desc")
          );

    const unsub = onSnapshot(
      salesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: MunuFile[] = snapshot.docs.map(
          (x) => new MunuFile(x.id, x.data())
        );
        emitter(data);
      }
    );
    return () => unsub();
  });
}
