import {
  DocumentData,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  collectionGroup,
  endBefore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment";
import { EventChannel, eventChannel } from "redux-saga";
import { Fee, Invoice, InvoiceVote } from "redux/invoice/types";
import { db } from "services/firebase";

export function setInvoiceFeesListener(
  userId: string
): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const collRef = collection(db, "users", userId, "fees");

    const unsub = onSnapshot(collRef, (snapshot) => {
      const data: Fee[] = snapshot.docs.map(
        (x) =>
          ({
            id: x.id,
            ...x.data(),
          }) as Fee
      );

      emitter(data);
    });
    return () => unsub();
  });
}

export function setUserInvoiceListener(
  userId: string,
  isAdmin = false,
  state = "all"
): EventChannel<Invoice[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const currTime: Date = new Date();
    currTime.setDate(currTime.getDate() - 89);

    const constraints = [where("createdAt", ">=", currTime)];
    if (isAdmin) constraints.splice(0, constraints.length);
    if (state !== "all" && state !== "deleted") {
      constraints.push(where("state", "==", state));
      constraints.push(where("deleted", "==", false));
    }

    if (state === "deleted") constraints.push(where("deleted", "==", true));

    const unsub = onSnapshot(
      query(collection(db, "users", userId, "invoices"), ...constraints),
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Invoice[] = snapshot.docs.map(
          (x) => new Invoice(x.id, x.ref.path, x.data())
        );

        emitter(data);
      }
    );
    return () => unsub();
  });
}

export function setUserInvoiceVotesListener(
  userId: string
): EventChannel<Invoice[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const userVotesRef = collection(db, "users", userId, "invoiceVotes");
    const votesQuery = query(userVotesRef, where("state", "==", "unresolved"));

    const unsub = onSnapshot(
      votesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Invoice[] = snapshot.docs.map(
          (x) => new Invoice(x.id, x.ref.path, x.data())
        );

        emitter(data);
      }
    );
    return () => unsub();
  });
}

export function setUserVotesResultListener(
  userId: string,
  invoiceId: string
): EventChannel<Invoice[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const userVotesRef = collection(db, "users", userId, "invoiceVotes");
    const votesQuery = query(userVotesRef, where("invoiceId", "==", invoiceId));

    const unsub = onSnapshot(
      votesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Invoice[] = snapshot.docs
          .filter((x) => x.data().isDraft !== true)
          .map((x) => new Invoice(x.id, x.ref.path, x.data()));

        emitter(data);
      }
    );
    return () => unsub();
  });
}

export function setAllUserInvoiceListener(
  direction?: "next" | "prev",
  dateFromInput?: Date,
  dateToInput?: Date,
  isAdmin?: boolean
): EventChannel<{ data: Invoice[]; dateFrom: Date; dateTo: Date }> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const limitDateFrom = moment().subtract(1, "days").startOf("day").toDate();
    const limitDateTo = moment().endOf("day").toDate();

    const currTime: Date = new Date();
    currTime.setDate(currTime.getDate() - 89);

    const constraints = [where("createdAt", ">=", currTime)];
    if (isAdmin) constraints.splice(0, constraints.length);

    const invoicesRef = collectionGroup(db, "invoices");
    let invoiceQuery: Query<DocumentData>;
    let dateFrom: Date;
    let dateTo: Date;
    if (!!direction && !!dateFromInput && !!dateToInput) {
      let dateFromProcess: Date;
      let dateToProcess: Date;
      if (direction === "next") {
        dateFromProcess = moment(dateFromInput).subtract(2, "days").toDate();
        dateToProcess = moment(dateToInput).subtract(2, "days").toDate();
        invoiceQuery = query(
          invoicesRef,
          ...constraints,
          orderBy("createdAt", "desc"),
          where("createdAt", ">=", dateFromProcess),
          where("createdAt", "<=", dateToProcess)
        );
      } else {
        dateFromProcess = moment(dateFromInput).add(2, "days").toDate();
        dateToProcess = moment(dateToInput).add(2, "days").toDate();
        invoiceQuery = query(
          invoicesRef,
          ...constraints,
          orderBy("createdAt", "desc"),
          where("createdAt", ">=", dateFromProcess),
          where("createdAt", "<=", dateToProcess)
        );
      }
      dateFrom = dateFromProcess;
      dateTo = dateToProcess;
    } else {
      invoiceQuery = query(
        invoicesRef,
        ...constraints,
        orderBy("createdAt", "desc"),
        where("createdAt", ">=", limitDateFrom),
        where("createdAt", "<=", limitDateTo)
      );
      dateFrom = limitDateFrom;
      dateTo = limitDateTo;
    }

    const unsub = onSnapshot(
      invoiceQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Invoice[] = snapshot.docs.map(
          (x) => new Invoice(x.id, x.ref.path, x.data())
        );
        emitter({ data, dateFrom, dateTo });
      }
    );
    return () => unsub();
  });
}

export function setInvoiceVotesListener(
  direction?: "next" | "previous",
  lastDoc?: QueryDocumentSnapshot<DocumentData>,
  firstDoc?: QueryDocumentSnapshot<DocumentData>,
  userId?: string,
  voterUserId?: string
): EventChannel<object> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const LIMIT = 100;

    const invoicesRef = collectionGroup(db, "invoices");

    let constraints: QueryConstraint[] = [limit(LIMIT)];
    if (direction) {
      constraints =
        direction === "next"
          ? [startAfter(lastDoc), limit(LIMIT)]
          : [endBefore(firstDoc), limitToLast(LIMIT)];
    }

    if (userId) constraints.push(where("userId", "==", userId));
    if (voterUserId)
      constraints.push(where("voters", "array-contains", voterUserId));

    const votesQuery: Query<DocumentData> = query(
      invoicesRef,
      where("state", "==", "unresolved"),
      where("deleted", "==", false),
      where("lastVotedAt", "!=", null),
      orderBy("lastVotedAt", "desc"),
      ...constraints
    );

    const unsub = onSnapshot(
      votesQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: InvoiceVote[] = snapshot.docs
          .filter((x) => !x.data().deleted)
          .map((x) => ({
            id: x.id,
            invoiceRefPath: x.ref.path,
            voters: x.data().voters ?? [],
            userId: x.data().userId,
            lastVotedAt: x.data().lastVotedAt,
            invoiceComments: x.data().invoiceComments ?? "",
          }));
        const returnedLastDoc =
          snapshot.docs.length !== LIMIT
            ? undefined
            : snapshot.docs[snapshot.docs.length - 1];
        const returnedFirstDoc = !direction ? undefined : snapshot.docs[0];

        emitter({ data, lastDoc: returnedLastDoc, firstDoc: returnedFirstDoc });
      }
    );
    return () => unsub();
  });
}

export function setUserStatusInvoiceListener(
  userId: string,
  checked = false,
  maxInvoices = 15
): EventChannel<Invoice[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const constraints: QueryConstraint[] = [where("deleted", "==", false)];

    if (checked) {
      constraints.push(where("isChecked", "==", true));
      constraints.push(orderBy("createdAt", "desc"));
      constraints.push(limit(maxInvoices));
    } else {
      constraints.push(where("isChecked", "==", false));
    }

    const unsub = onSnapshot(
      query(collection(db, "users", userId, "invoices"), ...constraints),
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Invoice[] = snapshot.docs.map(
          (x) => new Invoice(x.id, x.ref.path, x.data())
        );

        emitter(data);
      }
    );
    return () => unsub();
  });
}
