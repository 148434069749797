import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Card, Checkbox, Elevation, Icon, NonIdealState, Tooltip, } from "@blueprintjs/core";
import style from "./index.module.css";
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel, } from "@tanstack/react-table";
import { Fragment, useEffect, useMemo, useState } from "react";
import TableToolbar from "./toolbar";
import getColumns, { defaultColumn, getColumnVisibility } from "./helpers";
import { IconNames } from "@blueprintjs/icons";
import { cn } from "../styles";
export const Table = ({ headers, data, actions, getValue, bulkActions, columnProps, fieldProps, renderExpandedRow, module, searchQuery, setSearchQuery, }) => {
    var _a;
    const [rowSelection, setRowSelection] = useState({});
    const [sorting, setSorting] = useState([]);
    const [sortedData, setSortedData] = useState([...data]);
    const [columnVisibility, setColumnVisibility] = useState(getColumnVisibility(headers, columnProps === null || columnProps === void 0 ? void 0 : columnProps.defaultOrder));
    const [columnOrder, setColumnOrder] = useState(((_a = columnProps === null || columnProps === void 0 ? void 0 : columnProps.defaultOrder) === null || _a === void 0 ? void 0 : _a.map((col) => col.name)) || []);
    const columns = useMemo(() => [...getColumns(headers, rowSelection, module, getValue)], [headers, rowSelection]);
    useEffect(() => {
        const sortedData = [...data].sort((a, b) => {
            const aIndex = a.id;
            const bIndex = b.id;
            const aSelected = rowSelection[aIndex];
            const bSelected = rowSelection[bIndex];
            if (aSelected && !bSelected) {
                return -1; // a comes first if it's selected and b is not
            }
            if (!aSelected && bSelected) {
                return 1; // b comes first if it's selected and a is not
            }
            return 0; // if both are selected or both are not selected, they remain in their original order
        });
        setSortedData(sortedData);
    }, [rowSelection, data]);
    const table = useReactTable({
        columns,
        data: sortedData,
        defaultColumn: defaultColumn(),
        state: Object.assign({ columnOrder,
            columnVisibility,
            rowSelection,
            sorting }, ((columnProps === null || columnProps === void 0 ? void 0 : columnProps.firstColumn) && {
            columnPinning: { left: [columnProps === null || columnProps === void 0 ? void 0 : columnProps.firstColumn] },
        })),
        enableRowSelection: true,
        enableSorting: true,
        onColumnOrderChange: setColumnOrder,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        meta: { fieldProps, columnProps, bulkActions },
        getRowId: (row) => row.id,
        autoResetPageIndex: false,
    });
    return (_jsxs(Card, Object.assign({ elevation: Elevation.ONE }, { children: [_jsx(TableToolbar, { table: table, searchQuery: searchQuery, setSearchQuery: setSearchQuery }), table.getRowModel().rows.length > 0 ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: style.container }, { children: _jsxs("table", Object.assign({ className: style.table }, { children: [_jsx("thead", { children: table.getHeaderGroups().map((headerGroup) => (_jsxs("tr", Object.assign({ className: style.tr }, { children: [_jsx("th", Object.assign({ className: style.th }, { children: _jsx(Checkbox, Object.assign({ className: "mb-0" }, {
                                                    checked: table.getIsAllPageRowsSelected(),
                                                    indeterminate: table.getIsSomePageRowsSelected(),
                                                    onChange: table.getToggleAllPageRowsSelectedHandler(),
                                                })) })), headerGroup.headers.map((header) => {
                                                var _a;
                                                return (_jsx("th", Object.assign({ className: style.th }, { children: header.isPlaceholder ? null : (_jsxs("div", Object.assign({}, {
                                                        className: cn(header.column.getCanSort() &&
                                                            "cursor-pointer select-none", "d-flex justify-content-between"),
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }, { children: [flexRender(header.column.columnDef.header, header.getContext()), _jsx(Icon, { icon: (_a = {
                                                                    asc: IconNames.CHEVRON_UP,
                                                                    desc: IconNames.CHEVRON_DOWN,
                                                                }[header.column.getIsSorted()]) !== null && _a !== void 0 ? _a : IconNames.EXPAND_ALL, className: "ms-2", color: "gray" })] }))) }), `header-${header.id}-${header.index}`));
                                            }), actions && (_jsx("th", Object.assign({ className: style.th, style: { width: "10px" } }, { children: "Actions" })))] }), `headerGroup-${headerGroup.id}`))) }), _jsx("tbody", { children: table.getRowModel().rows.map((row) => (_jsxs(Fragment, { children: [_jsxs("tr", Object.assign({ className: style.tr }, { children: [_jsx("td", Object.assign({ className: style.td }, { children: _jsx(Checkbox, Object.assign({ className: "mb-0" }, {
                                                            checked: row.getIsSelected(),
                                                            disabled: !row.getCanSelect(),
                                                            indeterminate: row.getIsSomeSelected(),
                                                            onChange: row.getToggleSelectedHandler(),
                                                        })) })), row.getVisibleCells().map((cell) => (_jsx(Fragment, { children: _jsx("td", Object.assign({ className: style.td }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) })) }, `cell-${cell.id}`))), actions && (_jsx("td", Object.assign({ className: cn(style.td, style.actions) }, { children: _jsx("div", Object.assign({ className: "d-flex justify-content-center", style: { gap: 4 }, onClick: (e) => e.stopPropagation() }, { children: actions(row.original) })) })))] })), row.getIsExpanded() && renderExpandedRow ? (_jsx("tr", { children: _jsx("td", Object.assign({ colSpan: row.getVisibleCells().length }, { children: renderExpandedRow(row.original) })) })) : null] }, `row-${row.id}-${row.index}`))) })] })) })), _jsxs("div", Object.assign({ className: "mt-3", style: { padding: "0 15px" } }, { children: [_jsx(Tooltip, Object.assign({ compact: true, content: "Select all rows" }, { children: _jsx(Checkbox, Object.assign({ className: "mb-0" }, {
                                    checked: table.getIsAllRowsSelected(),
                                    indeterminate: table.getIsSomeRowsSelected(),
                                    onChange: table.getToggleAllRowsSelectedHandler(),
                                })) })), Object.keys(rowSelection).length, " of", " ", table.getPreFilteredRowModel().rows.length, " row(s) selected."] }))] })) : (_jsx(NonIdealState, { icon: IconNames.BAN_CIRCLE, title: "No data found", description: "No data found for your query.", className: "my-4" }))] })));
};
