import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { db } from "services/firebase";

export function setSuppliersListener(): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const collRef = collection(db, "suppliers");
    const unsub = onSnapshot(
      collRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: DocumentData[] = [];

        snapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref,
          });
        });

        emitter(data);
      }
    );

    return () => unsub();
  });
}
