import { Button, IconName } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actionsUser from "redux/users/actions";
import { claims, hasAccess } from "../../../permissions";
import "./index.scss";
import { createSelector } from "@reduxjs/toolkit";
import { cn } from "@stockifi/shared";
import _ from "lodash";
import { RootState } from "redux/store";

type Props = {
  userId: string;
  readonly?: boolean;
  property?: string;
};

function InvoiceNotes({ userId, readonly, property = "invoiceNotes" }: Props) {
  const dispatch = useAppDispatch();
  const selectUsers = (state: RootState) => state.users.users;
  const selectMatchedUser = createSelector([selectUsers], (users) =>
    users.find((user) => user.id === userId)
  );

  const users = useAppSelector(selectUsers);
  const user = useAppSelector(selectMatchedUser);
  const authClaims = useAppSelector((state) => state.auth.authClaims);

  const [notes, setNotes] = useState(user?.[property]);
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const [notesIcon, setNotesIcon] = useState<IconName>("edit");

  useEffect(() => {
    setNotesIcon(editNotes ? "floppy-disk" : "edit");
  }, [editNotes]);

  useEffect(() => {
    setNotes(user?.[property]);
  }, [user, property]);

  function handleClick(notes?: string) {
    if (notesIcon === "floppy-disk" && user) {
      const updatedUser = _.cloneDeep(user);
      updatedUser[property] = notes ?? "";
      dispatch(
        actionsUser.SET_STATE({
          users: users.map((existingUser) => {
            if (existingUser.id === updatedUser.id) {
              return updatedUser;
            }
            return existingUser;
          }),
        })
      );
      dispatch(
        actionsUser.UPDATE_USER({
          user: updatedUser,
          userId,
        })
      );
    }
    setEditNotes((prevState) => !prevState);
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <p>Account Guidelines</p>
      <div
        className={cn(
          "notes col-xl-12 mt-1 p-2 pb-5",
          editNotes && "notes-editable"
        )}
      >
        <div
          id="notes"
          contentEditable={!readonly && editNotes}
          className="edit-notes"
          suppressContentEditableWarning={true}
          onInput={(e) => setNotes(e.currentTarget.innerText)}
        >
          {user?.[property]}
        </div>
        {(user?.isFoodEnabled || user?.isFoodVarianceExcluded) &&
          property === "invoiceNotes" && (
            <div className="food-enabled-tag">Food item per item</div>
          )}
        {hasAccess(authClaims, claims.editInvoiceNotes) && (
          <Button
            disabled={readonly}
            minimal
            icon={notesIcon}
            onClick={() => handleClick(notes)}
            className="button"
          />
        )}
      </div>
    </div>
  );
}

export default InvoiceNotes;
