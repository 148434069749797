import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { ConfigState } from "./types";

const initialState: ConfigState = {
  // Advanced Filter Panel Layouts
  layouts: [],
  createLayoutLoading: false,
  deleteLayoutLoading: false,
  // Permissions Module
  permissionsLoading: false,
  permissions: [],
  createPermissionLoading: false,
  updatePermissionLoading: false,
  // Conditional Formatting
  formatSets: [],
  createFormatSetLoading: false,
  editFormatSetLoading: false,
  deletingFormatSetId: "",
  // Predefined Filter
  predefinedFilterAttributes: {},
  predefinedFilters: [],
  loadingPredefinedFilters: false,
  loadingPredefinedFilterAttributes: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
