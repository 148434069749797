import { DateFormatProps } from "@blueprintjs/datetime2";
import { cn } from "@stockifi/shared";
import moment from "moment";
import React from "react";
import { Invoice, InvoiceItem, Items } from "redux/invoice/types";
import store from "redux/store";
import { v4 } from "uuid";
import style from "./invoice-card/index.module.scss";

export type Occurrences = {
  value: string;
  weight: number;
};

export function highestOccurrences(
  arr: Occurrences[],
  totalWeight: number,
  ignoreMinConsensus = false
): string {
  const totalArrWeight = arr.reduce((acc, curr) => acc + curr.weight, 0);
  const minConsensus = ignoreMinConsensus
    ? 0
    : store.getState().settings.dataVotingConfig.minConsensus ?? 1;

  if (
    arr.length < 2 ||
    totalWeight < minConsensus ||
    totalArrWeight < minConsensus
  )
    return "";

  const arrToProcess = arr.map((x) => {
    if (x.value === undefined) return { value: "", weight: x.weight };
    else return x;
  });
  // IF ALL VALUES ARE EQUAL
  if (
    arrToProcess
      .map((x) => x.value)
      .every((val, _, arrToProcess) => val === arrToProcess[0])
  )
    return arrToProcess[0].value;

  // IF ALL WEIGHTS ARE EQUAL
  const allWeightEqual = arrToProcess
    .map((x) => x.weight)
    .every((val, _, arrToProcess) => val === arrToProcess[0]);

  if (allWeightEqual) {
    const sortedArr = [...arrToProcess.map((x) => x.value)].sort(
      (a, b) =>
        arrToProcess.filter((v) => v.value === a).length -
        arrToProcess.filter((v) => v.value === b).length
    );

    if (arrToProcess.length % 2 === 0) {
      const arrCopy = [...sortedArr];
      const firstChunk = arrCopy.splice(0, arrCopy.length / 2);
      const secondChunk = arrCopy;
      if (
        firstChunk[0] !== secondChunk[0] &&
        firstChunk.every((item) => item === firstChunk[0]) &&
        secondChunk.every((item) => item === secondChunk[0])
      )
        return "";
    }

    const highestOcc = sortedArr.pop();

    if (
      highestOcc &&
      arrToProcess.filter((str) => str.value === highestOcc).length > 1
    )
      return highestOcc as string;
  } else {
    // WE GROUP BY VALUE AND SUM WEIGHT
    const groupedByValue = arrToProcess.reduce(
      (acc, curr) => {
        if (acc[curr.value]) {
          acc[curr.value] += curr.weight;
        } else {
          acc[curr.value] = curr.weight;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    // RETURN VALUE WITH HIGHEST TOTAL WEIGHT
    const highestOcc = Object.keys(groupedByValue).reduce((a, b) =>
      groupedByValue[a] > groupedByValue[b] ? a : b
    );

    return groupedByValue[highestOcc] >= minConsensus ? highestOcc : "";
  }

  return "";
}

export function getMomentFormatter(format: string): DateFormatProps {
  // note that locale argument comes from locale prop and may be undefined
  return {
    formatDate: (date, _) => moment(date).tz("Europe/Oslo").format(format),
    parseDate: (str, _) => moment(str, format).toDate(),
    placeholder: "Select a date...",
  };
}

export function getGrandTotal(itemList: InvoiceItem[], foodTotal = 0): string {
  const total = itemList.reduce((acc, item) => acc + item.total, 0) + foodTotal;

  return Number(total).toFixed(2);
}

type GetBorderColorProps = {
  oldCost: number;
  cost: number;
  isNew: boolean;
  quantity: number;
  total: number;
  isOpenItem: boolean;
  nameChanged: boolean;
  wasOpenItem?: boolean;
};

export const getBorderColor = ({
  oldCost,
  cost,
  isNew,
  quantity,
  total,
  isOpenItem,
  wasOpenItem,
}: GetBorderColorProps) => {
  const defaultClassName = cn("mb-3 card-padding", style.border);
  if (isOpenItem === true) return cn(defaultClassName, style.border_open_item);
  if (wasOpenItem === true)
    return cn(defaultClassName, style.border_was_open_item);
  if (isNew === true) return cn(defaultClassName, style.border_yellow);

  const percentage: number =
    !!cost && !!oldCost && cost !== Infinity
      ? ((cost - oldCost) / oldCost) * 100
      : 0;

  if (cost < 0 || quantity < 0 || total < 0)
    return cn(defaultClassName, style.border_red);
  else if (percentage >= 75 || percentage <= -75)
    return cn(defaultClassName, style.border_dark_orange);
  else if (percentage >= 15 || percentage <= -15)
    return cn(defaultClassName, style.border_orange);

  return cn(defaultClassName, "ingredient");
};

export function getFormattedItem(
  votes: Invoice[]
): Record<string, Occurrences[] | string[]>[] {
  const data: any[] = [];
  if (votes.length > 0) {
    votes.forEach((vote) => {
      const voteWeight = getVoteWeight(vote.votedBy);
      vote.itemsList.forEach((item, idx) => {
        if (data.findIndex((datum) => datum.idx === idx) === -1) {
          const itemPayload: any = {
            idx,
            uuid: [item.uuid],
            itemId: [{ value: item.id, weight: voteWeight }],
            name: [{ value: item.name, weight: voteWeight }],
            cost: [
              {
                value: Number(item.cost ?? 0).toString(),
                weight: voteWeight,
              },
            ],
            oldCost: [{ value: item.oldCost.toString(), weight: voteWeight }],
            quantity: [
              {
                value: Number(item.quantity ?? 0).toString(),
                weight: voteWeight,
              },
            ],
            total: [
              {
                value: Number(item.total ?? 0).toString(),
                weight: voteWeight,
              },
            ],
            isNew: [{ value: item.isNew?.toString(), weight: voteWeight }],
            isOpenItem: [
              { value: item.isOpenItem?.toString(), weight: voteWeight },
            ],
            isFee: [{ value: item.isFee?.toString(), weight: voteWeight }],
            isSponsored: [
              { value: item.isSponsored?.toString(), weight: voteWeight },
            ],
            type: [{ value: item.type, weight: voteWeight }],
            size: [{ value: item.size, weight: voteWeight }],
            unit: [{ value: item.unit, weight: voteWeight }],
            variety: [{ value: item.variety, weight: voteWeight }],
            aliases: vote.newAliases[item.id]?.map((x) => ({
              value: x.name,
              weight: voteWeight,
            })) ?? [{ value: "", weight: voteWeight }],
            invoicePage: [
              {
                value: item.invoicePage ? item.invoicePage.toString() : "1",
                weight: voteWeight,
              },
            ],
          };

          data.push(itemPayload);
        } else {
          const targetIndex = data.findIndex((datum) => datum.idx === idx);
          data[targetIndex].uuid.push(item.uuid);
          data[targetIndex].itemId.push({
            value: item.id,
            weight: voteWeight,
          });
          data[targetIndex].name.push({
            value: item.name,
            weight: voteWeight,
          });
          data[targetIndex].cost.push({
            value: Number(item.cost ?? 0).toString(),
            weight: voteWeight,
          });
          data[targetIndex].oldCost.push({
            value: item.oldCost.toString(),
            weight: voteWeight,
          });
          data[targetIndex].quantity.push({
            value: Number(item.quantity ?? 0).toString(),
            weight: voteWeight,
          });
          data[targetIndex].total.push({
            value: Number(item.total ?? 0).toString(),
            weight: voteWeight,
          });
          data[targetIndex].isNew.push({
            value: item.isNew?.toString(),
            weight: voteWeight,
          });
          data[targetIndex].isOpenItem.push({
            value: item.isOpenItem?.toString(),
            weight: voteWeight,
          });
          data[targetIndex].type.push({
            value: item.type,
            weight: voteWeight,
          });
          data[targetIndex].size.push({
            value: item.size,
            weight: voteWeight,
          });
          data[targetIndex].unit.push({
            value: item.unit,
            weight: voteWeight,
          });
          data[targetIndex].variety.push({
            value: item.variety,
            weight: voteWeight,
          });
          data[targetIndex].isFee.push({
            value: item.isFee?.toString(),
            weight: voteWeight,
          });
          data[targetIndex].isSponsored.push({
            value: item.isSponsored?.toString(),
            weight: voteWeight,
          });
          data[targetIndex].aliases = [
            ...(data[targetIndex].aliases ?? []),
            ...(vote.newAliases[item.id]?.map((x) => ({
              value: x.name,
              weight: voteWeight,
            })) ?? [{ value: "", weight: voteWeight }]),
          ];
          data[targetIndex].invoicePage.push({
            value: item.invoicePage ? item.invoicePage.toString() : "1",
            weight: voteWeight,
          });
        }
      });
    });
  }

  return data;
}

export const getResolvingItems = (
  items: Record<string, Occurrences[] | string[]>[],
  totalWeight: number,
  itemsLengthConsensus: number,
  maxAliasesLength?: Map<number, number>
) => {
  const resolvingItems: InvoiceItem[] = [];
  items.slice(0, itemsLengthConsensus).forEach((item, itemIdx) => {
    if (item.name.length > 0) {
      const itemCostToCheck = item.cost.map((x) => {
        if (typeof x === "string") return x;
        const numValue = Number(x.value).toFixed(2);
        return { ...x, value: numValue };
      });
      const itemTotalToCheck = item.total.map((x) => {
        if (typeof x === "string") return x;
        const numValue = Number(x.value).toFixed(2);
        return { ...x, value: numValue };
      });
      const itemQtyToCheck = item.quantity.map((x) => {
        if (typeof x === "string") return x;
        const numValue = Number(x.value).toFixed(2);
        return { ...x, value: numValue };
      });

      const itemCostCheckResult = highestOccurrences(
        itemCostToCheck as Occurrences[],
        totalWeight
      );
      const itemQuantityCheckResult = highestOccurrences(
        itemQtyToCheck as Occurrences[],
        totalWeight
      );
      const itemTotalCheckResult = highestOccurrences(
        itemTotalToCheck as Occurrences[],
        totalWeight
      );

      const realItemCost = (item.cost as Occurrences[]).find(
        (x) =>
          typeof x !== "string" &&
          Number(x.value).toFixed(2) === itemCostCheckResult
      );
      const realItemQty = (item.quantity as Occurrences[]).find(
        (x) =>
          typeof x !== "string" &&
          Number(x.value).toFixed(2) === itemQuantityCheckResult
      );
      const realItemTotal = (item.total as Occurrences[]).find(
        (x) =>
          typeof x !== "string" &&
          Number(x.value).toFixed(2) === itemTotalCheckResult
      );

      const itemCost = realItemCost?.value ?? "";
      const itemQuantity = realItemQty?.value ?? "";
      const itemTotal = realItemTotal?.value ?? "";

      const itemOldCost = highestOccurrences(
        item.oldCost as Occurrences[],
        totalWeight
      );
      const itemIsNew = highestOccurrences(
        item.isNew as Occurrences[],
        totalWeight
      );
      const itemIsOpenItem = highestOccurrences(
        item.isOpenItem as Occurrences[],
        totalWeight
      );
      const itemIsSponsored = highestOccurrences(
        item.isSponsored as Occurrences[],
        totalWeight
      );

      const invoicePagesOccurances = item.invoicePage as Occurrences[];
      const invoicePagesHighestOcc = highestOccurrences(
        invoicePagesOccurances,
        totalWeight
      );

      const invoicePage = invoicePagesHighestOcc
        ? Number(invoicePagesHighestOcc)
        : Math.max(...invoicePagesOccurances.map((x) => Number(x.value)));

      let itemIsFee = highestOccurrences(
        item.isFee as Occurrences[],
        totalWeight
      );
      let itemName = highestOccurrences(
        item.name as Occurrences[],
        totalWeight
      );
      let itemType = highestOccurrences(
        item.type as Occurrences[],
        totalWeight
      );
      let itemSize = highestOccurrences(
        item.size as Occurrences[],
        totalWeight
      );
      let itemUnit = highestOccurrences(
        item.unit as Occurrences[],
        totalWeight
      );
      let itemVariety = highestOccurrences(
        item.variety as Occurrences[],
        totalWeight
      );
      let itemId = highestOccurrences(
        item.itemId as Occurrences[],
        totalWeight
      );

      if (
        itemName === "" ||
        itemType === "" ||
        ((itemSize === "" || itemUnit === "" || itemVariety === "") &&
          !(itemType === "fee" || itemIsFee === "true"))
      ) {
        itemId = "";
      }

      if (itemId === "") {
        itemName = itemName.toLowerCase() === "diverse item" ? itemName : "";
        itemType = itemName.toLowerCase() === "diverse item" ? "fee" : "";
        itemIsFee = itemName.toLowerCase() === "diverse item" ? "true" : "";
        itemSize = "";
        itemUnit = "";
        itemVariety = "";
      }

      const itemAliases = item.aliases
        ? [
            highestOccurrences(item.aliases as Occurrences[], totalWeight),
          ].filter((alias) => alias)
        : [];

      let aliases = itemAliases;
      const mostAlias = maxAliasesLength?.get(itemIdx) ?? 0;
      const emptyFieldLength = mostAlias - itemAliases.length;
      if (emptyFieldLength > 0)
        aliases = [...aliases, ...Array(emptyFieldLength).fill("")];

      const payload: InvoiceItem = {
        index: resolvingItems.length,
        id: itemId,
        name: itemName,
        cost: Number(itemCost),
        oldCost: Number(itemOldCost),
        quantity: Number(itemQuantity),
        total: Number(itemTotal),
        isNew: itemIsNew === "true" && itemId !== "",
        isOpenItem: itemIsOpenItem === "true",
        isSponsored: itemIsSponsored === "true",
        type: itemType,
        size: Number(itemSize),
        unit: itemUnit,
        variety: itemVariety,
        errorCost: "",
        errorQuantity: "",
        errorTotal: "",
        changedProperty: "",
        wasOpenItem: false,
        nameChanged: false,
        autoTotal: false,
        autoQuantity: false,
        invoicePage: invoicePage,
        uuid: v4(),
        allUuidFromVotes: item.uuid as string[],
        hasError: false,
        isFee: itemIsFee && itemIsFee === "true" ? true : false,
        aliases,
      };

      if (itemIsFee) payload.isFee = itemIsFee === "true";

      resolvingItems.push(payload);
    }
  });
  return resolvingItems;
};

export const getFormattedDetails = (votes: Invoice[]) => {
  const data: Record<string, Occurrences[]> = {};
  if (votes.length > 0) {
    votes.forEach((vote) => {
      const voteWeight = getVoteWeight(vote.votedBy);
      if (
        Object.keys(data).includes("number") &&
        Object.keys(data).includes("supplierId") &&
        Object.keys(data).includes("deliveryDate") &&
        Object.keys(data).includes("foodTotal") &&
        Object.keys(data).includes("supplierName") &&
        Object.keys(data).includes("isDuplicate") &&
        Object.keys(data).includes("isDiverseInvoice")
      ) {
        data.number.push({ value: vote.number, weight: voteWeight });
        data.supplierId.push({
          value: vote.supplierId,
          weight: voteWeight,
        });
        data.supplierName.push({
          value: vote.supplierName,
          weight: voteWeight,
        });
        const deliveryDate = new Date(vote.deliveryDate?.getTime());
        deliveryDate.setHours(9, 0, 0, 0);
        data.deliveryDate.push({
          value: deliveryDate.toString(),
          weight: voteWeight,
        });
        data.foodTotal.push({
          value: (vote.foodTotal ?? 0).toString(),
          weight: voteWeight,
        });
        data.isDuplicate.push({
          value: vote.isDuplicate.toString(),
          weight: voteWeight,
        });
      } else {
        data.number = [{ value: vote.number, weight: voteWeight }];
        data.supplierId = [{ value: vote.supplierId, weight: voteWeight }];
        data.supplierName = [{ value: vote.supplierName, weight: voteWeight }];
        const deliveryDate = new Date(vote.deliveryDate?.getTime());
        deliveryDate.setHours(9, 0, 0, 0);
        data.deliveryDate = [
          {
            value: deliveryDate.toString(),
            weight: voteWeight,
          },
        ];
        data.foodTotal = [
          { value: (vote.foodTotal ?? 0).toString(), weight: voteWeight },
        ];
        data.isDuplicate = [
          { value: vote.isDuplicate.toString(), weight: voteWeight },
        ];
        data.isDiverseInvoice = [
          { value: vote.isDiverseInvoice.toString(), weight: voteWeight },
        ];
      }
    });
  }

  return data;
};

export const getVoteWeight = (userId: string | undefined) => {
  if (!userId || userId === "") return 0;
  if (userId === "voter bot")
    return store.getState().settings.dataVotingConfig.voterBotWeight ?? 0;
  else if (userId === "vertex bot")
    return store.getState().settings.dataVotingConfig.vertexBotWeight ?? 0;
  else if (userId === "gemini pro bot")
    return store.getState().settings.dataVotingConfig.vertexBotWeight ?? 0;
  else if (userId === "gemini vision bot")
    return (
      store.getState().settings.dataVotingConfig.geminiProVisionWeight ?? 0
    );
  else {
    const user = store
      .getState()
      .users.users.find((user) => user.id === userId);

    return user?.invoiceVotingWeight ?? 0;
  }
};

export const handleItemSelect = (
  item: Items,
  resolvingItems: InvoiceItem[],
  idx: number,
  setResolvingItems: React.Dispatch<React.SetStateAction<InvoiceItem[]>>,
  isNew: boolean
) => {
  const targetItem = resolvingItems[idx];
  const isOrWasOpenItem = targetItem.isOpenItem || targetItem.wasOpenItem;

  const getCost = () => {
    if (isOrWasOpenItem) return targetItem.cost;
    if (targetItem.quantity && targetItem.total) {
      return Number((+targetItem.total / +targetItem.quantity).toFixed(2));
    }
    return +item.cost ?? 0;
  };

  const invoiceItem: InvoiceItem = {
    ...targetItem,
    index: targetItem.index,
    id: item.id,
    name: item.name,
    cost: getCost(),
    oldCost: +item.cost ?? 0,
    quantity:
      isOrWasOpenItem || +targetItem.quantity ? +targetItem.quantity : 0,
    total: isOrWasOpenItem || +targetItem.quantity ? +targetItem.total : 0,
    isNew: isNew,
    isFee: item.isFee,
    type: item.type,
    size: item.size,
    unit: item.unit,
    variety: item.variety,
    errorCost: "",
    errorQuantity: "",
    errorTotal: "",
    changedProperty: "",
    isOpenItem: false,
    wasOpenItem: isOrWasOpenItem,
    nameChanged: false,
    autoTotal: false,
    autoQuantity: false,
    invoicePage: targetItem.invoicePage,
    uuid: targetItem.uuid,
    hasError: false,
  };

  setResolvingItems((prev) => {
    const newResolvingItems = [...prev];
    newResolvingItems[idx] = invoiceItem;
    return newResolvingItems;
  });
};
