import { AuthClaims } from "redux/auth/types";
import { User } from "redux/users/types";

export function getUnresolvedCount(
  authClaims: AuthClaims | undefined,
  isForSupervisorFilter: boolean,
  user: User | undefined
) {
  return isForSupervisorFilter
    ? user?.invoicesCount?.unresolvedForSupervisor ?? 0
    : authClaims?.admin
      ? user?.invoicesCount?.unresolved ?? 0
      : !authClaims?.supervisor && !authClaims?.headDataManager
        ? user?.invoicesCount?.unresolvedDataManager ?? 0
        : user?.invoicesCount?.unresolvedNonAdmin ?? 0;
}
