import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
// COMPONENTS
import React, {
  lazy,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import accessLevelsActions from "redux/access-levels/actions";
// REDUX
import { useAppDispatch, useAppSelector } from "redux/hooks";
import supplierActions from "redux/suppliers/actions";
import "./index.css";
import CheckingPriorityList from "components/invoices/checking-priority-list";
import PriorityList from "components/invoices/priority-list";
import UsersList from "components/invoices/users-list";
import { DataLabelingContext, useDataLabelingData } from "pages/data-labeling";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { User } from "redux/users/types";
import { claims, hasAccess } from "../../permissions";

import { ItemRenderer } from "@blueprintjs/select";
import { getUnresolvedCount } from "components/invoices/count.helpers";
import TableView from "components/invoices/invoices-table-view";
import { UseSearchContext, useSearch } from "layouts";
import _ from "lodash";
import { UserListRef } from "redux/types";
import { getUserName } from "redux/users/helpers";
import { LOCALES } from "utils/constants";
import { getInvoice } from "../../services/invoice";
import useMergeInvoices from "./hooks/useMergeInvoices";
const DynamicFilters = lazy(() => import("components/dynamic-filter"));
const InvoicesMetric = lazy(() => import("components/invoices/metric"));

type ContextType = UseSearchContext & {
  userIdContext: string;
  userNameContext: string;
  tabContext: string;
  setTabContext: React.Dispatch<React.SetStateAction<string>>;
  setModificationOutlet: React.Dispatch<React.SetStateAction<string>>;
};

function Invoices({
  setModification,
}: {
  setModification?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { userIdParam } = useParams<{ userIdParam: string }>();
  const { searchProps, setSearchProps } = useSearch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users.users);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const isForSupervisorFilter = useAppSelector(
    (state) => state.invoices.isForSupervisorFilter
  );
  const loadAllUsers = useAppSelector((state) => state.settings.loadAllUsers);

  const { setModificationContext } = useDataLabelingData();
  const userName = useMemo(
    () => (userIdParam ? getUserName(userIdParam) : ""),
    [userIdParam]
  );
  const userId = useMemo(() => (userIdParam ? userIdParam : ""), [userIdParam]);
  const [advancedFilteredUsers, setAdvancedFilteredUsers] = useState<User[]>(
    []
  );

  const [priorityList, setPriorityList] = useState<User[]>([]);
  const [checkingPriorityList, setCheckingPriorityList] = useState<User[]>([]);
  const [tabContext, setTabContext] = useState<string>("");
  const [initialLoad, setInitialLoad] = useState(true);

  const { modification } = useContext(DataLabelingContext);
  const [isTableView, setTableView] = useState(false);
  const [filterByLocale, setFilterByLocale] = useState<Set<string>>(
    new Set<string>().add("en").add("no").add("hr")
  );
  const usersListRef = useRef<UserListRef>(null);
  const priorityListRef = useRef<UserListRef>(null);
  const checkingPriorityListRef = useRef<UserListRef>(null);

  const usersFilteredByLocale = useMemo(
    () =>
      users
        .filter((user) => {
          if (loadAllUsers) return true;
          return (
            user.active && !user.demo && !user.organization && !user.employee
          );
        })
        .filter((user) => filterByLocale.has(user.language)),
    [users, filterByLocale, loadAllUsers]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const params: Record<string, string> = useMemo(() => {
    let paramObj = {};
    for (const [key, value] of searchParams) {
      paramObj = { ...paramObj, [key]: value };
    }

    return paramObj;
  }, [searchParams]);

  const { loadingInvoice } = useMergeInvoices({
    userId,
    modification,
  });
  const setModificationUsed = setModification
    ? setModification
    : setModificationContext;

  useEffect(() => {
    dispatch(accessLevelsActions.SUBSCRIBE_TO_ACCESS_LEVELS());
    dispatch(supplierActions.SUBSCRIBE_TO_SUPPLIERS());

    return () => {
      dispatch(accessLevelsActions.UNSUBSCRIBE_FROM_ACCESS_LEVELS());
      dispatch(supplierActions.UNSUBSCRIBE_FROM_SUPPLIERS());
    };
  }, [dispatch]);

  function sortCheckingFromOldest(a: User, b: User): number {
    if (
      typeof a.invoiceOldestDeliveryDate?.toMillis === "function" &&
      typeof b.invoiceOldestDeliveryDate?.toMillis === "function"
    ) {
      const aMilisDeliveryDate = a.invoiceOldestDeliveryDate?.toMillis();
      const bMilisDeliveryDate = b.invoiceOldestDeliveryDate?.toMillis();
      if (aMilisDeliveryDate && bMilisDeliveryDate) {
        return aMilisDeliveryDate - bMilisDeliveryDate;
      }
    }
    if (
      typeof a.checkingOldestInvoiceDate?.toMillis !== "function" ||
      typeof b.checkingOldestInvoiceDate?.toMillis !== "function"
    )
      return 0;
    const aMilis = a.checkingOldestInvoiceDate?.toMillis();
    const bMilis = b.checkingOldestInvoiceDate?.toMillis();
    if (aMilis && bMilis) {
      return aMilis - bMilis;
    }
    return 0;
  }

  function getInvoices(id: string, isChecking: boolean) {
    const user = users?.find((user) => user.id === id);
    if (user) {
      if (user.isInvoiceVotingEnabled) {
        setModificationUsed("dataVoting");
      } else {
        setModificationUsed("dataLabeling");
      }
      setTabContext(isChecking ? "resolved" : "unresolved");
      navigate(`/data-labeling/invoices/${id}`);
    }
  }

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    const keys = Object.keys(params);
    // Set the tab param when search param is present
    if (userId && keys.includes("search")) {
      const newParam: Record<string, string> = { ...params, tab: tabContext };
      if (tabContext === "resolved") {
        if (!keys.includes("sub")) newParam.sub = "unchecked";
      } else {
        if (keys.includes("sub")) delete newParam.sub;
      }
      setSearchParams(newParam);
    }
  }, [userId, tabContext, params]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (userIdParam && params.tab && initialLoad) {
      // If invoiceId is present, get the invoice and set the correct tab context
      if (params.invoiceId) {
        getInvoice(userIdParam, params.invoiceId).then((res) => {
          if (!res.error && res.data) {
            const state = res.data.state;
            setTabContext(state);
            if (state === "resolved") {
              const newParam: Record<string, string> = { ...params };
              newParam.sub = "unchecked,checked";
              setSearchParams(newParam);
            }
          }
        });
      } else {
        setTabContext(params.tab);
      }
      setInitialLoad(false);
    }
  }, [params]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (usersFilteredByLocale.length) {
      const usersToFilter = [...usersFilteredByLocale]
        .sort((a, b) => a.name?.localeCompare(b.name))
        .map((user) => ({
          ...user,
          resolvingUsers: users.filter(
            (u) => u.resolvingUserId === user.id && u.active === true
          ),
        }));

      const usersToPrioritize = usersToFilter
        .filter(
          (user) =>
            getUnresolvedCount(authClaims, isForSupervisorFilter, user) > 0 &&
            !(
              Number(user.accessLevel) === 0 ||
              Number(user.accessLevel) === 1 ||
              !!user.demo
            ) &&
            user.active === true
        )
        .sort((a, b) => {
          const priorityA = a.priority ?? 1;
          const priorityB = b.priority ?? 1;
          if (priorityA === priorityB) {
            return (
              getUnresolvedCount(authClaims, isForSupervisorFilter, b) -
              getUnresolvedCount(authClaims, isForSupervisorFilter, a)
            );
          }
          return priorityB - priorityA;
        });

      const checkingPriority = usersToFilter
        .filter(
          (user) =>
            (authClaims?.admin
              ? user.invoicesCount?.unchecked ?? 0
              : user.invoicesCount?.uncheckedNonAdmin ?? 0) &&
            !(user.accessLevel === 0 || !user.accessLevel || !!user.demo) &&
            user.active === true
        )
        .sort((a, b) => {
          const priorityA = a.priority ?? 1;
          const priorityB = b.priority ?? 1;
          if (priorityA === priorityB) {
            return (
              (authClaims?.admin
                ? b.invoicesCount?.unchecked ?? 0
                : b.invoicesCount?.uncheckedNonAdmin ?? 0) -
              (authClaims?.admin
                ? a.invoicesCount?.unchecked ?? 0
                : a.invoicesCount?.uncheckedNonAdmin ?? 0)
            );
          }
          return priorityB - priorityA;
        })
        .map((user) => ({
          ...user,
          checkingUsers: users.filter((u) => u.checkingUserId === user.id),
        }));

      const usersByPriority = _.groupBy(usersToPrioritize, "priority");
      const checkingUsersByPriority = _.groupBy(checkingPriority, "priority");

      for (const key in checkingUsersByPriority) {
        checkingUsersByPriority[key] = checkingUsersByPriority[key]?.sort(
          sortCheckingFromOldest
        );
      }

      const sortedUsersByPriorityKeys = Object.keys(usersByPriority).sort(
        (a, b) => Number(b) - Number(a)
      );
      const sortedCheckingPriorityKeys = Object.keys(
        checkingUsersByPriority
      ).sort((a, b) => Number(b) - Number(a));

      const finalUsersToPrioritize = sortedUsersByPriorityKeys.flatMap(
        (key) => usersByPriority[key] ?? []
      );
      const finalCheckingPriority = sortedCheckingPriorityKeys.flatMap(
        (key) => checkingUsersByPriority[key] ?? []
      );

      setPriorityList(
        !authClaims?.admin &&
          !authClaims?.supervisor &&
          !authClaims?.headDataManager
          ? finalUsersToPrioritize.filter(
              (u) =>
                !u.isExcludedFromPriorityListAtInvoices && !u.isNewAtInvoices
            )
          : finalUsersToPrioritize
      );

      setCheckingPriorityList(finalCheckingPriority);
    } else {
      setPriorityList([]);
      setCheckingPriorityList([]);
    }
  }, [
    usersFilteredByLocale,
    users,
    authClaims?.admin,
    authClaims?.supervisor,
    authClaims?.headDataManager,
  ]);

  function handleFilterByLocale(filter: string) {
    const newFilters = new Set(filterByLocale);
    if (newFilters.has(filter) && newFilters.size > 1) {
      newFilters.delete(filter);
    } else {
      newFilters.add(filter);
    }
    setFilterByLocale(newFilters);
  }

  const localeRenderer: ItemRenderer<{ name: string; value: string }> = (
    locale,
    { handleClick, modifiers, handleFocus }
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={locale.value}
        text={`${locale.name} (${locale.value})`}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        selected={filterByLocale.has(locale.value)}
        shouldDismissPopover={false}
      />
    );
  };

  const handleExpandAllUsersLists = () => {
    priorityListRef.current?.handleOpenList();
    checkingPriorityListRef.current?.handleOpenList();
    usersListRef.current?.handleOpenList();
  };

  const handleContractAllUsersLists = () => {
    priorityListRef.current?.handleCloseList();
    checkingPriorityListRef.current?.handleCloseList();
    usersListRef.current?.handleCloseList();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          text={isTableView ? "Normal View" : "Table View"}
          onClick={() => setTableView((prev) => !prev)}
          icon={isTableView ? "circle-arrow-left" : "panel-table"}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <span
              style={{
                color: "#bb86fc",
                cursor: "pointer",
              }}
              onClick={handleExpandAllUsersLists}
            >
              Expand All
            </span>
            <span>|</span>
            <span
              style={{
                color: "#bb86fc",
                cursor: "pointer",
              }}
              onClick={handleContractAllUsersLists}
            >
              Contract All
            </span>
          </div>
          <Popover
            placement="left"
            content={
              <Menu>
                {LOCALES.map((locale, idx) =>
                  localeRenderer(locale, {
                    index: idx,
                    handleClick: () => handleFilterByLocale(locale.value),
                    modifiers: {
                      active: filterByLocale.has(locale.value),
                      disabled: false,
                      matchesPredicate: false,
                    },
                    query: "",
                  })
                )}
              </Menu>
            }
          >
            <Button icon="translate" text={`Filter by Locale`} />
          </Popover>
        </div>
      </div>
      {isTableView ? (
        <TableView setModification={setModificationUsed} />
      ) : (
        <>
          <InvoicesMetric priorityLists={priorityList} />
          <PriorityList
            priorityList={priorityList}
            username={userName}
            getInvoices={getInvoices}
            loadingInvoice={loadingInvoice}
            ref={priorityListRef}
          />
          {hasAccess(authClaims, claims.checkingPriorityList) && (
            <CheckingPriorityList
              checkingPriorityList={checkingPriorityList}
              username={userName}
              getInvoices={getInvoices}
              loadingInvoice={loadingInvoice}
              ref={checkingPriorityListRef}
            />
          )}
          <DynamicFilters
            module="invoices-users"
            advancedFilterModuleTitle="invoices"
            items={usersFilteredByLocale}
            filteredItems={advancedFilteredUsers}
            setFilteredItems={setAdvancedFilteredUsers}
          />
          <UsersList
            advancedFilteredUsers={advancedFilteredUsers}
            getInvoices={getInvoices}
            username={userName}
            loadingInvoice={loadingInvoice}
            ref={usersListRef}
          />
          {!userName ? (
            "Please select a user."
          ) : (
            <Outlet
              context={{
                searchProps,
                setSearchProps,
                userIdContext: userId,
                userNameContext: userName,
                tabContext: tabContext,
                setTabContext: setTabContext,
                setModificationOutlet: setModificationUsed,
              }}
            />
          )}
        </>
      )}
    </>
  );
}
export function useInvoiceData() {
  return useOutletContext<ContextType>();
}

export default Invoices;
