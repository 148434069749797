import {
  Button,
  DefaultPopoverTargetHTMLProps,
  MenuItem,
  PopoverProps,
} from "@blueprintjs/core";
import { ItemPredicate, Select } from "@blueprintjs/select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getUserLanguage } from "redux/users/helpers";
import { NoResults } from "utils/common";
import { ITEM_TYPES } from "utils/constants";

type Props = {
  isForType: boolean;
  selectedUserId: string;
  selectedType: string;
  selectedVariety: string;
  setSelectedType: React.Dispatch<React.SetStateAction<string>>;
  setSelectedVariety: React.Dispatch<React.SetStateAction<string>>;
  popoverProps?: Partial<
    Omit<
      PopoverProps<DefaultPopoverTargetHTMLProps>,
      "fill" | "content" | "defaultIsOpen" | "renderTarget"
    >
  >;
  isDisabled?: boolean;
  menuProps?: React.HTMLAttributes<HTMLUListElement>;
  tabIndex?: number;
  fill?: boolean;
  fillButton?: boolean;
  isForFiltering?: boolean;
  isRenderAllType?: boolean; // for handle when want to filtering all type, including recipe and POS Item
};

function SelectTypeVariety({
  isForType,
  selectedUserId,
  selectedType,
  selectedVariety,
  setSelectedType,
  setSelectedVariety,
  isDisabled,
  tabIndex,
  fill,
  fillButton,
  menuProps,
  popoverProps,
  isForFiltering = true,
  isRenderAllType,
}: Props) {
  const [varietyList, setVarietyList] = useState<string[]>([]);

  useEffect(() => {
    if (selectedType === "") return;
    setVarietyList(typeVariety[selectedType]);
  }, [selectedType]);

  const typeVariety = useMemo(() => {
    const lang = getUserLanguage(selectedUserId);
    // Default to Norwegian if type for the language is not found
    const itemType = ITEM_TYPES[lang] ?? ITEM_TYPES["no"];
    return isRenderAllType
      ? {
          ...itemType,
          Recipe: ["Menu Item", "Prebatch"],
          "POS Item": ["POS Item"],
        }
      : itemType;
  }, [selectedUserId, isRenderAllType]);

  const itemTypes = useMemo(() => {
    return Object.keys(typeVariety).sort();
  }, [typeVariety]);

  const selectItems = useMemo(() => {
    return isForFiltering
      ? [
          isForType ? "Select Type" : "Select variety",
          ...(isForType ? [...itemTypes] : [...varietyList]),
        ]
      : [...(isForType ? itemTypes : varietyList)];
  }, [isForFiltering, isForType, itemTypes, varietyList]);

  const filterItemTypeVariety: ItemPredicate<string> = useCallback(
    (query, target) => {
      if (target) {
        const normalizedQuery = query.toLowerCase();
        const normalizedTarget = target.toLowerCase();
        return normalizedTarget.indexOf(normalizedQuery) >= 0;
      }
      return false;
    },
    []
  );

  return (
    <Select
      items={selectItems}
      fill={fill}
      disabled={isDisabled}
      onItemSelect={(item) => {
        const selectedItem =
          item === "Select Type" || item === "Select variety" ? "" : item;
        if (isForType) {
          setSelectedVariety("");
          setSelectedType(selectedItem);
        } else return setSelectedVariety(selectedItem);
      }}
      itemPredicate={filterItemTypeVariety}
      noResults={<NoResults />}
      popoverProps={popoverProps}
      filterable
      itemRenderer={(
        typeVarietyValue: string,
        { handleClick, handleFocus, modifiers }
      ) => {
        return (
          <MenuItem
            key={`${isForType ? "type" : "variety"}-` + typeVarietyValue}
            active={modifiers.active}
            onClick={handleClick}
            onFocus={handleFocus}
            roleStructure="listoption"
            selected={
              isForType
                ? typeVarietyValue === selectedType
                : typeVarietyValue === selectedVariety
            }
            text={typeVarietyValue}
          />
        );
      }}
      menuProps={menuProps}
    >
      <Button
        text={
          isForType
            ? selectedType || "Select Type"
            : selectedVariety || "Select variety"
        }
        disabled={isForType ? undefined : !selectedType}
        alignText="left"
        rightIcon="double-caret-vertical"
        fill={fillButton}
        tabIndex={tabIndex}
      />
    </Select>
  );
}

export default SelectTypeVariety;
