import {
  Button,
  ContextMenu,
  HotkeyConfig,
  HotkeysProvider,
  HotkeysTarget2,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import { NumericInput, Popover } from "@blueprintjs/core";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import actionsOcr from "redux/ocr/actions";
import { Box, Selection, TextAnnotation, Vertex } from "redux/ocr/types";
import "./index.css";
import alertActions from "redux/alert/actions";
import { formatOcrNumber } from "utils/common";

import { Loader } from "@stockifi/shared";

import CircularSlider from "@fseehawer/react-circular-slider";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import { getFileExtension } from "components/integration-monitor/helpers";
import { initialHighlightColorSettings } from "components/settings/invoices/highlight-color/constants";
import { httpsCallable } from "firebase/functions";
import _ from "lodash";
import { getFileNameFromUrl } from "services/ap-transactions";
import { functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { v4 } from "uuid";
import InvoiceFloatingButtons from "../invoice-floating-buttons";
import { ResolvedSelection } from "../invoice/helpers";
import { selections } from "./constants";
import FloatingButtonCanvas from "./floating-name-canvas";

export type BulkAddedOpenItem = {
  name: string;
  qty: number;
  total: number;
  nameSelection: Selection;
  qtySelection: Selection;
  totalSelection: Selection;
  multiplierSelection?: Selection;
};
type ItemToMerge = {
  name: string;
  qty: number;
  total: number;
  nameSelection: Selection;
  multiplierSelection: Selection | undefined;
  qtySelection: Selection;
  totalSelection: Selection;
};

type Prop = {
  imageUrl: string;
  onTextSelected?: (
    value: string,
    type: string,
    selection: Selection,
    multiplier?: number,
    values?: string[],
    autoQty?: { value: string; selection: Selection },
    autoTotal?: { value: string; selection: Selection }
  ) => void;
  userId: string;
  imageOrientation: number;
  ocrOrientation: number;
  saveOrientationFunction?: (
    file: string,
    orientation: number,
    type?: "img" | "ocr" | "both",
    ocrOrientation?: number
  ) => void;
  openItemCreate?: boolean;
  onAutoDetectTotal?: (value: string, selection: Selection) => void;
  onAutoDetectQuantity?: (value: string, selection: Selection) => void;
  invoiceOcrInFocus?: Selection[];
  module: string;
  itemOcrSelections?: ResolvedSelection[];
  bulkAddOpenItems?: (items: BulkAddedOpenItem[]) => void;
  invoiceId: string;
  expandItems: boolean;
};

function InvoiceOCR({
  imageUrl,
  onTextSelected,
  userId,
  imageOrientation,
  saveOrientationFunction,
  openItemCreate,
  onAutoDetectTotal,
  onAutoDetectQuantity,
  invoiceOcrInFocus,
  module,
  itemOcrSelections,
  bulkAddOpenItems,
  ocrOrientation,
  invoiceId,
  expandItems,
}: Prop) {
  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const [ctxP, setCtxP] = useState<CanvasRenderingContext2D>();
  const [ctxImage, setCtxImage] = useState<CanvasRenderingContext2D>();
  const [ctxSelect, setCtxSelect] = useState<CanvasRenderingContext2D>();
  const [imageRatio, setImageRatio] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState<HTMLImageElement>();
  const [imageLoader, setImageLoader] = useState(true);
  const [enableDrawing, setEnableDrawing] = useState(false);
  const [startDrawingPoints, setStartDrawingPoints] = useState<Vertex>();
  const [selectedWordsIndex, setSelectedWordsIndex] = useState<Set<number>>(
    new Set<number>()
  );
  const [selectedTotalIndex, setSelectedTotalIndex] = useState<Set<number>>(
    new Set<number>()
  );
  const [autoMultipliersWordsIndex, setAutoMultipliersWordsIndex] = useState<
    Set<number>
  >(new Set<number>());
  const [selectedQtyIndex, setSelectedQtyIndex] = useState<Set<number>>(
    new Set()
  );

  const [totalColumnMinx, setTotalColumnMinx] = useState<number>(-1);
  const [totalColumnMaxx, setTotalColumnMaxx] = useState<number>(-1);
  const [qtyColumnMinx, setQtyColumnMinx] = useState<number>(-1);
  const [qtyColumnMaxx, setQtyColumnMaxx] = useState<number>(-1);

  const [itemLineY, setItemLineY] = useState({ min: -1, max: -1 });

  const [textAnnotations, setTextAnnotations] = useState<TextAnnotation[]>([]);
  const [textAnnotationsBox, setTextAnnotationsBox] = useState<Box[]>([]);
  const [imageRotation, setImageRotation] = useState(0);
  const [ocrRotation, setOcrRotation] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasFloatingNameRef = useRef<HTMLCanvasElement>(null);
  const canvasFloatingMultiplierRef = useRef<HTMLCanvasElement>(null);
  const canvasFloatingQtyRef = useRef<HTMLCanvasElement>(null);
  const canvasFloatingTotalRef = useRef<HTMLCanvasElement>(null);
  const canvasFloatingItemsRef = useRef<HTMLCanvasElement>(null);
  const canvasImageRef = useRef<HTMLCanvasElement>(null);
  const canvasParagraphRef = useRef<HTMLCanvasElement>(null);
  const canvasSelectRef = useRef<HTMLCanvasElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  // For Image + OCR rotator
  const [lockOcr, setLockOcr] = useState(false);
  const [lockedRotation, setLockedRotation] = useState<number | undefined>();
  const [lockedAnnotationBox, setLockedAnnotationBox] = useState<
    Box[] | undefined
  >();

  const dispatch = useAppDispatch();
  const ocrData = useAppSelector((state) => state.invoices.ocrData);
  const loadingOcrData = useAppSelector(
    (state) => state.invoices.loadingOcrData
  );
  const isFloatingButtonsVisible = useAppSelector(
    (state) => state.invoices.isFloatingButtonsVisible
  );
  const highlightColorSettings = useAppSelector(
    (state) => state.settings.invoiceHighlightColors
  );
  const isLoadingUpdateInvoice = useAppSelector(
    (state) => state.invoices.loadingSave
  );

  const [clicksInFocus, setClicksInFocus] = useState<Set<number>>(
    new Set<number>()
  );
  const [selectedItemBoxIndexes, setSelectedItemBoxIndexes] = useState<{
    selectedIndex: Set<number>;
    resolvedItem: Set<number>;
  }>({
    selectedIndex: new Set<number>(),
    resolvedItem: new Set<number>(),
  });
  const [preselectItemBoxIndexes, setPreselectItemBoxIndexes] = useState<
    Set<number>
  >(new Set<number>());
  const [isHighlightOn, setIshighlighOn] = useState(true);

  // FOR FLOATING BUTTONS
  const [ctxFloatingName, setCtxFloatingName] =
    useState<CanvasRenderingContext2D>();
  const [ctxFloatingMultiplier, setCtxFloatingMultiplier] =
    useState<CanvasRenderingContext2D>();
  const [ctxFloatingQty, setCtxFloatingQty] =
    useState<CanvasRenderingContext2D>();
  const [ctxFloatingTotal, setCtxFloatingTotal] =
    useState<CanvasRenderingContext2D>();
  const [ctxFloatingItems, setCtxFloatingItems] =
    useState<CanvasRenderingContext2D>();
  const [initialRenderFloatingButtons, setInitialRenderFloatingButtons] =
    useState<boolean>(true);
  const [selectedFloatingButton, setSelectedFloatingButton] =
    useState<string>("Names");
  const [nameMinX, setNameMinX] = useState<number>(-1);
  const [nameMaxX, setNameMaxX] = useState<number>(-1);
  const [multiplierMinX, setMultiplierMinX] = useState<number>(-1);
  const [multiplierMaxX, setMultiplierMaxX] = useState<number>(-1);
  const [qtyMinX, setQtyMinX] = useState<number>(-1);
  const [qtyMaxX, setQtyMaxX] = useState<number>(-1);
  const [totalMinX, setTotalMinX] = useState<number>(-1);
  const [totalMaxX, setTotalMaxX] = useState<number>(-1);
  const [firstSelectionMinY, setFirstSelectionMinY] = useState(-1);
  const [allSelectedYRange, setAllSelectedYRange] = useState<
    {
      min: number;
      max: number;
    }[]
  >([]);
  const [addSelectedRowMinY, setAddSelectedRowMinY] = useState(-1);
  const [deselectRowMinY, setDeselectRowMinY] = useState(-1);
  const [previousImgDimensions, setPreviousImgDimensions] = useState({
    h: 0,
    w: 0,
  });
  const [isAutoDetectMultipliersOn, setIsAutoDetectMultipliersOn] =
    useState(false);
  const [isDragging, setIsDragging] = useState(true);

  const toggleSelected = (btn: string) => {
    if (selectedFloatingButton === btn) {
      setSelectedFloatingButton("");
    } else {
      setSelectedFloatingButton(btn);
    }
  };

  const handleAutoDetectMultipliersSwitch = () => {
    setIsAutoDetectMultipliersOn(!isAutoDetectMultipliersOn);
  };

  const getSelectedIndex = (
    textAnnotations: TextAnnotation[],
    selections: (ResolvedSelection | Selection)[]
  ) => {
    const textList = new Set<string>();
    const isResolvingMap = new Map();
    for (const click of selections) {
      for (const property in click.boundingPoly) {
        textList.add(click.texts[property]);
        const vertices = click.boundingPoly[property];
        const key = vertices
          .map((vertex) => `${vertex.x},${vertex.y}`)
          .join("|");
        if ("isResolvedItem" in click)
          isResolvingMap.set(key, click.isResolvedItem);
        else isResolvingMap.set(key, null);
      }
    }

    const selectedIndex = new Set<number>();
    const resolvedItem = new Set<number>();

    textAnnotations.forEach((annotation, index) => {
      if (textList.has(annotation.description)) {
        const vertices = annotation.boundingPoly.vertices;
        const key = vertices
          .map((vertex) => `${vertex.x},${vertex.y}`)
          .join("|");

        if (isResolvingMap.has(key)) {
          selectedIndex.add(index);
          if (isResolvingMap.get(key)) {
            resolvedItem.add(index);
          }
        }
      }
    });

    return { resolvedItem, selectedIndex };
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    if (itemOcrSelections && textAnnotations) {
      const clicks = itemOcrSelections.filter(
        (click) => click.imageUrl === imageUrl
      );

      if (clicks.length < 1) {
        setSelectedItemBoxIndexes({
          selectedIndex: new Set<number>(),
          resolvedItem: new Set<number>(),
        });
      } else {
        const selectedIndex = getSelectedIndex(textAnnotations, clicks);
        setSelectedItemBoxIndexes({
          selectedIndex: selectedIndex.selectedIndex,
          resolvedItem: selectedIndex.resolvedItem,
        });
      }
    }
  }, [imageUrl, textAnnotations, itemOcrSelections]);

  // set index of words that are in focus
  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    if (invoiceOcrInFocus && textAnnotations) {
      const clicks = invoiceOcrInFocus.filter(
        (click) => click.imageUrl === imageUrl
      );
      if (clicks.length < 0) {
        setClicksInFocus(new Set<number>());
      } else {
        const selectedIndex = getSelectedIndex(textAnnotations, clicks);
        setClicksInFocus(selectedIndex.selectedIndex);
      }
    }
  }, [imageUrl, textAnnotations, invoiceOcrInFocus]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    if (allSelectedYRange.length === 0) {
      highlightSelectedRowColumnItems([]);
      setPreselectItemBoxIndexes(new Set<number>());
      return;
    }
    highlightSelectedRowColumnItems(allSelectedYRange);
    if (!isAutoDetectMultipliersOn) {
      // reset auto multiplier words index when switch turned off
      setAutoMultipliersWordsIndex(new Set<number>());
    }
    const preselectItem = getPreselectedItem();
    const preselectSelection: Selection[] = [];
    for (const x of preselectItem) {
      if (x.nameSelection) preselectSelection.push(x.nameSelection);
      if (x.multiplierSelection) preselectSelection.push(x.multiplierSelection);
      if (x.qtySelection) preselectSelection.push(x.qtySelection);
      if (x.totalSelection) preselectSelection.push(x.totalSelection);
    }
    const preselectIndex = getSelectedIndex(
      textAnnotations,
      preselectSelection
    );
    setPreselectItemBoxIndexes(preselectIndex.selectedIndex);
  }, [allSelectedYRange, isAutoDetectMultipliersOn]);

  useEffect(() => {
    if (nameMinX === -1 || nameMaxX === -1) return;
    setAllSelectedYRange([]);
    setDeselectRowMinY(-1);
    setFirstSelectionMinY(-1);
    setAddSelectedRowMinY(-1);
  }, [nameMinX, nameMaxX]);

  useEffect(() => {
    setImageRotation(imageOrientation);
    return () => {
      setImageRotation(0);
    };
  }, [imageOrientation]);

  useEffect(() => {
    setOcrRotation(ocrOrientation);
    return () => {
      setOcrRotation(0);
    };
  }, [ocrOrientation]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    // Handle slider rotator on mouse up
    if (
      !isDragging &&
      (imageRotation !== imageOrientation || ocrRotation !== ocrOrientation)
    ) {
      saveOrientationFunction?.(imageUrl, imageRotation, "both", ocrRotation);
    }
  }, [isDragging]);

  useEffect(() => {
    setImageLoader(true);
    setSelectedWordsIndex(new Set<number>());
    setSelectedTotalIndex(new Set<number>());
    setSelectedQtyIndex(new Set<number>());
    setAutoMultipliersWordsIndex(new Set<number>());
    dispatch(actions.GET_OCR_DATA({ userId, imageUrl }));
  }, [dispatch, imageUrl, userId]);

  useEffect(() => {
    const data:
      | {
          fileName: string;
          textAnnotations: TextAnnotation[];
        }[]
      | undefined = ocrData?.filter((obj) => obj.fileName === imageUrl);
    if (!data || !data.length || !data[0]) {
      setTextAnnotations([]);
      return;
    }
    const updatedTextAnnotations = data[0].textAnnotations.map((annotation) => {
      const { boundingPoly } = annotation;
      const { vertices } = boundingPoly;
      if (ocrRotation === 90 || ocrRotation === 270) {
        const updatedVertices = vertices.map((vertice) => {
          const xUpdate = vertice.y;
          const yUpdate = vertice.x;
          return { x: xUpdate, y: yUpdate };
        });
        return {
          ...annotation,
          boundingPoly: {
            ...annotation.boundingPoly,
            vertices: updatedVertices,
          },
        };
      } else return annotation;
    });
    setTextAnnotations(updatedTextAnnotations);
  }, [imageUrl, ocrData, ocrRotation]);

  const rotate = useCallback(
    (
      x: number,
      y: number,
      antiClockwise = false,
      angle: number,
      xCenter = 0,
      yCenter = 0
    ) => {
      let radians = 0;
      if (antiClockwise) {
        radians = (Math.PI / 180) * angle;
      } else {
        radians = (Math.PI / -180) * angle;
      }
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      const x_rotated = cos * (x - xCenter) + sin * (y - yCenter) + xCenter;
      const y_rotated = cos * (y - yCenter) - sin * (x - xCenter) + yCenter;
      return [x_rotated, y_rotated];
    },
    []
  );

  const handleResize = useCallback(() => {
    // set canvases
    const canvasImage = canvasImageRef.current;
    if (canvasImage == null) return;

    const canvas = canvasRef.current;
    if (canvas == null) return;

    const pCanvas = canvasParagraphRef.current;
    if (pCanvas == null) return;

    const floatingNameCanvas = canvasFloatingNameRef.current;
    if (floatingNameCanvas == null) return;

    const floatingMultiplierCanvas = canvasFloatingMultiplierRef.current;
    if (floatingMultiplierCanvas == null) return;

    const floatingQtyCanvas = canvasFloatingQtyRef.current;
    if (floatingQtyCanvas == null) return;

    const floatingTotalCanvas = canvasFloatingTotalRef.current;
    if (floatingTotalCanvas == null) return;

    const floatingItemsCanvas = canvasFloatingItemsRef.current;
    if (floatingItemsCanvas == null) return;

    const canvasSelect = canvasSelectRef.current;
    if (canvasSelect == null) return;

    const ctxSelect = canvasSelect.getContext("2d");
    if (ctxSelect == null) return;

    const ctx = canvas.getContext("2d");
    if (ctx == null) return;

    const pCtx = pCanvas.getContext("2d");
    if (pCtx == null) return;

    const nameBtnCtx = floatingNameCanvas.getContext("2d");
    if (nameBtnCtx == null) return;

    const multiplierBtnCtx = floatingMultiplierCanvas.getContext("2d");
    if (multiplierBtnCtx == null) return;

    const qtyBtnCtx = floatingQtyCanvas.getContext("2d");
    if (qtyBtnCtx == null) return;

    const totalBtnCtx = floatingTotalCanvas.getContext("2d");
    if (totalBtnCtx == null) return;

    const itemsBtnCtx = floatingItemsCanvas.getContext("2d");
    if (itemsBtnCtx == null) return;

    const ctxImage = canvasImage.getContext("2d");
    if (ctxImage == null) return;

    if (!backgroundImage) return;
    ctx.canvas.width = divRef.current ? divRef.current.offsetWidth : 0;
    const ratio = canvas.width / backgroundImage.width;
    ctx.canvas.height = backgroundImage.height * ratio;

    setCtx(ctx);
    setImageRatio(ratio);

    ctxSelect.canvas.width = ctx.canvas.width;
    ctxSelect.canvas.height = ctx.canvas.height;

    ctxSelect.globalAlpha = 0.2;
    setCtxSelect(ctxSelect);

    ctxImage.canvas.width = ctx.canvas.width;
    ctxImage.canvas.height = ctx.canvas.height;
    setCtxImage(ctxImage);

    pCtx.canvas.width = ctx.canvas.width;
    pCtx.canvas.height = ctx.canvas.height;
    setCtxP(pCtx);

    // PREVENT RERENDER WHEN IMAGE CHANGES
    if (initialRenderFloatingButtons) {
      nameBtnCtx.canvas.width = 3400;
      nameBtnCtx.canvas.height = 4730;
      setCtxFloatingName(nameBtnCtx);

      qtyBtnCtx.canvas.width = 3400;
      qtyBtnCtx.canvas.height = 4730;
      setCtxFloatingQty(qtyBtnCtx);

      multiplierBtnCtx.canvas.width = 3400;
      multiplierBtnCtx.canvas.height = 4730;
      setCtxFloatingMultiplier(multiplierBtnCtx);

      totalBtnCtx.canvas.width = 3400;
      totalBtnCtx.canvas.height = 4730;
      setCtxFloatingTotal(totalBtnCtx);

      itemsBtnCtx.canvas.width = 3400;
      itemsBtnCtx.canvas.height = 4730;
      setCtxFloatingItems(itemsBtnCtx);

      setInitialRenderFloatingButtons(false);
    }

    ctxImage.drawImage(
      backgroundImage,
      0,
      0,
      backgroundImage.width * ratio,
      backgroundImage.height * ratio
    );
    ctxImage.restore();
  }, [backgroundImage, initialRenderFloatingButtons]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      divRef.current &&
      canvasImageRef.current &&
      canvasImageRef.current.height > 0
    ) {
      divRef.current.style.height =
        canvasImageRef.current.height.toString() + "px";
      divRef.current.style.overflow = "hidden";
    }
  }, [divRef.current, canvasImageRef.current?.height]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    // rerender all boxes
    const background = new Image();
    background.src = imageUrl;
    background.onerror = () => {
      setImageLoader(false);
    };
    // Make sure the image is loaded first otherwise nothing will draw.
    background.onload = () => {
      setImageLoader(false);
      setBackgroundImage(background);

      if (
        !!background.height &&
        !!background.width &&
        (previousImgDimensions.h !== background.height ||
          previousImgDimensions.w !== background.width)
      ) {
        setPreviousImgDimensions({
          h: background.height,
          w: background.width,
        });
        setInitialRenderFloatingButtons(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      setBackgroundImage(undefined);
      setCtx(undefined);
      setImageRatio(1);
    };
  }, [imageUrl]);

  useEffect(() => {
    if (
      nameMinX === -1 ||
      nameMaxX === -1 ||
      qtyMinX === -1 ||
      qtyMaxX === -1 ||
      totalMinX === -1 ||
      totalMaxX === -1 ||
      !textAnnotationsBox.length ||
      !textAnnotations.length ||
      textAnnotations.length !== textAnnotationsBox.length
    )
      return;
    const filteredQuantities: Box[] = [];
    const filteredTotals: Box[] = [];
    textAnnotationsBox.forEach((box, i) => {
      const { xmin, xmax } = box;
      const qtyMinXUsed = qtyMinX < qtyMaxX ? qtyMinX : qtyMaxX;
      const qtyMaxXUsed = qtyMaxX > qtyMinX ? qtyMaxX : qtyMinX;
      const totalMinXUsed = totalMinX < totalMaxX ? totalMinX : totalMaxX;
      const totalMaxXUsed = totalMaxX > totalMinX ? totalMaxX : totalMinX;
      if (
        xmin > qtyMinXUsed - 10 &&
        xmax < qtyMaxXUsed + 10 &&
        textAnnotations[i].description &&
        !isNaN(Number(formatOcrNumber(textAnnotations[i].description, 2)))
      ) {
        filteredQuantities.push(box);
      }
      if (
        xmin > totalMinXUsed - 10 &&
        xmax < totalMaxXUsed + 10 &&
        textAnnotations[i].description &&
        !isNaN(Number(formatOcrNumber(textAnnotations[i].description, 2)))
      ) {
        filteredTotals.push(box);
      }
    });
    const getAverage = (minY: number, maxY: number) => (minY + maxY) / 2;

    const averageMinY = getAverage(
      Math.min(...filteredQuantities.map((x) => x.ymin)),
      Math.min(...filteredTotals.map((x) => x.ymin))
    );
    const avererateMaxY = getAverage(
      Math.max(...filteredQuantities.map((x) => x.ymax)),
      Math.max(...filteredTotals.map((x) => x.ymax))
    );
    const minY = averageMinY;
    const maxY = avererateMaxY;
    setAllSelectedYRange([{ min: minY, max: maxY }]);
  }, [
    nameMinX,
    nameMaxX,
    qtyMinX,
    qtyMaxX,
    totalMinX,
    totalMaxX,
    textAnnotationsBox,
    textAnnotations,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    if (divRef.current?.offsetWidth) handleResize();
  }, [divRef.current?.offsetWidth, handleResize]);

  function getValue(
    type: "total" | "quantity",
    _minX = -1,
    _maxX = -1,
    sIndex?: Set<number>,
    _minY = -1,
    _maxY = -1
  ): { value: string; sIndex: Set<number> } {
    if (!ctxP) return { value: "", sIndex: new Set<number>() };
    // find min and max y of all selected words
    let minY = -1;
    let maxY = -1;
    let minX = -1;
    let maxX = -1;

    const Y_MARGIN = 10;

    const tcMinX =
      _minX === -1
        ? type === "total"
          ? totalColumnMinx
          : qtyColumnMinx
        : _minX;
    const tcMaxX =
      _maxX === -1
        ? type === "total"
          ? totalColumnMaxx
          : qtyColumnMaxx
        : _maxX;

    // set min and max y
    if (_minY !== -1 && _maxY !== -1) {
      minY = _minY;
      maxY = _maxY;
    } else {
      // find item line y min and max
      for (const i of selectedWordsIndex) {
        const box = textAnnotationsBox[i];

        if (box.ymin < minY || minY === -1) minY = box.ymin;
        if (box.ymax > maxY || maxY === -1) maxY = box.ymax;
      }
    }
    // get all words in between item line and total line
    const totalValuesIndexes = getWord(tcMinX, minY, tcMaxX, maxY, true, true);

    // filter only words that overlap the most with the total line
    let maxP = 0;
    const sIndex2 = new Set<number>();
    if (totalValuesIndexes && totalValuesIndexes.size !== 0) {
      for (const i of totalValuesIndexes) {
        const box = textAnnotationsBox[i];
        if (box.xmin < minX || minX === -1) minX = box.xmin;
        if (box.xmax > maxX || maxX === -1) maxX = box.xmax;
        const p = rectangleOverlapPercentage(
          {
            xmin: 0,
            xmax: ctxP.canvas.width,
            ymin: textAnnotationsBox[i].ymin,
            ymax: textAnnotationsBox[i].ymax,
          },
          {
            xmin: 0,
            xmax: ctxP.canvas.width,
            ymin: itemLineY.min,
            ymax: itemLineY.max,
          }
        );
        if (p > maxP) {
          maxP = p;
          sIndex2.clear();
          sIndex2.add(i);
        } else if (p === maxP) {
          sIndex2.add(i);
        }
      }
    }

    // if first try or has new words, add 5px on each side and try to get new words
    if (
      (_maxX === -1 && _minX === -1) ||
      !sIndex ||
      sIndex2.size !== sIndex.size
    ) {
      if (_minY === -1 && _maxY === -1) {
        return getValue(type, minX - Y_MARGIN, maxX + Y_MARGIN, sIndex2);
      } else {
        return getValue(
          type,
          minX - Y_MARGIN,
          maxX + Y_MARGIN,
          sIndex2,
          minY,
          maxY
        );
      }
    }

    // if there is no words and if it not expanded, expand in y direction
    if (sIndex2.size === 0 && _maxY === -1 && _minY === -1) {
      return getValue(
        type,
        minX,
        maxX,
        sIndex2,
        minY - Y_MARGIN,
        maxY + Y_MARGIN
      );
    }

    // else return the words
    if (type === "total") setSelectedTotalIndex(sIndex);
    else setSelectedQtyIndex(sIndex);
    let totalValue = "";
    for (const i of sIndex) {
      totalValue += textAnnotations[i].description;
    }
    return { value: totalValue, sIndex };
  }

  function getWord(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    reset = true,
    returnIndexes = false
  ) {
    const selectedWordsIndexCopy = reset
      ? new Set<number>()
      : new Set<number>(selectedWordsIndex);

    if (!backgroundImage) return;

    if (textAnnotationsBox) {
      textAnnotationsBox.forEach((box, i) => {
        // skip first
        if (i === 0) return;

        if (
          rectanglesIntersect(
            x1,
            y1,
            x2,
            y2,
            box.xmin,
            box.ymin,
            box.xmax,
            box.ymax
          )
        ) {
          selectedWordsIndexCopy.add(i);
        }
      });

      if (!returnIndexes) {
        setSelectedWordsIndex(selectedWordsIndexCopy);
      } else {
        return selectedWordsIndexCopy;
      }
    }
  }

  function getWordByCoordinates(X: number, Y1: number, Y2: number): string {
    const X_MARGIN = 50;
    const Y_MARGIN = 5;

    if (!textAnnotations) return "";

    let word = "";

    const onY = textAnnotations
      .filter((annotation) => {
        const ys = annotation.boundingPoly.vertices.map((v) => v.y);
        const y1 = Math.min(...ys);
        const y2 = Math.max(...ys);

        return Math.abs(y1 - Y1) <= Y_MARGIN && Math.abs(y2 - Y2) <= Y_MARGIN;
      })

      .map((annotation) => ({
        text: annotation.description,
        x: Math.min(...annotation.boundingPoly.vertices.map((v) => v.x)),
      }))
      .sort((a, b) => a.x - b.x);

    for (const annotation of onY) {
      const { x } = annotation;

      if (Math.abs(x - X) <= X_MARGIN) {
        word += annotation.text;
      }
    }

    return word;
  }

  function getWordClick(x: number, y: number, reset = false) {
    const selectedWordsIndexCopy = reset
      ? new Set<number>()
      : new Set<number>(selectedWordsIndex);

    if (!backgroundImage || !textAnnotationsBox) return;

    // foreach skip first box
    textAnnotationsBox.forEach((box, i) => {
      // skip first
      if (i === 0) return;

      if (
        box.xmin - 5 <= x &&
        box.xmax + 5 >= x &&
        box.ymin - 5 <= y &&
        box.ymax + 5 >= y
      ) {
        selectedWordsIndexCopy.add(i);
      }
    });

    setSelectedWordsIndex(selectedWordsIndexCopy);
  }

  function rectangleOverlapPercentage(rectA: Box, rectB: Box) {
    // calculate how much of rectA is inside rectB

    // SI = Max(0, Min(XA2, XB2) - Max(XA1, XB1)) * Max(0, Min(YA2, YB2) - Max(YA1, YB1))
    // SU = SA + SB - SI
    // SI / SU

    const SI =
      Math.max(
        0,
        Math.min(rectA.xmax, rectB.xmax) - Math.max(rectA.xmin, rectB.xmin)
      ) *
      Math.max(
        0,
        Math.min(rectA.ymax, rectB.ymax) - Math.max(rectA.ymin, rectB.ymin)
      );
    const SU =
      (rectA.xmax - rectA.xmin) * (rectA.ymax - rectA.ymin) +
      (rectB.xmax - rectB.xmin) * (rectB.ymax - rectB.ymin) -
      SI;
    return SI / SU;
  }

  function rectanglesIntersect(
    xa1: number,
    ya1: number,
    xa2: number,
    ya2: number,
    minBx: number,
    minBy: number,
    maxBx: number,
    maxBy: number
  ) {
    const minAx = Math.min(xa1, xa2);
    const maxAx = Math.max(xa1, xa2);
    const minAy = Math.min(ya1, ya2);
    const maxAy = Math.max(ya1, ya2);

    const aLeftOfB = maxAx < minBx;
    const aRightOfB = minAx > maxBx;
    const aAboveB = minAy > maxBy;
    const aBelowB = maxAy < minBy;
    return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
  }

  function drawBox(
    inFocus: boolean,
    canvas: HTMLCanvasElement,
    coord: Box,
    color: string,
    lineWidth: number,
    isRenderHighlight = false,
    hoverColor: string,
    highlightColor?: string
  ) {
    if (!coord || typeof coord === "undefined") return null;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let [x, y, width, height] = [0, 0, 0, 0];

    if (
      typeof coord.xmin !== "undefined" &&
      typeof coord.xmax !== "undefined" &&
      typeof coord.ymin !== "undefined" &&
      typeof coord.ymax !== "undefined"
    ) {
      // coord is an object containing xmin, xmax, ymin, ymax attributes
      // width is absolute value of (xmax - xmin)
      // height is absolute value of (ymax - ymin)
      // absolute value takes care of various possible referentials:
      //   - sometimes 0,0 is top-left corner
      //   - sometimes 0,0 is bottom-left corner
      [x, y, width, height] = [
        Math.min(coord.xmin, coord.xmax),
        Math.min(coord.ymin, coord.ymax),
        Math.max(coord.xmin, coord.xmax) - Math.min(coord.xmin, coord.xmax),
        Math.max(coord.ymin, coord.ymax) - Math.min(coord.ymin, coord.ymax),
      ];
    }

    if (x < lineWidth / 2) {
      x = lineWidth / 2;
    }
    if (y < lineWidth / 2) {
      y = lineWidth / 2;
    }

    if (x + width > canvas.width) {
      width = canvas.width - lineWidth - x;
    }
    if (y + height > canvas.height) {
      height = canvas.height - lineWidth - y;
    }
    if (inFocus) {
      // Left segment
      const tenPercent = ctx.canvas.width / 10;
      const ninetyPercent = 1 * tenPercent;
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(tenPercent, y);
      ctx.lineTo(0, y);
      ctx.lineTo(0, y + height);
      ctx.lineTo(tenPercent, y + height);
      ctx.stroke();

      // Right segment
      ctx.beginPath();
      ctx.moveTo(ninetyPercent, y);
      ctx.lineTo(ctx.canvas.width, y);
      ctx.lineTo(ctx.canvas.width, y + height);
      ctx.lineTo(ninetyPercent, y + height);
      ctx.stroke();

      if (isRenderHighlight) {
        const leftRange = width / 10;
        const rightRange = 9 * leftRange;
        ctx.strokeStyle = hoverColor;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(x + leftRange, y);
        ctx.lineTo(x, y);
        ctx.lineTo(x, y + height);
        ctx.lineTo(x + leftRange, y + height);
        ctx.stroke();

        // Right segment
        ctx.beginPath();
        ctx.moveTo(x + rightRange, y);
        ctx.lineTo(x + width, y);
        ctx.lineTo(x + width, y + height);
        ctx.lineTo(x + rightRange, y + height);
        ctx.stroke();
      }
    } else {
      // Left segment
      const tenPercent = width / 10;
      const ninetyPercent = 9 * tenPercent;
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(x + tenPercent, y);
      ctx.lineTo(x, y);
      ctx.lineTo(x, y + height);
      ctx.lineTo(x + tenPercent, y + height);
      ctx.stroke();

      // Right segment
      ctx.beginPath();
      ctx.moveTo(x + ninetyPercent, y);
      ctx.lineTo(x + width, y);
      ctx.lineTo(x + width, y + height);
      ctx.lineTo(x + ninetyPercent, y + height);
      ctx.stroke();
    }

    if (isRenderHighlight && highlightColor) {
      ctx.fillStyle = highlightColor;
      ctx.fillRect(0, y, ctx.canvas.width, height);
    }
  }

  function highlightSelectedRowColumnFloatingButton(
    title: string,
    point1: number,
    point2: number
  ) {
    const color = highlightColorSettings ?? initialHighlightColorSettings;
    if (title === "Names") {
      if (!ctxFloatingName) return;
      ctxFloatingName.clearRect(
        0,
        0,
        ctxFloatingName.canvas.width,
        ctxFloatingName.canvas.height
      );
      ctxFloatingName.globalAlpha = 0.2;
      ctxFloatingName.fillStyle = color.floatingButtons.name;
      ctxFloatingName.fillRect(
        point1,
        0,
        point2 - point1,
        ctxFloatingName.canvas.height
      );
    } else if (title === "Multipliers") {
      if (!ctxFloatingMultiplier) return;
      ctxFloatingMultiplier.clearRect(
        0,
        0,
        ctxFloatingMultiplier.canvas.width,
        ctxFloatingMultiplier.canvas.height
      );
      ctxFloatingMultiplier.globalAlpha = 0.2;
      ctxFloatingMultiplier.fillStyle = color.floatingButtons.multiplier;
      ctxFloatingMultiplier.fillRect(
        point1,
        0,
        point2 - point1,
        ctxFloatingMultiplier.canvas.height
      );
    } else if (title === "Quantities") {
      if (!ctxFloatingQty) return;
      ctxFloatingQty.clearRect(
        0,
        0,
        ctxFloatingQty.canvas.width,
        ctxFloatingQty.canvas.height
      );
      ctxFloatingQty.globalAlpha = 0.2;
      ctxFloatingQty.fillStyle = color.floatingButtons.quantity;
      ctxFloatingQty.fillRect(
        point1,
        0,
        point2 - point1,
        ctxFloatingQty.canvas.height
      );
    } else if (title === "Totals") {
      if (!ctxFloatingTotal) return;
      ctxFloatingTotal.clearRect(
        0,
        0,
        ctxFloatingTotal.canvas.width,
        ctxFloatingTotal.canvas.height
      );
      ctxFloatingTotal.globalAlpha = 0.2;
      ctxFloatingTotal.fillStyle = color.floatingButtons.total;
      ctxFloatingTotal.fillRect(
        point1,
        0,
        point2 - point1,
        ctxFloatingTotal.canvas.height
      );
    } else if (title === "Items") {
      if (!ctxFloatingItems) return;
      ctxFloatingItems.clearRect(
        0,
        0,
        ctxFloatingItems.canvas.width,
        ctxFloatingItems.canvas.height
      );
      ctxFloatingItems.globalAlpha = 0.2;
      ctxFloatingItems.fillStyle = color.floatingButtons.selectedRow;
      ctxFloatingItems.fillRect(
        0,
        point1,
        ctxFloatingItems.canvas.height,
        point2 - point1
      );
    }
  }

  function highlightSelectedRowColumnItems(
    allRows: { min: number; max: number }[]
  ) {
    if (!ctxFloatingItems) return;
    const color = highlightColorSettings ?? initialHighlightColorSettings;
    ctxFloatingItems.clearRect(
      0,
      0,
      ctxFloatingItems.canvas.width,
      ctxFloatingItems.canvas.height
    );
    ctxFloatingItems.globalAlpha = 0.2;
    ctxFloatingItems.fillStyle = color.rowHighlight.selected;
    for (const row of allRows) {
      ctxFloatingItems.fillRect(
        0,
        row.min,
        ctxFloatingItems.canvas.height,
        row.max - row.min
      );
    }
  }

  function handleSetPoints(
    type: "start" | "end",
    newPoint: Vertex,
    rowEditType?: "remove" | "add"
  ) {
    if (rowEditType === "remove" && selectedFloatingButton === "Items") {
      if (type === "start") {
        setDeselectRowMinY(newPoint.y);
      } else {
        const deselectMaxY =
          deselectRowMinY > newPoint.y ? deselectRowMinY : newPoint.y;
        const deselectMinY =
          deselectRowMinY < newPoint.y ? deselectRowMinY : newPoint.y;
        const predictY = getMaxMinYInWordIndexes(deselectMinY, deselectMaxY);

        const deselectMaxYUsed =
          predictY && predictY.maxYPredict > deselectMaxY
            ? predictY.maxYPredict
            : deselectMaxY;
        const deselectMinYUsed =
          predictY && predictY.minYPredict < deselectMinY
            ? predictY.minYPredict
            : deselectMinY;
        if (
          deselectMaxY === -1 ||
          deselectMinY === -1 ||
          !allSelectedYRange.length
        ) {
          setDeselectRowMinY(-1);
          return;
        }

        const updatedAllSelectedYRange = allSelectedYRange.flatMap((range) => {
          if (range.min < deselectMinYUsed && range.max > deselectMaxYUsed) {
            return [
              { min: range.min, max: deselectMinYUsed },
              { min: deselectMaxYUsed, max: range.max },
            ];
          } else if (
            range.min < deselectMinYUsed &&
            range.max <= deselectMaxYUsed &&
            range.max > deselectMinYUsed
          ) {
            return [{ min: range.min, max: deselectMinYUsed }];
          } else if (
            range.min >= deselectMinYUsed &&
            range.max > deselectMaxYUsed &&
            range.min < deselectMaxYUsed
          ) {
            return [{ min: deselectMaxYUsed, max: range.max }];
          } else if (
            range.min >= deselectMinYUsed &&
            range.max <= deselectMaxYUsed
          ) {
            return [];
          } else {
            return [range];
          }
        });
        setDeselectRowMinY(-1);
        setAllSelectedYRange(updatedAllSelectedYRange);
        return;
      }
    }
    if (rowEditType === "add" && selectedFloatingButton === "Items") {
      if (type === "start") {
        setAddSelectedRowMinY(newPoint.y);
      } else {
        const addedMaxY =
          addSelectedRowMinY > newPoint.y ? addSelectedRowMinY : newPoint.y;
        const addedMinY =
          addSelectedRowMinY < newPoint.y ? addSelectedRowMinY : newPoint.y;
        const predictY = getMaxMinYInWordIndexes(addedMinY, addedMaxY);

        const addedMaxYUsed =
          predictY && predictY?.maxYPredict > addedMaxY
            ? predictY.maxYPredict
            : addedMaxY;
        const addedMinYUsed =
          predictY && predictY?.minYPredict < addedMinY
            ? predictY.minYPredict
            : addedMinY;
        const isCurrentSelectedContainAdded = allSelectedYRange.some(
          (x) => x.max >= addedMaxYUsed && x.min <= addedMinYUsed
        );
        if (
          addedMaxY === -1 ||
          addedMinY === -1 ||
          isCurrentSelectedContainAdded
        ) {
          setAddSelectedRowMinY(-1);
          return;
        }

        const intersects = allSelectedYRange.some(
          (range) =>
            (addedMinYUsed >= range.min && addedMinYUsed <= range.max) ||
            (addedMaxYUsed >= range.min && addedMaxYUsed <= range.max) ||
            (addedMinYUsed <= range.min && addedMaxYUsed >= range.max)
        );
        if (!intersects) {
          setAllSelectedYRange((prev) => {
            return [...prev, { min: addedMinYUsed, max: addedMaxYUsed }];
          });
          setAddSelectedRowMinY(-1);
          return;
        } else {
          let min = addedMinYUsed;
          let max = addedMaxYUsed;
          const updatedAllSelectedYRange = allSelectedYRange.filter((range) => {
            if (addedMinY <= range.max && addedMaxY >= range.min) {
              min = Math.min(min, range.min);
              max = Math.max(max, range.max);
              return false;
            }
            return true;
          });

          updatedAllSelectedYRange.push({ min, max });

          setAllSelectedYRange(updatedAllSelectedYRange);
          setAddSelectedRowMinY(-1);
          return;
        }
      }
    }
    if (
      !["Names", "Multipliers", "Quantities", "Totals", "Items"].includes(
        selectedFloatingButton
      ) ||
      !isFloatingButtonsVisible
    )
      return;

    switch (selectedFloatingButton) {
      case "Names":
        if (type === "start") {
          setNameMinX(newPoint.x);
        } else if (type === "end") {
          setNameMaxX(newPoint.x);
          highlightSelectedRowColumnFloatingButton(
            "Names",
            nameMinX,
            newPoint.x
          );
          setSelectedFloatingButton("Quantities");
        }
        break;
      case "Multipliers":
        if (type === "start") {
          setMultiplierMinX(newPoint.x);
        } else {
          setMultiplierMaxX(newPoint.x);
          highlightSelectedRowColumnFloatingButton(
            "Multipliers",
            multiplierMinX,
            newPoint.x
          );
          setSelectedFloatingButton("Items");
        }
        break;
      case "Quantities":
        if (type === "start") {
          setQtyMinX(newPoint.x);
        } else {
          setQtyMaxX(newPoint.x);
          highlightSelectedRowColumnFloatingButton(
            "Quantities",
            qtyMinX,
            newPoint.x
          );
          setSelectedFloatingButton("Totals");
        }
        break;
      case "Totals":
        if (type === "start") {
          setTotalMinX(newPoint.x);
        } else {
          setTotalMaxX(newPoint.x);
          highlightSelectedRowColumnFloatingButton(
            "Totals",
            totalMinX,
            newPoint.x
          );
          setSelectedFloatingButton("Items");
        }
        break;
      case "Items":
        if (type === "start") {
          setFirstSelectionMinY(newPoint.y);
        } else {
          const resetSelection = () => {
            setDeselectRowMinY(-1);
            setFirstSelectionMinY(-1);
          };
          if (firstSelectionMinY === -1) {
            resetSelection();
            return;
          }
          const addedMaxYUsed =
            firstSelectionMinY > newPoint.y ? firstSelectionMinY : newPoint.y;
          const addedMinYUsed =
            firstSelectionMinY < newPoint.y ? firstSelectionMinY : newPoint.y;
          if (addedMaxYUsed === -1 || addedMinYUsed === -1) {
            resetSelection();
            return;
          }
          const predictY = getMaxMinYInWordIndexes(
            addedMinYUsed,
            addedMaxYUsed
          );

          setAllSelectedYRange([
            {
              min: predictY ? predictY.minYPredict : addedMinYUsed,
              max: predictY ? predictY.maxYPredict : addedMaxYUsed,
            },
          ]);
          setDeselectRowMinY(-1);
          setFirstSelectionMinY(-1);
          resetSelection();
          return;
        }
        break;
      default:
        break;
    }
  }

  function handleMouseDown(e: React.MouseEvent<HTMLCanvasElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (!ctx) return;

    const rect = ctx.canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (e.ctrlKey || e.metaKey) {
      handleSetPoints("start", { x, y }, "remove");
    } else if (e.shiftKey) {
      handleSetPoints("start", { x, y }, "add");
    } else handleSetPoints("start", { x, y });

    if (!e.ctrlKey && e.button !== 2 && selectedWordsIndex.size > 0) {
      getWordClick(x, y, true);
    } else {
      getWordClick(x, y);
      updateCanvasSelect(e.clientX, e.clientY);
    }
    setEnableDrawing(true);
  }

  function handleMouseUp(e: React.MouseEvent<HTMLCanvasElement>) {
    e.preventDefault();
    e.stopPropagation();
    const rect = ctx?.canvas.getBoundingClientRect();
    const x = e.clientX - (rect?.left ?? 0);
    const y = e.clientY - (rect?.top ?? 0);

    setEnableDrawing(false);
    setStartDrawingPoints(undefined);
    if (e.ctrlKey || e.metaKey) {
      handleSetPoints("end", { x, y }, "remove");
    } else if (e.shiftKey) {
      handleSetPoints("end", { x, y }, "add");
    } else {
      handleSetPoints("end", { x, y }, undefined);
    }

    if (!ctxSelect) return;
    ctxSelect.canvas.focus({
      preventScroll: true,
    });

    ctxSelect.clearRect(0, 0, ctxSelect.canvas.width, ctxSelect.canvas.height);

    // clear main canvas
    if (ctxP) {
      ctxP.clearRect(0, 0, ctxP.canvas.width, ctxP.canvas.height);
    }

    let selectedWords = getMenuWord().trim();
    if (selectedWords !== "") {
      drawItemLine();

      // check if selectedWords is not a date
      // copy selected words to clipboard
      const hasLetters = !!selectedWords.match(/[^\d,.-]/g);
      const hasMultipleDots = !!selectedWords.match(/^[^.]+\.[^.]+$/g);
      const hasMultipleCommas = !!selectedWords.match(/^[^,]+\.[^,]+$/g);

      if (!(hasLetters || hasMultipleDots || hasMultipleCommas))
        selectedWords = formatOcrNumber(selectedWords, 2).toString();

      navigator.clipboard
        .writeText(selectedWords)
        .then(() => {
          dispatch(
            alertActions.INFO({
              message: selectedWords + " copied to clipboard",
              position: Position.TOP,
            })
          );
        })
        .catch((err) => {
          dispatch(alertActions.ERROR(err.message || "Error!"));
        });
    }
  }

  function handleMouseMoveDraw(e: React.MouseEvent<HTMLCanvasElement>) {
    if (!enableDrawing) return;
    e.preventDefault();
    e.stopPropagation();
    updateCanvasSelect(e.clientX, e.clientY);

    // clear canvas
    if (startDrawingPoints && ctxSelect) {
      const rect = ctxSelect.canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      getWord(startDrawingPoints.x, startDrawingPoints.y, x, y);

      const edgeTreshold = 50;
      const isNearBottom = e.clientY > window.innerHeight - edgeTreshold;
      const isNearTop = e.clientY < edgeTreshold;

      if (isNearBottom || isNearTop) {
        const elementToScroll =
          document.getElementsByClassName("scroll-bar")[0];
        elementToScroll?.scrollBy(0, isNearBottom ? 10 : -10);
      }
    }
  }

  useEffect(() => {
    if (!ctx || !ctxImage || !ctxP || !ctxSelect || !backgroundImage) return;
    if (imageRotation === 90 || imageRotation === 270) {
      const ratio = ctxImage.canvas.width / backgroundImage.height;
      ctxImage.canvas.height = backgroundImage.width * ratio;
    } else {
      const ratio = ctxImage.canvas.width / backgroundImage.width;
      ctxImage.canvas.height = backgroundImage.height * ratio;
    }
    ctx.canvas.height = ctxImage.canvas.height;
    ctxP.canvas.height = ctxImage.canvas.height;
    ctxSelect.canvas.height = ctxImage.canvas.height;
    ctxSelect.globalAlpha = 0.2;
  }, [imageRotation, ctxImage, ctx, ctxP, ctxSelect, backgroundImage]);

  // draw image on ctxImage canvas
  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    if (!backgroundImage || !ctxImage) return;
    let ratio = 1;

    if (imageRotation === 90 || imageRotation === 270) {
      ratio = ctxImage.canvas.width / backgroundImage.height;
      ctxImage.canvas.height = backgroundImage.width * ratio;
    } else {
      ratio = ctxImage.canvas.width / backgroundImage.width;
      ctxImage.canvas.height = backgroundImage.height * ratio;
    }
    setImageRatio(ratio);
    ctxImage.clearRect(0, 0, ctxImage.canvas.width, ctxImage.canvas.height);
    ctxImage.save();

    if (imageRotation === 90 || imageRotation === 270) {
      ctxImage.translate(
        (backgroundImage.height * ratio) / 2,
        (backgroundImage.width * ratio) / 2
      );
    } else {
      ctxImage.translate(
        (backgroundImage.width * ratio) / 2,
        (backgroundImage.height * ratio) / 2
      );
    }
    ctxImage.rotate((imageRotation * Math.PI) / 180);
    ctxImage.drawImage(
      backgroundImage,
      (-backgroundImage.width * ratio) / 2,
      (-backgroundImage.height * ratio) / 2,

      backgroundImage.width * ratio,
      backgroundImage.height * ratio
    );
    ctxImage.restore();
  }, [
    backgroundImage,
    ctxImage,
    ctxImage?.canvas.width,
    ctxImage?.canvas.height,
    imageRotation,
  ]);

  // update text annotation boxes
  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useEffect(() => {
    if (!textAnnotations?.length) {
      setTextAnnotationsBox([]);
      return;
    }
    if (!backgroundImage || !ctx || ctx?.canvas.width < 1) return;
    const data:
      | {
          fileName: string;
          textAnnotations: TextAnnotation[];
        }[]
      | undefined = ocrData?.filter((obj) => obj.fileName === imageUrl);
    if (!(data !== undefined && data[0] !== undefined)) return;
    if (!data[0].textAnnotations.length) return;
    const xs = data[0].textAnnotations
      .map((annotation) => annotation.boundingPoly.vertices.map((v) => v.x))
      .flat();
    const ys = data[0].textAnnotations
      .map((annotation) => annotation.boundingPoly.vertices.map((v) => v.y))
      .flat();

    const minX = Math.min(...xs);
    const maxX = Math.max(...xs);

    const minY = Math.min(...ys);
    const maxY = Math.max(...ys);

    const portrait = ctx
      ? ctx?.canvas.width < ctx?.canvas.height
      : maxX - minX < maxY - minY;

    const isFlipped = imageRotation > ocrRotation ? portrait : !portrait;
    const width = isFlipped ? backgroundImage.height : backgroundImage.width;
    const height = isFlipped ? backgroundImage.width : backgroundImage.height;

    const settedRotation = [90, 180, 270];
    const isLocked =
      lockOcr &&
      lockedRotation !== undefined &&
      !!lockedAnnotationBox &&
      ocrRotation !== 0 &&
      !settedRotation.includes(ocrRotation);
    const angle = isLocked ? ocrRotation - lockedRotation : ocrRotation;

    // Handle !(90,180,270) rotation saved in firestore
    if (
      ocrRotation !== 0 &&
      imageRotation !== ocrRotation &&
      textAnnotationsBox.length === 0 &&
      !settedRotation.includes(ocrRotation)
    ) {
      // Getting base rotation
      const baseRotation = 360 - imageRotation + ocrRotation;
      const baseAnnotationBoxes = generateCoordinate(
        data[0].textAnnotations,
        backgroundImage.width,
        backgroundImage.height,
        false,
        baseRotation,
        settedRotation,
        false,
        baseRotation
      );

      let newRotation = ocrRotation - baseRotation;
      if (newRotation < 0) newRotation = 360 + newRotation;

      // Adjusting the boxes according to how many degree after the base rotation
      const adjustedAnnotationBoxes = generateCoordinate(
        baseAnnotationBoxes,
        width,
        height,
        true,
        newRotation,
        settedRotation,
        isFlipped
      );

      // setting base rotation and annotation boxes
      setLockOcr(true);
      setLockedRotation(baseRotation);
      setLockedAnnotationBox(baseAnnotationBoxes);
      setTextAnnotationsBox(adjustedAnnotationBoxes);
      return;
    }

    // If the rotation is locked and the boxes are already set, return the boxes
    if (ocrRotation === lockedRotation && lockedAnnotationBox) {
      setTextAnnotationsBox(lockedAnnotationBox);
      return;
    }

    const dataToLoop = isLocked ? lockedAnnotationBox : data[0].textAnnotations;
    const boxList = generateCoordinate(
      dataToLoop,
      width,
      height,
      isLocked,
      angle,
      settedRotation,
      isFlipped
    );
    setTextAnnotationsBox(boxList);

    // if the rotation is (90,180,270) and it different with image rotation, lock current rotation as base rotation
    if (settedRotation.includes(ocrRotation) && imageRotation !== ocrRotation) {
      setLockedRotation(ocrRotation);
      setLockOcr(true);
      setLockedAnnotationBox(boxList);
    }

    if (ocrRotation === 0 && imageRotation === 0) {
      setLockedRotation(undefined);
      setLockOcr(false);
      setLockedAnnotationBox([]);
    }
  }, [
    backgroundImage,
    ctx,
    ctx?.canvas.width,
    ctx?.canvas.height,
    ocrData,
    rotate,
    imageRotation,
    imageRatio,
    ocrRotation,
    textAnnotations,
    imageUrl,
  ]);

  const generateCoordinate = useCallback(
    (
      dataToLoop: TextAnnotation[] | Box[],
      width: number,
      height: number,
      isLocked: boolean,
      angle: number,
      settedRotation: number[],
      isFlipped: boolean,
      baseRotation?: number
    ) => {
      const boxByAngle = (
        angle: number,
        x: number,
        x1: number,
        y: number,
        y1: number
      ) => {
        const newWidth = isFlipped ? height : width;
        const newHeight = isFlipped ? width : height;
        if (angle === 90) {
          return {
            xmin: (x1 + newWidth) * imageRatio,
            xmax: (x + newWidth) * imageRatio,
            ymin: y1 * imageRatio,
            ymax: y * imageRatio,
          };
        } else if (angle === 180) {
          return {
            xmin: (x1 + newHeight) * imageRatio,
            xmax: (x + newHeight) * imageRatio,
            ymin: (y1 + newWidth) * imageRatio,
            ymax: (y + newWidth) * imageRatio,
          };
        } else if (angle === 270) {
          return {
            xmin: x1 * imageRatio,
            xmax: x * imageRatio,
            ymin: (y1 + newHeight) * imageRatio,
            ymax: (y + newHeight) * imageRatio,
          };
        }
      };

      const boxList: Box[] = [];
      const newBaseRotation = baseRotation ?? lockedRotation;

      for (let i = 0; i < dataToLoop.length; i++) {
        const annotation = dataToLoop[i];
        let xMin = 0;
        let xMax = 0;
        let yMin = 0;
        let yMax = 0;

        if (
          isLocked &&
          newBaseRotation &&
          ocrRotation - newBaseRotation === 0
        ) {
          const { xmin, xmax, ymin, ymax } = annotation as Box;
          boxList.push({ xmin, xmax, ymin, ymax });
          continue;
        }

        if (isLocked) {
          const { xmin, xmax, ymin, ymax } = annotation as Box;
          xMin = xmin;
          xMax = xmax;
          yMin = ymin;
          yMax = ymax;
        } else {
          const { boundingPoly } = annotation as TextAnnotation;
          const { vertices } = boundingPoly;

          const xs = vertices.map((v) => v.x);
          const ys = vertices.map((v) => v.y);

          xMin = Math.min(...xs);
          xMax = Math.max(...xs);
          yMin = Math.min(...ys);
          yMax = Math.max(...ys);
        }

        if (!settedRotation.includes(angle) || isLocked) {
          const xCenter = isLocked ? width * 0.3 : width / 2;
          const yCenter = isLocked ? height * 0.3 : height / 2;

          const [xMinRotated, yMinRotated] = rotate(
            xMin,
            yMin,
            false,
            angle,
            xCenter,
            yCenter
          );

          const [xMaxRotated, yMaxRotated] = rotate(
            xMax,
            yMax,
            false,
            angle,
            xCenter,
            yCenter
          );

          if (isLocked) {
            boxList.push({
              xmin: xMinRotated - 2,
              xmax: xMaxRotated + 2,
              ymin: yMinRotated - 2,
              ymax: yMaxRotated + 2,
            });
            continue;
          }

          boxList.push({
            xmin: xMinRotated * imageRatio - 2,
            xmax: xMaxRotated * imageRatio + 2,
            ymin: yMinRotated * imageRatio - 2,
            ymax: yMaxRotated * imageRatio + 2,
          });
        } else {
          const [xMinRotated, yMinRotated] = rotate(xMin, yMin, false, angle);

          const [xMaxRotated, yMaxRotated] = rotate(xMax, yMax, false, angle);

          const box = boxByAngle(
            angle,
            xMinRotated,
            xMaxRotated,
            yMinRotated,
            yMaxRotated
          );
          if (box) boxList.push(box);
        }
      }

      return boxList;
    },
    [imageRatio, lockedRotation, ocrRotation, rotate]
  );

  // draw text annotation boxes
  // biome-ignore lint/correctness/useExhaustiveDependencies: I'm not going to change this
  useLayoutEffect(() => {
    if (
      !ctx ||
      ctx?.canvas.width < 1 ||
      !textAnnotationsBox ||
      textAnnotationsBox.length < 1
    )
      return;

    const ocrColor = highlightColorSettings ?? initialHighlightColorSettings;

    const getColor = (
      index: number,
      isSelectedItem: boolean,
      isResolvedItem: boolean
    ) => {
      if (autoMultipliersWordsIndex.has(index))
        return ocrColor.boxBorder.autoMultiplier;
      if (isSelectedItem && isResolvedItem) return ocrColor.boxBorder.resolved;
      if (isSelectedItem && !isResolvedItem)
        return ocrColor.boxBorder.unresolved;
      if (selectedTotalIndex.has(index))
        return ocrColor.boxBorder.autofillTotal;
      if (selectedQtyIndex.has(index))
        return ocrColor.boxBorder.autofillQuantity;
      return selectedWordsIndex.has(index)
        ? ocrColor.boxBorder.selected
        : ocrColor.boxBorder.unselected;
    };

    const getHighlightColor = (inFocus: boolean, isResolvedItem: boolean) => {
      if (isResolvedItem) return ocrColor.rowHighlight.resolved;
      if (!isResolvedItem) return ocrColor.rowHighlight.unresolved;
      return inFocus ? ocrColor.rowHighlight.focus : ocrColor.rowHighlight.idle;
    };

    const draw = () => {
      // clear canvas
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      if (!isHighlightOn) {
        return; // Skip drawing highlights if isHighlightOn is false
      }

      const rowHighlighted: number[] = [];
      textAnnotationsBox.forEach((box, index) => {
        const isRowHighlighted = rowHighlighted.find(
          (yLine) => Math.abs(yLine - Math.round(box.ymin)) < 5
        );

        const inFocus = clicksInFocus && clicksInFocus.has(index);
        const isSelectedItem =
          (selectedItemBoxIndexes &&
            selectedItemBoxIndexes.selectedIndex.has(index)) ||
          preselectItemBoxIndexes.has(index);
        const isResolvedItem =
          selectedItemBoxIndexes &&
          selectedItemBoxIndexes.resolvedItem.has(index);
        const color = getColor(index, isSelectedItem, isResolvedItem);

        const highlightRow = (inFocus || isSelectedItem) && !isRowHighlighted;

        if (highlightRow) {
          const highlightColor = getHighlightColor(inFocus, isResolvedItem);
          drawBox(
            inFocus,
            ctx.canvas,
            box,
            color,
            2,
            true,
            ocrColor.boxBorder.hover,
            highlightColor
          );
        } else {
          drawBox(
            inFocus,
            ctx.canvas,
            box,
            color,
            2,
            true,
            ocrColor.boxBorder.hover
          );
        }

        if (highlightRow) rowHighlighted.push(Math.round(box.ymin));
      });
    };
    const timerId = requestAnimationFrame(draw);
    return () => cancelAnimationFrame(timerId);
  }, [
    textAnnotationsBox,
    clicksInFocus,
    selectedWordsIndex,
    selectedTotalIndex,
    selectedQtyIndex,
    selectedItemBoxIndexes,
    preselectItemBoxIndexes,
    ctx,
    highlightColorSettings,
    autoMultipliersWordsIndex,
    isHighlightOn,
  ]);

  const updateCanvasSelect = (clientX: number, clientY: number) => {
    if (!backgroundImage || !ctxSelect) return;
    const rect = ctxSelect.canvas.getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;

    if (startDrawingPoints) {
      const w = x - startDrawingPoints.x;
      const h = y - startDrawingPoints.y;
      ctxSelect.clearRect(
        0,
        0,
        ctxSelect.canvas.width,
        ctxSelect.canvas.height
      );
      ctxSelect.fillRect(startDrawingPoints.x, startDrawingPoints.y, w, h);
    } else {
      setStartDrawingPoints({ x, y });
    }
  };

  function getMenuWord() {
    let word = "";
    for (const i of selectedWordsIndex) {
      if (!textAnnotations[i]) continue;
      word += textAnnotations[i].description;
      word += " ";
    }

    return word;
  }

  function getMaxMinYInWordIndexes(min: number, max: number) {
    const filteredSelectionInRangeMin = textAnnotationsBox.filter((box, i) => {
      if (i === 0) return false;
      return box.ymin < min + 7;
    });
    const filteredSelectionInRangeMax = textAnnotationsBox.filter((box, i) => {
      if (i === 0) return false;
      return box.ymax > max - 7;
    });
    if (
      filteredSelectionInRangeMin.length === 0 ||
      filteredSelectionInRangeMax.length === 0
    )
      return;
    const maxY = Math.min(
      ...filteredSelectionInRangeMax.map((box) => box.ymax)
    );
    const minY = Math.max(
      ...filteredSelectionInRangeMin.map((box) => box.ymin)
    );
    const maxYPredict = maxY > minY ? maxY : minY;
    const minYPredict = minY < maxY ? minY : maxY;
    return { minYPredict: minYPredict - 5, maxYPredict: maxYPredict + 5 };
  }

  function createSelection(type: string, sIndex?: Set<number>) {
    const newSelection: Selection = {
      timestamp: new Date(),
      boundingPoly: {},
      texts: [] as string[],
      selectedType: type,
      imageUrl: imageUrl,
    };

    const t: string[] = [];
    const bp: Vertex[][] = [];
    if (type === "autoDetectTotal") {
      const selectedIndex = sIndex ? sIndex : selectedTotalIndex;
      for (const i of selectedIndex) {
        t.push(textAnnotations[i].description);
        bp.push(textAnnotations[i].boundingPoly.vertices);
      }
    } else if (type === "autoDetectQuantity") {
      const selectedIndex = sIndex ? sIndex : selectedQtyIndex;
      for (const i of selectedIndex) {
        t.push(textAnnotations[i].description);
        bp.push(textAnnotations[i].boundingPoly.vertices);
      }
    } else {
      for (const i of selectedWordsIndex) {
        t.push(textAnnotations[i].description);
        bp.push(textAnnotations[i].boundingPoly.vertices);
      }
    }

    newSelection.texts = { ...t };
    newSelection.boundingPoly = { ...bp };
    return newSelection;
  }

  const createSelectionForBulk = useCallback(
    (
      annotations: TextAnnotation[],
      selectedType:
        | "Item"
        | "autoMultiplier"
        | "multiplier"
        | "LastItemQuantity"
        | "LastItemTotal"
    ): Selection => ({
      timestamp: new Date(),
      boundingPoly: {
        ...annotations.map(
          (item: TextAnnotation) => item.boundingPoly.vertices
        ),
      },
      texts: {
        ...annotations.map((item: TextAnnotation) => item.description),
      } as string[],
      selectedType,
      imageUrl,
    }),
    [imageUrl]
  );

  function drawItemLine(
    type?: "total" | "quantity" | "multiplier",
    _totalColumnMinx?: number,
    _totalColumnMaxx?: number
  ) {
    if (!ctxP) return;

    let minY = -1;
    let maxY = -1;

    const getTotalColumnRange = () => {
      switch (type) {
        case "quantity":
          return { minX: qtyColumnMinx, maxX: qtyColumnMaxx };
        case "total":
          return { minX: totalColumnMinx, maxX: totalColumnMaxx };
        case "multiplier":
          return { minX: multiplierMinX, maxX: multiplierMaxX };
        default:
          break;
      }
    };

    const tcminx = _totalColumnMinx
      ? _totalColumnMinx
      : getTotalColumnRange()?.minX ?? -1;
    const tcmmax = _totalColumnMaxx
      ? _totalColumnMaxx
      : getTotalColumnRange()?.maxX ?? -1;
    for (const i of selectedWordsIndex) {
      const box = textAnnotationsBox[i];

      if (box.ymin < minY || minY === -1) minY = box.ymin;
      if (box.ymax > maxY || maxY === -1) maxY = box.ymax;
    }

    if (
      !rectanglesIntersect(
        0,
        itemLineY.min,
        ctxP.canvas.width,
        itemLineY.max,
        0,
        minY,
        ctxP.canvas.width,
        maxY
      )
    ) {
      if (type === "total") {
        setSelectedTotalIndex(new Set<number>());
      } else if (type === "quantity") {
        setSelectedQtyIndex(new Set<number>());
      }
    }

    setItemLineY({
      min: minY,
      max: maxY,
    });

    if (
      isFloatingButtonsVisible &&
      ["Names", "Multipliers", "Quantities", "Totals", "Items"].includes(
        selectedFloatingButton
      )
    )
      return;

    const h = maxY - minY;

    const color = highlightColorSettings ?? initialHighlightColorSettings;

    ctxP.globalAlpha = 0.2;
    ctxP.fillStyle = color.rowHighlight.selected;
    // ctxP.fillRect(0, minY - 5, ctxP.canvas.width, h + 10);
    ctxP.fillRect(0, minY, ctxP.canvas.width, h);
    ctxP.fillRect(tcminx, 0, tcmmax - tcminx, ctxP.canvas.height);
    ctxP.globalAlpha = 1.0;
    ctxP.fillStyle = "black";
    ctxP.fillText("Total", tcminx + 5, 15);
  }

  function selectColumn(
    setColumnMaxx: React.Dispatch<React.SetStateAction<number>>,
    setColumnMinx: React.Dispatch<React.SetStateAction<number>>,
    type?: "total" | "quantity"
  ) {
    // find smallest x and largest X of all selected words
    let minX = -1;
    let maxX = -1;

    for (const i of selectedWordsIndex) {
      const box = textAnnotationsBox[i];

      if (box.xmin < minX || minX === -1) minX = box.xmin;
      if (box.xmax > maxX || maxX === -1) maxX = box.xmax;
    }

    setColumnMaxx(maxX);
    setColumnMinx(minX);

    drawItemLine(type, minX, maxX);
  }

  function handleMenuItemClick(type: string, multiplier?: number) {
    if (type === selections.LastItemQuantity) {
      if (multiplier === undefined) multiplier = 1;
      if (multiplier === 1)
        selectColumn(setQtyColumnMaxx, setQtyColumnMinx, "quantity");
      const quantity = getMenuWord();
      const quantityMultiplied = parseFloat(quantity) * multiplier;
      dispatch(
        alertActions.INFO({
          message:
            "Quantity: " +
            quantity +
            " * " +
            multiplier +
            " added (" +
            quantityMultiplied +
            ")",
          position: Position.TOP,
        })
      );
    }
    let autoTotal;
    if (totalColumnMaxx > 0 && totalColumnMinx > 0 && ctxP) {
      const { value: totalValue, sIndex } = getValue("total");
      const selectedIndex = sIndex ? sIndex : selectedTotalIndex;

      if (selectedIndex.size > 0) {
        if (
          type === selections.AddOpenItem ||
          type === selections.AddDiverseItem
        )
          autoTotal = {
            value: totalValue,
            selection: createSelection("autoDetectTotal", sIndex),
          };
        else
          onAutoDetectTotal?.(
            totalValue,
            createSelection("autoDetectTotal", sIndex)
          );
      }
    }

    let autoQty;
    if (qtyColumnMaxx > 0 && qtyColumnMinx > 0 && ctxP) {
      const { value: qtyValue, sIndex } = getValue("quantity");
      const selectedIndex = sIndex ? sIndex : selectedQtyIndex;

      if (selectedIndex.size > 0) {
        if (
          type === selections.AddOpenItem ||
          type === selections.AddDiverseItem
        )
          autoQty = {
            value: qtyValue,
            selection: createSelection("autoDetectQuantity", sIndex),
          };
        else
          onAutoDetectQuantity?.(
            qtyValue,
            createSelection("autoDetectQuantity", sIndex)
          );
      }
    }

    const selectionType =
      type + (multiplier !== undefined ? multiplier.toString() : "");

    if (selectionType === selections.LastItemTotal)
      selectColumn(setTotalColumnMaxx, setTotalColumnMinx, "total");

    const selection = createSelection(selectionType);
    const text = getMenuWord();
    if (
      selectionType === selections.AddOpenItem ||
      selectionType === selections.AddDiverseItem
    ) {
      onTextSelected?.(
        text,
        type,
        selection,
        undefined,
        undefined,
        autoQty,
        autoTotal
      );
      return;
    }

    if (type === selections.Item) {
      dispatch(
        actionsOcr.UPDATE_OCR_ITEM_QUERY_STATE({
          ocrItemQuery: text,
        })
      );
    }

    if (selectionType === selections.AutoDetectDiverseItems) {
      const ERROR_MARGIN = 6;

      interface BoundingGroup {
        minY: number;
        maxY: number;
        keys: number[];
      }

      const groups: BoundingGroup[] = [];

      for (const key of Object.keys(selection.boundingPoly)) {
        const ys = selection.boundingPoly[Number(key)].map((v) => v.y);
        const minY = Math.min(...ys);
        const maxY = Math.max(...ys);

        const group = groups.find((group) => {
          return (
            Math.abs(group.minY - minY) < ERROR_MARGIN &&
            Math.abs(group.maxY - maxY) < ERROR_MARGIN
          );
        });

        if (group) {
          group.keys.push(Number(key));
        }

        if (!group) {
          groups.push({
            minY,
            maxY,
            keys: [Number(key)],
          });
        }
      }

      if (!ctx) return;

      const getMargin = (c: number) => -0.8051 * c + 720.1;
      const margin = getMargin(ctx.canvas.width);

      const values = groups.map((group) => {
        return formatOcrNumber(
          getWordByCoordinates(
            totalColumnMinx + margin,
            group.minY,
            group.maxY
          ),
          2
        );
      });

      onTextSelected?.("", type, selection, multiplier, values);
      return;
    }

    onTextSelected?.(text, type, selection, multiplier);
  }

  const getPreselectedItem = useCallback(() => {
    setSelectedQtyIndex(new Set<number>());

    const nameMinXUsed = Math.min(nameMinX, nameMaxX);
    const nameMaxXUsed = Math.max(nameMinX, nameMaxX);
    const qtyMinXUsed = Math.min(qtyMinX, qtyMaxX);
    const qtyMaxXUsed = Math.max(qtyMinX, qtyMaxX);
    const multiplierMinXUsed = Math.min(multiplierMinX, multiplierMaxX);
    const multiplierMaxXUsed = Math.max(multiplierMinX, multiplierMaxX);
    const totalMinXUsed = Math.min(totalMinX, totalMaxX);
    const totalMaxXUsed = Math.max(totalMinX, totalMaxX);

    const filteredNames: TextAnnotation[] = [];
    const filteredMultipliers: TextAnnotation[] = [];
    const filteredQuantities: TextAnnotation[] = [];
    const filteredTotals: TextAnnotation[] = [];

    for (const range of allSelectedYRange) {
      for (const [i, box] of textAnnotationsBox.entries()) {
        const { xmin, xmax, ymin, ymax } = box;
        const isYInRange = ymin > range.min - 5 && ymax < range.max + 5;
        const description = textAnnotations[i].description;
        const formattedNumber = Number(formatOcrNumber(description, 2));

        if (
          xmin > nameMinXUsed - 10 &&
          xmax < nameMaxXUsed + 10 &&
          isYInRange
        ) {
          filteredNames.push(textAnnotations[i]);
        }
        if (
          xmin > multiplierMinXUsed - 10 &&
          xmax < multiplierMaxXUsed + 10 &&
          isYInRange &&
          !isNaN(formattedNumber)
        ) {
          filteredMultipliers.push(textAnnotations[i]);
        }
        if (
          xmin > qtyMinXUsed - 10 &&
          xmax < qtyMaxXUsed + 10 &&
          isYInRange &&
          !isNaN(formattedNumber)
        ) {
          filteredQuantities.push(textAnnotations[i]);
        }
        if (
          xmin > totalMinXUsed - 10 &&
          xmax < totalMaxXUsed + 10 &&
          isYInRange &&
          !isNaN(formattedNumber)
        ) {
          filteredTotals.push(textAnnotations[i]);
        }
      }
    }

    const mergedMap = new Map();
    let prev = 0;
    for (const item of filteredNames.sort((a, b) => {
      if (!a || !b) return 0;
      const aMinY = Math.min(...a.boundingPoly.vertices.map((v) => v.y));
      const bMinY = Math.min(...b.boundingPoly.vertices.map((v) => v.y));
      return aMinY - bMinY;
    })) {
      if (!item) continue;
      const yMin = Math.min(...item.boundingPoly.vertices.map((v) => v.y));
      let key = yMin;
      if (yMin - prev > 5) {
        key = yMin;
        prev = yMin;
      } else {
        key = prev;
      }

      const collection = mergedMap.get(key);
      if (!collection) {
        mergedMap.set(key, [item]);
      } else {
        collection.push(item);
      }
    }

    const data = Array.from(mergedMap.keys()).map((line) => {
      const names: TextAnnotation[] = mergedMap.get(line);
      const nameMaxY = Math.max(
        ...names.map((item) =>
          Math.max(...item.boundingPoly.vertices.map((v) => v.y))
        )
      );
      const nameMinY = line;

      const expectedHeight = nameMaxY - nameMinY;

      const filterItems = (items: TextAnnotation[]) =>
        items.filter((item) => {
          const yMin = Math.min(...item.boundingPoly.vertices.map((v) => v.y));
          const yMax = Math.max(...item.boundingPoly.vertices.map((v) => v.y));
          const isYMinValid = yMin >= line || Math.abs(yMin - line) <= 5;
          const isInsideItemLine =
            isYMinValid &&
            yMin <= nameMaxY &&
            yMin > line - 5 &&
            yMin < nameMaxY;
          const height = yMax - yMin;
          const isHeightClose =
            expectedHeight >= height || Math.abs(expectedHeight - height) <= 10;
          return isInsideItemLine && isHeightClose;
        });

      const multipliers = filterItems(filteredMultipliers);
      const quantities = filterItems(filteredQuantities);
      const totals = filterItems(filteredTotals);

      const getTextFromItems = (items: TextAnnotation[]) =>
        items.map((item) => item.description).join(" ");
      const namesText = getTextFromItems(
        names.sort((a: TextAnnotation, b: TextAnnotation) => {
          if (!a || !b) return 0;
          const aMinX = Math.min(...a.boundingPoly.vertices.map((v) => v.x));
          const bMinX = Math.min(...b.boundingPoly.vertices.map((v) => v.x));
          return ocrRotation === 0 || ocrRotation === 180
            ? aMinX - bMinX
            : bMinX - aMinX;
        })
      );
      const multipliersText = getTextFromItems(multipliers);
      const quantitiesText = getTextFromItems(quantities);
      const totalsText = getTextFromItems(totals);

      const nameSelection: Selection = createSelectionForBulk(names, "Item");
      const qtySelection: Selection = createSelectionForBulk(
        quantities.filter(
          (annotation) => annotation !== undefined
        ) as TextAnnotation[], // remove undefined values
        "LastItemQuantity"
      );
      const totalSelection: Selection = createSelectionForBulk(
        totals.filter(
          (annotation) => annotation !== undefined
        ) as TextAnnotation[], // remove undefined values
        "LastItemTotal"
      );

      let multiplier = 1;
      let multiplierSelection: Selection | undefined;
      if (multipliersText) {
        multiplier = Number(formatOcrNumber(multipliersText, 2));
        multiplierSelection = createSelectionForBulk(
          multipliers.filter(
            (annotation) => annotation !== undefined
          ) as TextAnnotation[], // remove undefined values
          "multiplier"
        );
      }
      if (
        isAutoDetectMultipliersOn &&
        quantitiesText &&
        !isNaN(Number(formatOcrNumber(quantitiesText, 2)))
      ) {
        const multiplierInText = findMultipliersInText(namesText);
        if (multiplierInText) {
          multiplier = multiplierInText;
          const multiplierInTextAnnotations: TextAnnotation[] = [];
          for (const [key, value] of Object.entries(nameSelection.texts)) {
            if (value.includes(String(multiplierInText))) {
              const boundingPolyForWord =
                nameSelection.boundingPoly[Number(key)];
              const [targetMinX, targetMaxX, targetMinY, targetMaxY] = [
                Math.min(...boundingPolyForWord.map((v) => v.x)),
                Math.max(...boundingPolyForWord.map((v) => v.x)),
                Math.min(...boundingPolyForWord.map((v) => v.y)),
                Math.max(...boundingPolyForWord.map((v) => v.y)),
              ];

              const targetIndex = textAnnotations.findIndex((annotation) => {
                const { vertices } = annotation.boundingPoly;
                return vertices.every(
                  (v) =>
                    v.x === targetMinX ||
                    v.x === targetMaxX ||
                    v.y === targetMinY ||
                    v.y === targetMaxY
                );
              });

              if (targetIndex !== -1) {
                multiplierInTextAnnotations.push(textAnnotations[targetIndex]);
                setAutoMultipliersWordsIndex((prevSet) =>
                  new Set(prevSet).add(targetIndex)
                );
              }
            }
          }
          if (multiplierInTextAnnotations.length > 0) {
            multiplierSelection = createSelectionForBulk(
              multiplierInTextAnnotations,
              "autoMultiplier"
            );
          }
        }
      }
      return {
        name: namesText,
        qty: Number(formatOcrNumber(quantitiesText, 2)) * multiplier,
        total: Number(formatOcrNumber(totalsText, 2)),
        nameSelection,
        multiplierSelection,
        qtySelection,
        totalSelection,
        id: v4(),
      };
    });

    const validLine = data.filter(
      (item) =>
        !isNaN(item.qty) &&
        !isNaN(item.total) &&
        item.name &&
        // item name should include at least one letter
        /[a-zA-Z]/.test(item.name)
    );

    // find the lowest of diff space found in all valid items
    const diffSpace = validLine.reduce((minDiff, item, index) => {
      if (index === validLine.length - 1) return minDiff;
      const currentMaxY = Math.max(
        ...Object.values(item.nameSelection.boundingPoly)
          .flat()
          .map((v) => v.y)
      );
      const nextMinY = Math.min(
        ...Object.values(validLine[index + 1].nameSelection.boundingPoly)
          .flat()
          .map((v) => v.y)
      );
      return Math.min(minDiff, nextMinY - currentMaxY);
    }, Infinity);

    // Handles if item name is multiple lines
    const restructuredData: ItemToMerge[] = [];
    for (let i = 0; i < validLine.length; i++) {
      const itemIndex = data.findIndex((d) => d.id === validLine[i].id);

      const currentLine = data[itemIndex];
      const nextLine = data[itemIndex + 1];

      if (nextLine) {
        const [nextMinY, currentMaxY] = [
          Math.min(
            ...Object.values(nextLine.nameSelection.boundingPoly)
              .flat()
              .map((v) => v.y)
          ),
          Math.max(
            ...Object.values(currentLine.nameSelection.boundingPoly)
              .flat()
              .map((v) => v.y)
          ),
        ];
        const isNextLineTooFar = nextMinY - currentMaxY >= diffSpace + 5;
        const isNextLineValid = !isNaN(nextLine.qty) && !isNaN(nextLine.total);
        const isNextLineExtendedName =
          isNaN(nextLine.total) && isNaN(nextLine.qty);

        if (isNextLineTooFar || isNextLineValid || !isNextLineExtendedName) {
          restructuredData.push(currentLine);
          continue;
        }

        const mergedNameSelection = mergeItemSelections(
          currentLine.nameSelection,
          nextLine.nameSelection
        );
        const newNameSelection = {
          ...currentLine.nameSelection,
          boundingPoly: mergedNameSelection.newBoundingPoly,
          texts: mergedNameSelection.newTexts,
        };

        const minNameYFromMerged = Math.min(
          ...Object.values(newNameSelection.boundingPoly)
            .flat()
            .map((v) => v.y)
        );
        const maxNameYFromMerged = Math.max(
          ...Object.values(newNameSelection.boundingPoly)
            .flat()
            .map((v) => v.y)
        );

        const filterInRange = (annotations: TextAnnotation[]) =>
          annotations.filter((annotation) => {
            const ymin = Math.min(
              ...Object.values(annotation.boundingPoly)
                .flat()
                .map((v) => v.y)
            );
            return ymin > minNameYFromMerged - 5 && ymin < maxNameYFromMerged;
          });

        const totalsInRange = filterInRange(filteredTotals);
        const quantitiesInRange = filterInRange(filteredQuantities);

        const inRangeTotalSelection = createSelectionForBulk(
          totalsInRange,
          "LastItemTotal"
        );
        const inRangeQuantitiesSelection = createSelectionForBulk(
          quantitiesInRange,
          "LastItemQuantity"
        );

        const inRangeTotalsText = totalsInRange
          .map((item) => item.description)
          .join(" ");
        const inRangeQuantitiesTexts = quantitiesInRange
          .map((item) => item.description)
          .join(" ");
        let multiplier = 1;
        if (isAutoDetectMultipliersOn) {
          const multiplierInText = findMultipliersInText(
            currentLine.name + " " + nextLine.name
          );
          if (
            multiplierInText &&
            inRangeQuantitiesTexts &&
            !isNaN(Number(formatOcrNumber(inRangeQuantitiesTexts, 2)))
          ) {
            multiplier = multiplierInText;
            for (const key of Object.keys(newNameSelection.texts)) {
              const value = newNameSelection.texts[Number(key)];
              if (value.includes(String(multiplierInText))) {
                const boundingPolyForWord =
                  newNameSelection.boundingPoly[Number(key)];
                const [targetMinX, targetMaxX, targetMinY, targetMaxY] = [
                  Math.min(...boundingPolyForWord.map((v) => v.x)),
                  Math.max(...boundingPolyForWord.map((v) => v.x)),
                  Math.min(...boundingPolyForWord.map((v) => v.y)),
                  Math.max(...boundingPolyForWord.map((v) => v.y)),
                ];

                const targetIndex = textAnnotations.findIndex((annotation) => {
                  const { vertices } = annotation.boundingPoly;
                  return vertices.every(
                    (v) =>
                      v.x === targetMinX ||
                      v.x === targetMaxX ||
                      v.y === targetMinY ||
                      v.y === targetMaxY
                  );
                });

                if (targetIndex !== -1) {
                  setAutoMultipliersWordsIndex((prevSet) => {
                    const newSet = new Set(prevSet);
                    newSet.add(targetIndex);
                    return newSet;
                  });
                }
              }
            }
          }
        }

        restructuredData.push({
          ...currentLine,
          name: currentLine.name + " " + nextLine.name,
          nameSelection: newNameSelection,
          totalSelection: inRangeTotalSelection,
          total: Number(formatOcrNumber(inRangeTotalsText, 2)),
          qtySelection: inRangeQuantitiesSelection,
          qty: Number(formatOcrNumber(inRangeQuantitiesTexts, 2)) * multiplier,
        });
      } else restructuredData.push(currentLine);
    }

    return restructuredData.filter(
      (item) => !isNaN(item.qty) && !isNaN(item.total)
    );
  }, [
    nameMaxX,
    nameMinX,
    multiplierMaxX,
    multiplierMinX,
    qtyMaxX,
    qtyMinX,
    totalMaxX,
    totalMinX,
    textAnnotations,
    textAnnotationsBox,
    allSelectedYRange,
    createSelectionForBulk,
    ocrRotation,
    isAutoDetectMultipliersOn,
  ]);

  function findMultipliersInText(input: string): number | null {
    const specificNumbers = [6, 12, 24];
    const foundNumbers = input.match(/\d+/g)?.map(Number) || [];
    const filteredNumbers = foundNumbers.filter((number) =>
      specificNumbers.includes(number)
    );
    if (filteredNumbers.length === 1) {
      return filteredNumbers[0];
    } else {
      return null;
    }
  }

  function mergeItemSelections(
    currentSelection: Selection,
    nextSelection: Selection
  ): { newBoundingPoly: Record<number, Vertex[]>; newTexts: string[] } {
    let boundingPolyIndex = 0;
    const newBoundingPoly: Record<number, Vertex[]> = {};
    for (const key of Object.keys(currentSelection.boundingPoly)) {
      newBoundingPoly[boundingPolyIndex] =
        currentSelection.boundingPoly[Number(key)];
      boundingPolyIndex++;
    }
    for (const key of Object.keys(nextSelection.boundingPoly)) {
      newBoundingPoly[boundingPolyIndex] =
        nextSelection.boundingPoly[Number(key)];
      boundingPolyIndex++;
    }

    // Merge texts
    let textIndex = 0;
    const newTexts: string[] = [];
    for (const key of Object.keys(currentSelection.texts)) {
      newTexts[textIndex] = currentSelection.texts[Number(key)];
      textIndex++;
    }
    for (const key of Object.keys(nextSelection.texts)) {
      newTexts[textIndex] = nextSelection.texts[Number(key)];
      textIndex++;
    }
    return { newBoundingPoly, newTexts };
  }

  const handleBulkAddOpenItems = () => {
    const data = getPreselectedItem();
    if (data.length === 0) {
      dispatch(
        alertActions.ERROR({
          message:
            "No items found in the selected area or you have not selected any items",
        })
      );
      return;
    }
    if (bulkAddOpenItems) bulkAddOpenItems(data);
  };

  const handleDownloadInvoiceFile = async () => {
    const blob = await fetch(imageUrl).then((r) => r.blob());
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    const validExtensions = ["png", "jpg", "jpeg"];
    const rawFileName =
      getFileNameFromUrl(imageUrl) ?? `${userId}-invoice-${Date.now()}.jpg`;
    const fileExtension = getFileExtension(imageUrl);

    link.download =
      fileExtension && validExtensions.includes(fileExtension)
        ? rawFileName
        : `${rawFileName}.jpg`;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const hotkeys: HotkeyConfig[] = [
    {
      combo: "1",
      global: true,
      label: "Invoice Number",
      onKeyUp: () => handleMenuItemClick(selections.InvoiceNumber),
    },
    {
      combo: "2",
      global: true,
      label: "Supplier Number",
      onKeyUp: () => handleMenuItemClick(selections.Supplier),
    },
    {
      combo: "3",
      global: true,
      label: "Delivery Date",
      onKeyUp: () => handleMenuItemClick(selections.DeliveryDate),
    },
    {
      combo: "4",
      global: true,
      label: "Food Total",
      onKeyUp: () => handleMenuItemClick(selections.FoodTotal),
    },
    {
      combo: "5",
      global: true,
      label: selections.Item,
      onKeyUp: () => handleMenuItemClick(selections.Item),
    },
    {
      combo: "7",
      global: true,
      label: "Quantity",
      onKeyUp: () => handleMenuItemClick(selections.LastItemQuantity),
    },
    {
      combo: "u",
      global: true,
      label: "Quantity x24",
      onKeyUp: () => handleMenuItemClick(selections.LastItemQuantity, 24),
    },
    {
      combo: "i",
      global: true,
      label: "Quantity x12",
      onKeyUp: () => handleMenuItemClick(selections.LastItemQuantity, 12),
    },
    {
      combo: "o",
      global: true,
      label: "Quantity x6",
      onKeyUp: () => handleMenuItemClick(selections.LastItemQuantity, 6),
    },
    {
      combo: "8",
      global: true,
      label: "Total",
      onKeyUp: () => handleMenuItemClick(selections.LastItemTotal),
    },
    {
      combo: "9",
      global: true,
      label: "Add Open Item",
      onKeyUp: () => handleMenuItemClick(selections.AddOpenItem),
    },
    {
      combo: "0",
      global: true,
      label: "Add Diverse Item",
      onKeyUp: () => handleMenuItemClick(selections.AddDiverseItem),
    },
    {
      combo: "d",
      global: true,
      label: "Auto Detect Diverse Items",
      onKeyUp: () => handleMenuItemClick(selections.AutoDetectDiverseItems),
    },
    {
      combo: "z",
      global: true,
      label: "Select Names Floating Button",
      onKeyUp: () => toggleSelected("Names"),
    },
    {
      combo: "x",
      global: true,
      label: "Select Quantities Floating Button",
      onKeyUp: () => toggleSelected("Quantities"),
    },
    {
      combo: "c",
      global: true,
      label: "Select Totals Floating Button",
      onKeyUp: () => toggleSelected("Totals"),
    },
    {
      combo: "a",
      global: true,
      label: "Bulk Add Open Items",
      onKeyUp: handleBulkAddOpenItems,
    },
    {
      combo: "ctrl + [",
      global: true,
      label: "Rotate Image Left",
      onKeyUp: () => rotateBothImgAndOcr(-1),
    },
    {
      combo: "ctrl + ]",
      global: true,
      label: "Rotate Image Right",
      onKeyUp: () => rotateBothImgAndOcr(1),
    },
  ];

  const menuItems = () => {
    return (
      <>
        <MenuItem text={getMenuWord()} disabled />
        <MenuDivider />
        <MenuItem
          text="Invoice # [1]"
          onClick={() => handleMenuItemClick(selections.InvoiceNumber)}
          disabled={!expandItems}
        />
        <MenuItem
          text="Supplier [2]"
          onClick={() => handleMenuItemClick(selections.Supplier)}
          disabled={!expandItems}
        />
        <MenuItem
          text="Delivery Date [3]"
          onClick={() => handleMenuItemClick(selections.DeliveryDate)}
          disabled={!expandItems}
        />
        <MenuItem
          text="Food Total [4]"
          onClick={() => handleMenuItemClick(selections.FoodTotal)}
          disabled={!expandItems}
        />
        <MenuItem
          text="Item [5]"
          onClick={() => handleMenuItemClick(selections.Item)}
        />
        <MenuItem
          text="Quantity [7]"
          onClick={() => handleMenuItemClick(selections.LastItemQuantity)}
        >
          <MenuItem
            text="x24 [u]"
            onClick={() => handleMenuItemClick(selections.LastItemQuantity, 24)}
          />
          <MenuItem
            text="x12 [i]"
            onClick={() => handleMenuItemClick(selections.LastItemQuantity, 12)}
          />
          <MenuItem
            text="x6 [o]"
            onClick={() => handleMenuItemClick(selections.LastItemQuantity, 6)}
          />
        </MenuItem>
        <MenuItem
          text="Total [8]"
          onClick={() => handleMenuItemClick(selections.LastItemTotal)}
        />
        <MenuItem
          text="Add Open Item [9]"
          onClick={() => handleMenuItemClick(selections.AddOpenItem)}
        />
        <MenuItem
          text="Add Diverse Item [0]"
          onClick={() => handleMenuItemClick(selections.AddDiverseItem)}
        />
        <MenuItem
          text="Auto Detect Diverse Items [d]"
          onClick={() => handleMenuItemClick(selections.AutoDetectDiverseItems)}
        />
      </>
    );
  };

  function rotateImgAndOcr(angle: number, type: "img" | "ocr") {
    const rotation = type === "img" ? imageRotation : ocrRotation;

    let newRotation = rotation + angle;

    if (newRotation < 0) {
      newRotation = newRotation + 360;
    } else if (newRotation > 360) {
      newRotation = newRotation - 360;
    } else if (newRotation === 360) {
      newRotation = 0;
    }
    saveOrientationFunction?.(imageUrl, newRotation, type);
  }

  function rotateBothImgAndOcr(angle: number, isForSlider = false) {
    let imageRtn = imageRotation + angle;
    let ocrRtn = ocrRotation + angle;

    if (imageRtn < 0) {
      imageRtn = imageRtn + 360;
    } else if (imageRtn > 360) {
      imageRtn = imageRtn - 360;
    } else if (imageRtn === 360) {
      imageRtn = 0;
    }

    if (ocrRtn < 0) {
      ocrRtn = ocrRtn + 360;
    } else if (ocrRtn > 360) {
      ocrRtn = ocrRtn - 360;
    } else if (ocrRtn === 360) {
      ocrRtn = 0;
    }

    if (isForSlider) {
      setImageRotation(imageRtn);
      setOcrRotation(ocrRtn);
      return;
    }
    saveOrientationFunction?.(imageUrl, imageRtn, "both", ocrRtn);
  }

  const [generateOcrDataLoading, setGenerateOcrDataLoading] = useState(false);

  const handleGenerateOcrData = async () => {
    if (!functions) return;

    try {
      setGenerateOcrDataLoading(true);
      const generateOcrData = httpsCallable<
        { userId: string; imageUrl: string; invoiceId: string },
        string
      >(functions, CALLABLE_FUNCTIONS.generateOcrData, { timeout: 540000 });

      const payload = {
        userId: userId,
        imageUrl: imageUrl,
        invoiceId: invoiceId,
      };
      const response = await generateOcrData(payload);
      dispatch(
        alertActions.SUCCESS({
          message: response.data,
          isManualDismiss: true,
        })
      );
      dispatch(actions.GET_OCR_DATA({ userId, imageUrl }));
    } catch (error) {
      dispatch(
        alertActions.ERROR({ message: String(error), isManualDismiss: true })
      );
    } finally {
      setGenerateOcrDataLoading(false);
    }
  };

  return (
    <>
      {module === "invoice" && (
        <div style={{ display: "flex" }}>
          <Popover
            position="bottom"
            content={
              <div
                className="p-4 d-flex"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularSlider
                  knobDraggable={!isLoadingUpdateInvoice}
                  onChange={(value: number) => {
                    if (value < 0) value = value + 360;
                    if (value > 360) value = value - 360;
                    rotateBothImgAndOcr(value - imageRotation, true);
                  }}
                  isDragging={(value: boolean) => setIsDragging(value)}
                  width={200}
                  dataIndex={imageRotation}
                  renderLabelValue={
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 5,
                      }}
                    >
                      <NumericInput
                        asyncControl={true}
                        disabled={isLoadingUpdateInvoice}
                        value={imageRotation}
                        onValueChange={_.debounce((value) => {
                          if (value < 0) value = value + 360;
                          if (value > 360) value = value - 360;
                          rotateBothImgAndOcr(value - imageRotation);
                        }, 500)}
                        stepSize={1}
                        style={{ width: "75px" }}
                      />
                    </div>
                  }
                  progressColorFrom="#BB86FC"
                  progressColorTo="#FD7AFE"
                  knobColor="#681fc2"
                />

                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "repeat(2, auto)",
                    marginTop: "10px",
                  }}
                >
                  <Tooltip
                    position="top"
                    content="Rotate image & OCR by 1° CCW"
                  >
                    <Button
                      loading={isLoadingUpdateInvoice}
                      onClick={() => {
                        rotateBothImgAndOcr(-1);
                      }}
                      icon="image-rotate-left"
                      fill
                    >
                      Both (-1°)
                    </Button>
                  </Tooltip>

                  <Tooltip position="top" content="Rotate image & OCR by 1° CW">
                    <Button
                      loading={isLoadingUpdateInvoice}
                      onClick={() => {
                        rotateBothImgAndOcr(1);
                      }}
                      icon="image-rotate-right"
                      fill
                    >
                      Both (1°)
                    </Button>
                  </Tooltip>

                  <Tooltip
                    position="top"
                    content="Rotate only image by 90° CCW"
                  >
                    <Button
                      loading={isLoadingUpdateInvoice}
                      onClick={() => {
                        rotateImgAndOcr(-90, "img");
                      }}
                      icon="image-rotate-left"
                      fill
                    >
                      Image (-90°)
                    </Button>
                  </Tooltip>

                  <Tooltip position="top" content="Rotate only image by 90° CW">
                    <Button
                      loading={isLoadingUpdateInvoice}
                      onClick={() => {
                        rotateImgAndOcr(90, "img");
                      }}
                      icon="image-rotate-right"
                      fill
                    >
                      Image (90°)
                    </Button>
                  </Tooltip>

                  <Tooltip position="top" content="Rotate only OCR by 90° CCW">
                    <Button
                      loading={isLoadingUpdateInvoice}
                      onClick={() => {
                        rotateImgAndOcr(-90, "ocr");
                      }}
                      icon="image-rotate-left"
                      fill
                    >
                      OCR (-90°)
                    </Button>
                  </Tooltip>

                  <Tooltip position="top" content="Rotate only OCR by 90° CW">
                    <Button
                      loading={isLoadingUpdateInvoice}
                      onClick={() => {
                        rotateImgAndOcr(90, "ocr");
                      }}
                      icon="image-rotate-right"
                      fill
                    >
                      OCR (90°)
                    </Button>
                  </Tooltip>

                  {textAnnotations.length === 0 && (
                    <ActionConfirmationDialogue
                      onConfirm={() => handleGenerateOcrData()}
                      actionName="Confirm"
                      children={
                        <Tooltip content="Generate OCR Data">
                          <Button
                            loading={generateOcrDataLoading}
                            icon="generate"
                            text="Generate OCR Data"
                          />
                        </Tooltip>
                      }
                      title="Generate OCR Data"
                      text="Are you sure want to generate OCR Data?"
                    />
                  )}
                </div>
              </div>
            }
          >
            <Button icon="cog" className="m-1">
              Rotation
            </Button>
          </Popover>
          <Button
            icon="download"
            style={{ marginLeft: "7px" }}
            className="bp5-button m-1"
            onClick={async () => await handleDownloadInvoiceFile()}
          >
            Download Image
          </Button>

          <Switch
            checked={isHighlightOn}
            onChange={() => setIshighlighOn((prev) => !prev)}
            label={"Highlight"}
            style={{ marginLeft: "7px", marginTop: "10px" }}
            alignIndicator="left"
          />
        </div>
      )}
      <HotkeysProvider>
        <div className="canvasDiv" ref={divRef}>
          <Loader loading={imageLoader || loadingOcrData} loaderHeight={0}>
            <></>
          </Loader>

          {isFloatingButtonsVisible && (
            <InvoiceFloatingButtons
              selectedFloatingButton={selectedFloatingButton}
              toggleSelected={toggleSelected}
              handleAutoDetectMultipliersSwitch={
                handleAutoDetectMultipliersSwitch
              }
              isAutoDetectMultipliersOn={isAutoDetectMultipliersOn}
            />
          )}

          <HotkeysTarget2
            hotkeys={
              openItemCreate
                ? []
                : hotkeys.filter((h) => {
                    if (
                      !isFloatingButtonsVisible &&
                      ["z", "x", "c", "v", "a"].includes(h.combo)
                    )
                      return false;
                    return true;
                  })
            }
          >
            {({ handleKeyDown, handleKeyUp }) => (
              <div
                data-testid="canvas-div"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              >
                <ContextMenu
                  content={
                    openItemCreate ? undefined : (
                      <Menu data-testid="menu-item">{menuItems()}</Menu>
                    )
                  }
                >
                  <canvas
                    className="canvas1"
                    style={{
                      backgroundImage: `url(${backgroundImage?.src || ""})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                    }}
                    ref={canvasImageRef}
                  />
                  <canvas className="canvas2" ref={canvasRef} />
                  <canvas className="canvas3" ref={canvasParagraphRef} />
                  <FloatingButtonCanvas targetRef={canvasFloatingNameRef} />
                  <FloatingButtonCanvas
                    targetRef={canvasFloatingMultiplierRef}
                  />
                  <FloatingButtonCanvas targetRef={canvasFloatingQtyRef} />
                  <FloatingButtonCanvas targetRef={canvasFloatingTotalRef} />
                  <FloatingButtonCanvas targetRef={canvasFloatingItemsRef} />
                  <canvas
                    data-testid="ocr-canvas"
                    className="canvas5"
                    ref={canvasSelectRef}
                    tabIndex={0}
                    onMouseMove={
                      openItemCreate
                        ? undefined
                        : (e) => {
                            requestAnimationFrame(() => {
                              handleMouseMoveDraw(e);
                            });
                          }
                    }
                    onMouseDown={
                      openItemCreate ? undefined : (e) => handleMouseDown(e)
                    }
                    onMouseUp={
                      openItemCreate ? undefined : (e) => handleMouseUp(e)
                    }
                  />
                </ContextMenu>
              </div>
            )}
          </HotkeysTarget2>
        </div>
      </HotkeysProvider>
    </>
  );
}

export default InvoiceOCR;
