import { convertFromPdf, isPdf } from "components/ap-transactions/utils";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { APImageDataUpload } from "redux/ap-transactions/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db, storage } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function getFile(path: string): Promise<ServiceReturn> {
  if (!storage) return { data: null, error: "No storage connection" };
  const downloadURL = await getDownloadURL(ref(storage, path))
    .then((downloadURL) => {
      return { data: downloadURL, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });

  return downloadURL;
}

export async function getFiles(imagesPath: string[]): Promise<ServiceReturn> {
  const downloadURL: string[] = [];
  const errorList: unknown[] = [];

  if (!storage) return { data: null, error: ["No storage connection"] };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  for (const path of imagesPath) {
    if (!path) continue;
    try {
      const imageLink = await getDownloadURL(ref(storage, path));
      downloadURL.push(imageLink);
    } catch (error) {
      errorList.push(error);
    }
  }

  return { data: downloadURL, error: errorList.length > 0 ? errorList : null };
}

export async function createInvoiceWithFiles(
  apUserId: string,
  invUserId: string,
  selectedImageData: APImageDataUpload[],
  transactionId: string,
  resolvedBy: string
) {
  if (!db) return { data: null, error: "No db connection" };
  try {
    const selectedImages: APImageDataUpload[] = [];

    const invoices = [
      ...new Set(selectedImageData.flat(1).map((i) => i.invoiceCounter)),
    ].sort();

    const batch = writeBatch(db);
    const invoiceIds: string[] = [];

    await Promise.all(
      invoices.map(async (i) => {
        // Create new invoice document
        if (!db) return Promise.reject("No db connection");
        const invoiceRef = doc(collection(db, "users", invUserId, "invoices"));
        const invoiceId = invoiceRef.id;
        invoiceIds.push(invoiceId);

        const targetImages = selectedImageData.filter(
          (x) => x.invoiceCounter === i
        );

        const uploadedFiles: string[] = await Promise.all(
          targetImages.map(
            async ({ source, page, url, invoiceCounter }, idx) => {
              const fileName: string = url.startsWith("data:image/png")
                ? `${invoiceId}-${idx}-${Date.now()}.png`
                : `${invoiceId}-${idx}-${getFileNameFromUrl(url)}`;
              const fileData = await fetch(url).then((x) => x.arrayBuffer());
              const newUrl = await uploadInvoiceFile(
                invUserId,
                fileName,
                fileData
              );
              selectedImages.push({
                source,
                page,
                url: newUrl,
                invoiceCounter,
              });
              return newUrl;
            }
          )
        );

        // remove `null` entry in between images
        const filteredFiles = uploadedFiles.filter((x) => Boolean(x));

        batch.set(invoiceRef, {
          url: filteredFiles[0],
          files: filteredFiles,
          userId: invUserId,
          state: "unresolved",
          createdAt: new Date(),
          updatedAt: new Date(),
          isFromApTransaction: true,
          isChecked: false,
        });
      })
    );

    // Update AP transaction document
    const docRef = doc(
      db,
      "users",
      apUserId,
      "invoiceTransactions",
      transactionId
    );
    batch.update(docRef, {
      resolved: true,
      resolvedBy: resolvedBy,
      invoiceIds,
      selectedImages: selectedImages,
      classificationSuccess: false,
      classificationMessage: "Resolved by user",
    });
    await batch.commit();
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export function getFileNameFromUrl(url: string) {
  const temp = decodeURIComponent(url).split(/[#?]/)[0].split("/").pop();
  return temp?.trim();
}

export async function uploadInvoiceFile(
  userId: string,
  fileName: string,
  fileData: ArrayBuffer | Blob
) {
  if (!storage) return Promise.reject("No storage connection");
  const path = "users/" + userId + "/invoices/" + fileName;
  const storageRef = ref(storage, path);
  return uploadBytes(storageRef, fileData).then((x) => getDownloadURL(x.ref));
}

export async function updateAPTransaction(
  userId: string,
  transId: string,
  paths: string[]
) {
  if (!db) return { data: null, error: "No db connection" };
  try {
    const apRef = doc(
      db,
      "users/" + userId + "/invoiceTransactions/" + transId
    );
    await updateDoc(apRef, {
      files: arrayUnion(...paths),
    });
    return { data: apRef, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function markTransactionAsSensitive(
  userId: string,
  transactionId: string,
  isSensitive: boolean
) {
  if (!db) return { data: null, error: "No db connection" };
  try {
    const docRef = doc(
      db,
      "users/" + userId + "/invoiceTransactions/" + transactionId
    );

    await updateDoc(docRef, { isSensitive });
    const status = isSensitive ? "marked" : "unmarked";

    return { data: status, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function uploadAndCreateTransactions(
  userId: string,
  fileArray: File[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!storage) return Promise.reject("No storage connection");
  try {
    await Promise.all(
      fileArray.map(async (file) => {
        if (!db) return null;
        if (isPdf(file.name)) {
          const blobUrl = URL.createObjectURL(file);
          const jpegBlobUrls = await convertFromPdf(blobUrl);
          const jpegBlobUrlsMapped = jpegBlobUrls.map((x) => ({
            ...x,
            source: file.name.replace(".pdf", ".jpeg"),
          }));
          for (let i = 0; i < jpegBlobUrlsMapped.length; i++) {
            const data = jpegBlobUrlsMapped[i];
            const fileName = `${new Date().getTime()}-users-${userId}-invoiceTransactions-page${
              data.page + 1
            }-${file.name.replace(".pdf", ".jpeg")}`;
            const image = await fetch(data.url).then((x) => x.arrayBuffer());
            const newUrl = await uploadInvoiceFile(userId, fileName, image);
            const fileUrls: string[] = [];
            fileUrls.push(newUrl);
            const transRef = collection(
              db,
              "users",
              userId,
              "invoiceTransactions"
            );
            await addDoc(transRef, {
              files: fileUrls,
              userId: userId,
              state: "unresolved",
              createdAt: new Date(),
              updatedAt: new Date(),
              resolved: false,
              vendor: "Manual Upload",
              deleted: false,
              transactionHeader: {
                transactionSource: { description: "Transactions" },
              },
            });
          }
        } else {
          const fileName = `${new Date().getTime()}-users-${userId}-invoiceTransactions-${
            file.name
          }`;
          const newUrl = await uploadInvoiceFile(userId, fileName, file);
          const fileUrls: string[] = [];
          fileUrls.push(newUrl);
          const transRef = collection(
            db,
            "users",
            userId,
            "invoiceTransactions"
          );
          await addDoc(transRef, {
            files: fileUrls,
            userId: userId,
            resolved: false,
            createdAt: new Date(),
            updatedAt: new Date(),
            vendor: "Manual Upload",
            deleted: false,
            transactionHeader: {
              transactionSource: { description: "Transactions" },
            },
          });
        }
      })
    );

    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}
