import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import { Invoice } from "redux/invoice/types";

interface Props {
  userId: string;
  modification?: string;
  filterState?: string;
}

export default function useMergeInvoices({
  userId,
  modification,
  filterState,
}: Props) {
  const loadingInvoice = useAppSelector((state) => state.invoices.loading);
  const loadingInvoiceVote = useAppSelector(
    (state) => state.invoices.loadingVotes
  );
  const invoiceData = useAppSelector((state) => state.invoices.invoices);
  const invoiceVotes = useAppSelector((state) => state.invoices.invoiceVotes);
  const currentUser = useAppSelector((state) => state.auth.user);
  const maxVotes = useAppSelector(
    (state) => state.settings.dataVotingConfig?.maxVotes
  );
  const authClaims = useAppSelector((state) => state.auth.authClaims);

  const [mergedInvoices, setMergedInvoices] = useState<Invoice[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userId) return;
    if (!filterState || filterState === "pending-votes") return;

    dispatch(actions.SUBSCRIBE_TO_USER_INVOICE({ userId, state: filterState }));

    return () => {
      dispatch(actions.UNSUBSCRIBE_FROM_USER_INVOICE());
      dispatch(actions.SET_STATE({ invoices: [] }));
    };
  }, [userId, dispatch, filterState]);

  useEffect(() => {
    if (!userId) return;
    dispatch(actions.SUBSCRIBE_TO_USER_ITEMS({ userId }));
    if (modification === "dataVoting") {
      dispatch(actions.SUBSCRIBE_TO_USER_INVOICE_VOTES({ userId }));
    }
    return () => {
      dispatch(actions.UNSUBSCRIBE_FROM_USER_ITEMS());
      dispatch(actions.SET_STATE({ userItems: [] }));

      if (modification === "dataVoting") {
        dispatch(actions.UNSUBSCRIBE_FROM_USER_INVOICE_VOTES());
      }
    };
  }, [dispatch, userId, modification]);

  useEffect(() => {
    if (!filterState) return;

    const isDataVoting = modification === "dataVoting";

    if (isDataVoting && filterState === "pending-votes") {
      const pendingVotes = invoiceVotes?.filter(
        (vote) => vote.votedBy === currentUser && vote.state === "unresolved"
      );
      setMergedInvoices(pendingVotes);
      return;
    }

    const mergedInvoices = invoiceData
      .map((inv: Invoice) => {
        if (!isDataVoting || inv.state === "resolved") {
          return inv;
        }
        const invoice = { ...inv }; // let lose the class instance
        const relatedVotes = invoiceVotes?.filter(
          (i) => i.invoiceId === invoice.id
        );
        if (invoice.resolvedBy !== "Auto Resolver") {
          return {
            ...invoice,
            voters: relatedVotes?.map((i) => String(i.votedBy)),
          };
        }
        const votedInvoices = relatedVotes?.filter(
          (i) => i.state === invoice.state && !i.deleted
        );
        if (votedInvoices && votedInvoices.length > 0) {
          return {
            ...invoice,
            voters: relatedVotes.map((i) => String(i.votedBy)),
          };
        }
        return invoice;
      })
      .filter((inv) => {
        if (!currentUser) return false;
        // IF STATE IS UNRESOLVED AND USER HAS VOTED, DON'T SHOW THE INVOICES ON VOTER'S LIST
        if (inv.state === "unresolved" && inv.voters?.includes(currentUser)) {
          return false;
        }

        const votedInvoices = invoiceVotes?.filter(
          (vote) => vote.invoiceId === inv.id && vote.state === inv.state
        );

        if (
          votedInvoices &&
          votedInvoices.length > (maxVotes || 3) &&
          inv.state === "unresolved"
        ) {
          return false;
        }

        if (authClaims?.admin) return true;
        return !inv.deleted;
      });

    setMergedInvoices(mergedInvoices);
  }, [
    invoiceData,
    invoiceVotes,
    modification,
    currentUser,
    maxVotes,
    authClaims,
    filterState,
  ]);

  const pendingVotesCount = useMemo(() => {
    if (modification !== "dataVoting") return 0;
    return (
      invoiceVotes?.filter(
        (vote) => vote.votedBy === currentUser && vote.state === "unresolved"
      ).length ?? 0
    );
  }, [invoiceVotes, modification, currentUser]);

  return {
    loadingInvoice: loadingInvoice || loadingInvoiceVote,
    invoiceData: mergedInvoices,
    pendingVotesCount,
  };
}
